.datewarning {
  background-color: #e3e3e3;
  color: #333;
  font-weight: 700;
  line-height: 15px;
  margin: 12px 0 5px 0;
  padding: 10px;
  text-align: center;

  &.valid_no {
    opacity: 0.65;
  }
}
