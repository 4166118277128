.flashcontent {
  display: block !important;
  opacity: 0;
  transition: all 1.5s ease-in-out;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
