.insights-employee {
  align-items: center;
  display: flex !important;
  margin: 0;

  &-avatar {
    margin: 0 6px 0 0 !important;
  }

  &-name {
    flex: 1;
  }
}

.calendar-team-view-employee {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  &-avatar {
    margin-right: 6px;
  }

  &-avatar-name {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    margin-right: 6px;
  }

  &-name {
    display: flex;
    align-items: center;
    flex: 1;
    height: 36px;
    overflow: hidden;
  }
}
