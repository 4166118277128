.calls-queue-item {
  display: flex;
  flex-direction: column;

  .calls-queue-item-header {
    align-items: center;
    display: flex;
    margin-bottom: 12px;

    .calls-queue-item-caller-avatar {
      margin-right: 12px;
    }

    .calls-queue-item-caller-name-status {
      display: flex;
      flex: 1;
      padding-right: 24px;

      .calls-queue-item-caller-status {
        margin-left: 6px;
      }

      .calls-queue-item-caller-action {
        align-self: flex-end;
      }
    }
  }

  .calls-queue-item-content {
    .calls-queue-item-by-for-employee {
      margin-bottom: 12px;
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }
}
