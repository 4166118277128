#open_ticket_counter {
  background: #5fbeb7;
  color: $color-white;
  float: right;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 4px 15px 0 0;
  padding: 0 5px;

  .active & {
    background: #5fbeb7;
    color: $color-white;
  }
}
