// Input: simple input fields
.inp {
  display: block;
  margin: 10px 0;
}

.inp-radio {
  background-color: $color-white;
  border: 3px solid $color-white;
  border-radius: 50%;
  box-shadow: 0 0 0 3px $color-grey-3;
  height: 12px;
  margin-left: auto;
  width: 12px;
}

.inp-radio-checked {
  background-color: $color-primary-1;
  box-shadow: 0 0 0 2px $color-primary-1;
}
