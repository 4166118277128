#stats_ppl {
  h2 {
    #weighted_link {
      color: #999;
      font-size: 11px;
    }
  }

  .statphase {
    b {
      float: right;
    }
  }
}

// Pipeline stats
.innerwidget {
  &.pipeline_stats {
    background-color: transparent;

    .content_t {
      color: #333;
      font-size: 24px;
      font-weight: 700;
    }

    .widgettitleholder {
      &.dropdownwidget {
        padding-left: 0px;
      }
    }
  }
}
