.notification-informational-group {
  overflow: hidden;

  .notification-informational-group-header {
    align-items: flex-start;
    display: flex;
    margin-bottom: 12px;

    .notification-informational-group-counter {
      margin-right: 12px;
    }

    .notification-informational-group-title {
      flex: 1;
      padding-top: 1px;
    }

    .notification-informational-group-mark-as-done {
      justify-self: flex-end;
      margin-left: 12px;
      padding-top: 3px;
      white-space: nowrap;
    }
  }

  .notification-informational-group-show-all {
    margin-top: 12px;
  }

  &.is-showing-all-items {
    .notification-informational-group-show-all {
      display: none;
    }

    .notification-informational-items-hidden {
      display: block;
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }
}

.notification-informational-items {
  .notification-informational-item {
    display: flex;
    margin-top: 6px;

    .notification-informational-item-title {
      margin-left: 12px;
    }
  }

  &.notification-informational-items-hidden {
    display: none;
  }
}

.notification-actionable-item {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .notification-actionable-item-action {
    flex-shrink: 0;
    margin-left: 12px;
  }
}

.notice {
  background-color: $color-white;
  float: left;
  font-size: 12px;
  margin: 16px 0 6px 0;
  padding: 15px;
  position: relative;
  width: 350px;

  em {
    color: $color-body-text;
    font-size: 11px;
    font-style: italic;
  }

  i {
    color: $color-error;
    font-style: normal;
  }

  p {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 15px;
    margin-top: 12px;
    text-align: center;

    &.normal {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
      padding: 0 10px 0 10px;
      text-align: left;
    }
  }

  #timeline & {
    font-size: 15px;
    line-height: 31px;
    margin-left: -329px;
    margin-top: 130px;
    text-align: center;
    width: 640px;
  }
}

.no_access_notice {
  color: $color-body-text;
  font-size: 16px;
  margin: 100px auto 100px auto;
  text-align: center;
  width: 500px;
}

.goodnotice,
.badnotice {
  box-shadow: 0 0 5px #d1d1d1;

  &:before {
    font-family: "icomoon";
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 28px;
  }
}

.goodnotice:before {
  color: $color-valid;
  content: "\ea10";
}

.badnotice:before {
  color: $color-error;
  content: "\ea0f";
}

.neutralnotice {
  background-repeat: no-repeat;
  opacity: 1;
  padding: 0;
}

.vat-notice {
  color: $color-grey-5;
}

#rightcontent {
  .notice {
    float: none;
    font-size: 14px;
    line-height: 30px;
    margin: 0 0 30px;
    padding: 15px 15px 15px 65px;
    width: auto;
  }
}
#rightcontent .container-max990 .notice {
  margin-left: 0px;
}
