#voipstatus {
  margin-bottom: 10px;

  li {
    cursor: pointer;
    background: 0 0;
    min-width: 120px;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      background: $ahoy-color-aqua-dark;

      a {
        color: $color-white;
      }
    }
  }
}

#voipstatuslist {
  margin-top: 15px;

  a {
    font-size: 13px;
    text-decoration: none;
  }

  .ttlink {
    a {
      background-color: $ahoy-color-aqua-dark;
      border-radius: 2px;
      color: $color-white;
      cursor: pointer;
      display: block;
      font-size: 11px;
      height: 20px;
      line-height: 20px;
      margin: 0 10px;
      padding: 3px;
      text-align: center;
      text-transform: uppercase;
    }

    &:hover {
      a {
        background-color: #006ab0;
        text-decoration: none;
      }
    }
  }
}
