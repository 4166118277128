#files_content {
  a.activeContext {
    color: #34a2dc;
  }
}

.file {
  background-position: 20px 15px;
  background-image: url("#{$cdn-url}/images/companies/placeholder_contact.jpg");
  background-repeat: no-repeat;
  border-top: 1px solid #ccc;
  float: left;
  height: 55px;
  margin: 0 30px 20px 0;
  position: relative;
  padding: 15px 0 0 80px;
  width: 305px;

  a.delete {
    background-image: url("#{$cdn-url}/img/trash.png?v=3");
    height: 14px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 14px;
  }

  b {
    font-weight: 400;
    font-size: 9px;
  }

  p {
    font-weight: 700;
    font-size: 12px;
    padding: 0 0 0 0;
    margin: 0;
  }
}
