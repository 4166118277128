#comments .inner_comment a.file {
  background-repeat: no-repeat;
  padding: 0 0 0 20px;
  display: block;
  line-height: 18px;
  margin: 6px 0 0 0;
}
#comments .inner_comment a.file b {
  font-weight: normal;
}
#comments .inner_comment {
  padding: 0 20px 20px 0;
}
#comments.active {
  position: relative;
  top: auto;
  left: auto;
  visibility: visible;
  opacity: 1;
}
#comments .poster {
  float: left;
  width: 78px;
}
#comments .poster img,
#comments .poster_self img,
#comments .poster_other img {
  width: 61px;
  padding: 1px;
  border: 1px solid #ccc;
  padding: 1px;
  border-radius: 2px;
}

#comments .poster_self {
  float: left;
  width: 78px;
}
#comments .poster_other {
  margin-right: 20px;
  float: right;
}
#comments .rendered_comment em.speech,
#comments .input em.speech {
  position: absolute;
  top: 27px;
  left: -16px;
  width: 21px;
  height: 15px;
  background-image: url("#{$cdn-url}/img/speech.png");
}
#comments .input em.speech {
  left: -4px;
  z-index: 3;
}
#comments .rendered_comment em.speech.right {
  right: -16px;
  left: auto;
  background-position: 0 -15px;
}

#comments .rendered_comment .textcontent {
  position: relative;
  font-size: 12px;
  color: #444;
  border-radius: 4px;
  resize: none;
  margin-top: 0;
  float: left;
  width: 433px;
  padding: 2px 5px 5px 7px;
  margin-bottom: 7px;
  min-height: 57px;
  line-height: 23px;
  margin-left: 12px;
  margin-right: 10px;
  border: 1px solid #ccc;
}

#comments .rendered_comment .textcontent h4 {
  font-size: 11px;
  color: $ahoy-color-aqua-dark;
  margin: 0 0 -5px 0;
  text-transform: uppercase;
}
#comments .rendered_comment .textcontent i {
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #999;
  font-size: 11px;
}
#comments .rendered_comment a.remove {
  transition: all 150ms linear;
  opacity: 0;
  position: absolute;
  top: 4px;
  right: 50px;
}
#comments .rendered_comment:hover a.remove {
  opacity: 1;
}

#comments .rendered_comment {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin: 0 0 7px 0;
}
#comments .active .textarea {
  color: #333;
}
#comments .input {
  transition: all 150ms linear;
  opacity: 0.5;
}
#comments .input:first-child {
  opacity: 1;
  margin-top: 38px;
}
#comments .input.active,
#comments .input:hover {
  opacity: 1;
}
#comments a.button {
  float: right;
  width: 65px;
  position: static;
  margin: 0 20px 0 0;
  background-image: none;
  padding: 0;
}
