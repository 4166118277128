@font-face {
  font-family: "proximanova";
  src: url("#{$cdn-url}/images/fonts/proximanova-reg-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "proximanova";
  src: url("#{$cdn-url}/images/fonts/proximanova-sbold-webfont.woff")
    format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "icomoon";
  src: url("#{$cdn-url}/images/fonts/icomoon.woff?nk8aa4") format("woff"),
    url("#{$cdn-url}/images/fonts/icomoon.ttf?nk8aa4") format("truetype"),
    url("#{$cdn-url}/images/fonts/icomoon.svg?nk8aa4#icomoon") format("svg");
  font-style: normal;
  font-weight: normal;
}
