.mobile_device {
  background-image: url("#{$cdn-url}/images/devices.png?v=2");
  background-repeat: no-repeat;
  float: left;
  height: 40px;
  margin-bottom: 15px;
  margin-top: 10px;
  padding-left: 50px;
  position: relative;
  width: 360px;

  a.delete {
    display: block;
    height: 14px;
    opacity: 0;
    position: absolute;
    right: 40px;
    top: 4px;
    transition: all 150ms linear;
    width: 14px;
  }

  h3 {
    font-size: 16px;
  }

  &:hover {
    a.delete {
      opacity: 1;
    }
  }

  &.ipad {
    background-position: 0px -56px;
  }

  &.outlook {
    background-position: 0px -169px;
  }
}
