/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

/* CSS Transition
	Usage: @include transition(width,0.3s,ease-out);
 */
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

/* Size mixin
	Usage: @include size(80px[, 40px]);
*/
@mixin size($width: 0, $height: $width) {
  height: $height;
  width: $width;
}

/* Temporary popup footer button mixin
	Usage: @include icon("\e90a");
*/

@mixin button-popup-footer(
  $color-bg: $color-primary-1,
  $color-text: $color-white,
  $hover-color-bg: darken($color-bg, 10%),
  $hover-color-text: $color-text
) {
  background: $color-bg !important;
  border-radius: 2px;
  color: $color-text !important;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;

  &:focus-visible,
  &:hover {
    background: $hover-color-bg !important;
    color: $hover-color-text !important;
  }
}
