.do_stats {
  padding: 20px;

  .do_type {
    clear: both;
    float: left;
    margin: 0 0 5px 0;
    width: 100%;

    b {
      clear: both;
      display: block;
      float: left;
      height: 3px;
      margin: 3px 0 0px 0;
      position: relative;
      width: 100%;
    }

    em {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;

      &.bg {
        height: 100%;
        left: 0;
        opacity: 0.2;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    i {
      font-style: normal;
    }

    span {
      background-color: #cc2800;
      border-radius: 2px;
      color: $color-white;
      display: block;
      float: left;
      line-height: 18px;
      margin-right: 6px;
      min-width: 21px;
      padding: 0 4px 0 4px;
      text-align: right;
    }
  }
}
