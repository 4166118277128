#planning_legenda {
  float: left;
  height: auto;
  min-height: 100px;
  position: relative;
  width: 20%;

  .legenda {
    height: 20px;
    line-height: 0;
    overflow: hidden;
    position: absolute;
  }
}
