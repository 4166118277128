#header {
  position: relative;

  .active_call {
    float: left;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;

    a {
      background-color: $color-error;
      background-image: url("#{$cdn-url}/img/callback_alert.png");
      background-position: 7px 3px;
      background-repeat: no-repeat;
      border-radius: 3px;
      color: $color-white;
      display: block;
      font-size: 14px;
      line-height: 26px;
      margin: 11px 0 0 73px;
      overflow: hidden;
      padding: 0 9px 0 32px;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
