#viz_infoo {
  float: left;
  margin-left: 10px;
  margin-top: 46px;
  width: 220px;

  a.nextbtn,
  a.prevbtn {
    background-color: $color-primary-1;
    border-radius: 3px;
    box-sizing: border-box;
    color: $color-white;
    display: block;
    float: left;
    height: 24px;
    line-height: 24px;
    padding-top: 1px;
    padding-right: 4px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: 28px;

    &.greyed {
      cursor: default;
      opacity: 0.5;
    }
  }

  a.nextbtn {
    padding-left: 4px;
  }

  a.prevbtn {
    padding-right: 4px;
  }

  .infobox {
    background-color: $color-grey-3;
    border-radius: 10px;
    border: 1px solid $color-grey-4;
    min-height: 60px;
    padding: 13px;
  }

  #compare_date,
  #current_date {
    color: #3c3c3c;
    display: block;
    float: left;
    text-align: center;
    width: 131px;
  }

  #compare_date {
    line-height: 24px;
    text-transform: lowercase;
  }

  #current_date {
    box-sizing: border-box;
    height: 24px;
    line-height: 13px;
    text-transform: lowercase;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #extra_date {
    float: left;
    margin-top: 12px;

    a#plus {
      background-image: url("#{$cdn-url}/img/plus_button_small.png");
      display: block;
      height: 20px;
      margin-left: 83px;
      margin-top: 4px;
      width: 20px;
    }

    a.nextbtn,
    a.prevbtn {
      background-color: $color-warning;
    }
  }

  #legendebox {
    margin-top: 18px;

    i {
      display: block;
      float: left;
      font-style: normal;
      line-height: 18px;
    }

    img {
      display: block;
      float: left;
      font-style: normal;
      line-height: 18px;
      margin: 0 5px 0 0;
    }

    p {
      clear: both;
      float: left;
    }
  }
}
