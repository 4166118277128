#lead_visualization {
  clear: left;
  margin-top: 5px;
  width: 80%;

  #targets_info & {
    float: left;
    margin: 40px 0 20px 0;
    width: calc(100% - 230px);

    circle {
      cursor: pointer;
    }
  }
}
