.widget-bigtitle.stats-view {
  .content {
    padding-top: 0;
  }

  #graph_header {
    // the graph header is inside a div with padding,
    //so we need negative margin and extra width to make it bigger again
    margin: 0 -19px 0 -19px;
    width: calc(100% + 38px);
    box-sizing: border-box;

    background: $color-white;
    min-height: 45px;
    border-bottom: 1px solid #e5e6e9;
    border-radius: 4px 4px 0 0;
    font-size: 1em;
    float: none;

    .widgettitle {
      font-size: 13px;

      .content_t {
        font-size: 16px;

        @media screen and (max-width: 1300px) {
          font-size: 13px;
        }
      }
    }
  }

  .graph_header_with_pipeline{
    display: flex;
    align-items: center;
  }

  #graph_filter {
    position: relative;

    #user_selection_active {
      margin-top: 0;
    }

    .middle_switches {
      position: absolute;
      top: -35px;
      left: 50%;
      transform: translateX(-50%);

      &.deal_type_picker_with_pipeline{
        @media (max-width: 1740px) {
          right: 30px;
          left: unset;
          transform: unset;
        }
      }
    }

    #stats_toggle {
      .btn-link {
        margin-left: 25px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.stats {
  margin: 20px 0 30px 0;

  b {
    border-right: 1px solid #9eb4cd;
    color: #777777;
    display: block;
    float: left;
    font-size: 11px;
    height: 25px;
    width: 350px;
  }

  p {
    background-color: $color-white;
    border: 1px solid #9eb4cd;
    border-bottom-width: 0;
    color: #777777;
    font-size: 11px;
    height: 25px;
    line-height: 25px;
    margin: 0;
    padding: 0 0 0 5px;
    width: 695px;

    &.last {
      border-bottom-width: 1px;
    }
  }

  span {
    color: #777777;
    display: block;
    float: left;
    font-size: 11px;
    height: 25px;
    padding: 0 0 0 5px;
    width: 300px;
  }
}

#empty_stat {
  color: #767373;
  font-size: 16px;
  height: 200px;
  text-align: center;
  padding-top: 200px;
}

.stats-filter-button {
  float: right;
  margin-right: 12px;
  margin-top: -38px;
}
