.overview-cell {
  /* Overwrite default div legacy styles */
  line-height: unset !important;
  height: 100% !important;

  &-warning {
    display: flex !important;
    align-items: center;

    & > svg > path {
      fill: $ahoy-color-gold;
    }
  }

  &-profit {
    font-weight: 700;
    color: $ahoy-color-mint-dark !important;
  }

  &-profit-amount-profit:before {
    content: "+ ";
  }

  &-loss {
    font-weight: 700;
    color: $ahoy-color-ruby-darkest !important;
  }

  &-profit-amount-loss:before {
    content: "- ";
  }

  &-budget {
    display: flex !important;
    align-items: center;
    width: 100% !important;
    padding-left: 0 !important;
    white-space: pre-wrap !important;

    &-icon {
      flex-shrink: 0;
      height: 24px !important;
      width: auto !important;
      margin: 0 6px 0 0 !important;

      &--over-budget {
        & svg {
          fill: $ahoy-color-ruby-dark;
        }
      }

      &--in-danger {
        & svg {
          fill: $ahoy-color-gold;
        }
      }

      &--on-track {
        & svg {
          fill: $ahoy-color-mint-dark;
        }
      }
    }

    &-negative {
      font-weight: 700;
      color: $ahoy-color-ruby-darkest !important;

      &:before {
        content: "- ";
      }
    }

    &-text {
      height: auto !important;
      width: auto !important;
      font-weight: normal !important;
    }
  }

  &-health {
    display: flex !important;
    align-items: center;
    width: 100% !important;
    padding-left: 0 !important;

    &-icon {
      flex-shrink: 0;
      height: 24px !important;
      width: auto !important;
      margin: 0 6px 0 0 !important;

      &--on-track {
        & svg {
          fill: $ahoy-color-mint-dark;
        }
      }

      &--in-danger {
        & svg {
          fill: $ahoy-color-gold;
        }
      }

      &--off-track {
        & svg {
          fill: $ahoy-color-ruby-dark;
        }
      }
    }

    &-text {
      height: auto !important;
      width: auto !important;
      font-weight: normal !important;

      &--unknown {
        color: $ahoy-color-neutral-darkest !important;
      }
    }
  }
}
