.avatar,
#filtering_menu .avatar {
  background-color: #cecece;
  border-radius: 17px;
  color: $color-white;
  display: inline;
  float: right;
  font-size: 20px;
  height: 35px;
  line-height: 35px;
  margin-right: 12px;
  position: relative;
  text-align: center;
  width: 35px;
}

.avatar img {
  border-radius: 17px;
  float: left;
  margin-right: 12px;
  width: 35px;
}

.avatarchart,
.avatarStack {
  .avatar {
    border-radius: 20px;

    &.neg {
      border: 3px solid $color-error;
    }

    &.pos {
      border: 3px solid $color-valid;
    }
  }
}

.avatarchart {
  color: #858585;
  margin-left: -20px;

  .avatarchart_x_number {
    float: left;
    margin-left: 80px;
  }

  .avatarchartrow {
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: $color-grey-1;
    min-height: 58px;
    padding: 26px 0 0 0;

    &:last-child {
      border: 0;
    }
  }
}

.avatarinfo {
  margin-left: -7px;
  margin-top: -4px;
  position: absolute;
  transition: opacity 0.1s ease-in-out;
  z-index: 2;

  &.muted {
    opacity: 0.1;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
  }
}

.average_line {
  background-color: $color-valid;
  height: 85px;
  margin-top: -27px;
  position: absolute;
  width: 4px;
}

.avatarStack {
  margin-top: 16px;

  .avatarinfo {
    margin-left: -18px;
    margin-top: 19px;
  }

  .avatarStack_line {
    box-sizing: border-box;
    height: 59px;
    margin-left: 160px;
    position: relative;
    width: calc(100% - 194px);
  }

  .average_line {
    background-color: transparent;
    margin-top: 0px;
  }
}

.avatar_extra {
  left: 55px;
  position: absolute;
  top: 10px;
  width: 120px;
}

.avatarchart_x {
  border-top: 1px solid #e6e6e6;
  margin-right: -20px;
  padding-left: 0px;
}

.avatarStack_x {
  box-sizing: border-box;
  clear: both;
  color: #787677;
  float: left;
  height: 39px;
  margin-bottom: 10px;
  margin-left: 160px;
  position: relative;
  width: calc(100% - 194px);

  .avatarchart_x_number {
    position: absolute;
    top: 0px;
  }
}

.phase_arrow {
  left: -79px;
  position: absolute;
}

.avatarchart_x_label {
  float: right;
  margin-top: 15px;
  margin-right: 25px;
}

.avatarchart_values {
  margin-left: 0;
  width: calc(100% + 19px);
}

.avatarchart_tick {
  background-color: #e6e6e6;
  height: 10px;
  position: absolute;
  width: 1px;
}

.avatarchart_tick_nr {
  box-sizing: border-box;
  display: block;
  margin-left: -14px;
  margin-top: 20px;
  text-align: center;
  width: 30px;

  &.big_tick {
    margin-left: -300px;
    width: 600px;
  }
}

.avatarStack_info {
  margin-left: 18px;
  width: 14%;
  text-align: center;
  font-size: 14px;
  color: $color-white;
  background-color: $color-primary-1;
  padding: 2px;
  border-radius: 1px;

  &.aanvaard {
    background-color: #a7d35a;
  }
}

.avatarStack_dash {
  float: right;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: $color-grey-1;
  margin-right: -19px;
  width: calc(100% - 109px);
}

#avatarStack_legend {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  color: #c9c9c9;
  margin-bottom: 10px;
  padding: 5px;
}

#avatarStack_legenditems {
  float: right;
  width: 85%;
}

.avatarStack_legenditem {
  border-left: 14px solid grey;
  color: #848484;
  opacity: 0.7;
  padding-left: 10px;
  padding-right: 10px;

  &.selected {
    opacity: 1;
  }

  &.users_legend {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  &#average {
    border-left: 14px solid #93d158;
  }
}

.avatarStack_legendtitle {
  text-align: right;
  width: 14%;
}
