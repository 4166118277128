.pro_budget {
  clear: both;
  float: left;
  margin: 10px 0 0 0;
  min-height: 35px;
  position: relative;
  width: 425px;

  a.edit {
    display: block;
    height: 14px;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
  }

  a.delete {
    display: block;
    height: 14px;
    position: absolute;
    right: -22px;
    top: 0px;
    width: 14px;
  }

  b {
    font-size: 11px;
    font-weight: 400;
    left: 0;
    position: absolute;
    top: -15px;
  }

  em {
    background-color: $ahoy-color-aqua-dark;
    display: block;
    height: 11px;
    left: 0;
    position: absolute;
    top: 0;
  }

  h4 {
    float: left;
    font-size: 11px;
    font-weight: 400;
    margin: 0 25px 0 0;
    text-align: left;
    width: 130px;
  }

  i {
    background-image: url("#{$cdn-url}/img/overdue_bg.png?v=3");
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
  }

  span {
    background-color: #ececec;
    float: left;
    height: 11px;
    line-height: 11px;
    margin-top: 3px;
    position: relative;
    width: 240px;
  }

  .red {
    font-weight: 700;
    color: #cb2800;
  }
}
