#stats_form {
  .widgettitleholder {
    left: -19px;

    &.right {
      left: auto;
      right: -19px;
    }
  }
}
