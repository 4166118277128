#calllist_header {
  float: left;
  margin: 0 0 32px 0;
  width: 100%;

  .multi-view-date {
    color: $color-primary-2;
    display: block;
    float: left;
    line-height: 24px;
    text-align: center;
    padding: 0 0 0 0;
    width: 36%;
  }

  .right {
    float: right;
  }

  #user-team-select {
    width: 160px;
    margin-right: 12px;
    float: right;
  }
}
