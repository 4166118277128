#page_no {
  margin: 10px 0 0 0;
  width: 80px;
}

label.page_no {
  display: block;
  float: left;
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0 0 5px;
  width: 65px;
}
