.document-layout-detail {
  font-family: $ahoy-font-family-inter;
  font-weight: 400;

  &__inner,
  &__right,
  &__actions,
  &__label,
  &__label-wrapper {
    display: flex;
  }

  &__back {
    position: relative;
    left: -15px;
    letter-spacing: 0.6px;
    font-family: $ahoy-font-family-inter;
    font-weight: 700;
    text-transform: uppercase;
    color: $ahoy-color-neutral-darkest !important;
  }

  &__container {
    margin-bottom: 18px;
  }

  &__inner {
    margin-bottom: 36px !important;
  }

  &__column {
    padding: 18px;
    height: 100%;
  }

  &__download {
    margin-right: 18px;
    font-family: $ahoy-font-family-inter;
    font-weight: 400;
  }

  &__upload {
    cursor: pointer;
    font-family: $ahoy-font-family-inter !important;
    font-weight: 400;
  }

  &__right {
    flex: 1;
  }

  &__left {
    flex: 1.6;
  }

  &__preview {
    max-width: 100%;
  }

  &__actions {
    margin: 18px 0;
    justify-content: flex-start;
    align-items: center;
  }

  &__preview-wrapper {
    height: 500px;
    border: solid 1px #e3e3e6;
    padding: 18px;
    background-color: #f7f7fa;
    overflow-y: scroll;
  }

  &__default-template {
    &-label,
    &-button {
      align-self: flex-end;
    }
  }

  &__fallback-font,
  &__page-numbers,
  &__custom-css {
    margin-top: 3px;
  }

  &__fallback-font {
    width: 235px;
  }

  &__fallback-font-info {
    text-align: left !important;
  }

  &__fallback-font-tooltip {
    top: -1px;
  }

  &__page-numbers {
    width: 60px;
  }

  &__template-gallery {
    margin: 56px 0;
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: flex-start;
  }

  &__label-wrapper {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__loading-indicator {
    margin: 0 6px !important;
  }

  &__description {
    line-height: 1.75 !important;
    color: #64788f !important;
    margin: 18px 0;
    width: 80%;
  }

  &__top-wrapper {
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__advanced {
    width: 100%;
  }
}

.ahoy-link.document-layout-detail__advanced-button,
.ahoy-link.document-layout-detail__back {
  color: #82828c !important;
}
