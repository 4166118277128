.cb_blue {
  display: none;
}

#rightcontent {
  .listview {
    .cbb_blue {
      background-repeat: no-repeat;
      border-radius: 3px;
      color: $color-primary-1;
      display: block;
      font-size: 17px;
      height: 21px;
      margin: 6px 0 3px 4px;
      padding: 0;
      width: 15px;

      &:before {
        content: "\f09b";
      }

      &.active {
        background-position: -17px 0;

        &:before {
          content: "\f048";
        }
      }
    }
  }
}
