.task-scheduled-dates {
  align-items: center;
  display: flex;
  margin-right: 6px;

  .task-scheduled-date {
    background-color: $ahoy-color-neutral-light;
    border-radius: 4px;
    margin-right: 6px;
    min-width: 42px;
    overflow: hidden;
    text-align: center;
    transition-duration: .1s;
    transition-timing-function: ease-in;
    transition-property: background-color, box-shadow;
    user-select: none;

    &:hover {
      background-color: $ahoy-color-neutral;
      text-decoration: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px $ahoy-color-neutral-dark;
    }

    &-month {
      background: $ahoy-color-neutral-light;
      color: $ahoy-color-neutral-darkest;
      font-size: 10px;
      font-family: $ahoy-font-family-inter;
      font-weight: 700;
      letter-spacing: 0.6px;
      padding: 1px 0;
    }

    &-day {
      color: $ahoy-color-teal-darkest;
      font-family: $ahoy-font-family-inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      padding-bottom: 2px;
      padding-top: 4px;
    }
  }

  &-overflow {
    font-family: $ahoy-font-family-inter;
    font-weight: 500;
    margin-top: -8px;
    margin-right: 6px;
  }
}

#title_buttons {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  .task-done-message {
    margin-right: 12px;
  }

  .ahoy-button-group {
    margin-right: 12px;
  }

  &:not(.task-done) {
    .button-task-done {
      display: inline-block;
    }
    .button-task-reopen {
      display: none;
    }
    .task-done-message {
      display: none;
    }
  }

  &.task-done {
    .button-task-done {
      display: none;
    }
    .button-task-reopen {
      display: inline-block;
    }
    .task-done-message {
      display: inline-block;
    }
  }
}
