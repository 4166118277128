#ticket_uploaded_attachments {
  .att {
    float: right;
    line-height: 23px;
  }

  .link {
    background-color: #efefef;
    border-radius: 2px;
    color: #333;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 11px;
    line-height: 23px;
    margin: 0 0 6px 10px;
    padding: 0 7px 0 7px;
    pointer-events: all;
    text-decoration: none;

    &.right {
      float: right;
    }

    &:hover {
      background-color: $color-grey-4;
    }
  }

  #more_attachments {
    display: none;
  }
}

#current_uploads {
  float: left;
  width: calc(100% - 32px);

  &[data-singlefile] {
    width: 100%;
  }
}
