#rightcontent {
  .tl_description {
    p {
      font-size: 11px;
      word-break: break-word;
    }

    ol {
      list-style-type: decimal;
      margin-left: 20px;

      li {
        font-size: 11px;
        line-height: 16px;
        margin-top: 5px;
        padding-left: 0;
      }
    }

    ul {
      li {
        font-size: 11px;
        line-height: 16px;
        margin-top: 5px;
      }
    }
  }
}

.tl_description {
  p {
    word-break: break-all;
  }
}
