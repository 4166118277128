#day_off_stats.do_right {
  * {
    box-sizing: border-box;
  }
}

#day_off_stats {
  #evaluateRequestButton.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.new-banner-wrapper{
  #days_off_form.do_right,
  #day_off_stats.do_right {
    margin-top: -69px;
  }  
}

#days_off_form.do_right,
#day_off_stats.do_right {
  height: auto;
  min-height: 534px;
  background-color: $color-grey-2;
  margin-top: -63px;
  margin-right: -19px;
  overflow: hidden;
}

#days_off_form.do_right {
  .button {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
  }

  .days_off_title {
    font-size: 16px;
    font-weight: bold;
    color: $color-primary-2;
  }

  .dayoff-item {
    background-color: $color-white;
    border: 1px solid $color-grey-4;
    display: block;
    line-height: 30px;
    margin-bottom: 8px;
    padding: 0 30px 0 10px;

    .remove-day-off {
      cursor: pointer;
      font-size: 11px;
      opacity: 0.5;
      padding: 0 10px;
      position: absolute;
      right: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  .day-off-time {
    background-color: $color-white;
    border: 1px solid $color-grey-5;
    color: #545458;
    font-size: 11px;
    height: 40px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 10px;
    padding-left: 10px;
    position: relative;

    .day_off_delete {
      font-size: 22px;
      line-height: 40px;
      position: absolute;
      right: 10px;
      top: 5px;

      &.icon-square-o:before {
        margin-right: 3px;
      }
    }

    .day-off-time-second-line {
      display: block;
    }

    &.approval {
      height: 60px;

      .approval_request {
        font-size: 11px;
        font-style: italic;
        line-height: 20px;
        position: relative;
      }
    }
  }

  .onoffholder {
    .full_day_off {
      font-style: normal;
      width: auto;
    }

    .onoff {
      position: absolute;
      right: 0;
    }
  }

  .select {
    height: 25px;
    line-height: 25px;
    margin-top: 10px;
    width: 100%;
  }

  .tp_container {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  #detailed_options {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

#days_off_ajaxholder {
  .do_left {
    float: left;
    width: 680px;
    position: relative;
  }

  .do_right {
    float: right;
    position: relative;
    width: 228px;

    .small_notice {
      background-image: url("#{$cdn-url}/images/check_notify_big.png");
      background-repeat: no-repeat;
      font-size: 11px;
      line-height: 23px;
      padding: 0 0 0 30px;
    }
  }

  .open_requests {
    bottom: 14px;
    left: 24px;
    position: absolute;
  }
}

#days_off_form {
  input.timepicker {
    border: 1px solid $color-grey-5;
    font-size: 12px;
    padding: 3px 3px 3px 24px;
    line-height: 16px;
    height: 16px;
    width: 93px;
  }

  .days-off-form-wrapper {
    height: 454px;
    overflow-y: auto;
    padding: 20px;
    position: relative;
  }

  .save-button-wrapper {
    background-color: $color-grey-2;
    bottom: 0;
    left: 0;
    padding: 20px;
    position: absolute;
    right: 0;
  }

  .tp_wrapper {
    width: 120px;
  }

  .close_button {
    cursor: pointer;
    position: absolute;
    padding: 0 20px;
    top: 0px;
    opacity: 0.8;
    right: 0px;
    color: $color-white;
    height: 40px;
    line-height: 40px;

    &:hover {
      opacity: 1;
    }
  }

  #day_off_header {
    height: 40px;
    display: block;
    background-color: $color-primary-2;
    line-height: 40px;
    font-size: 20px;
    color: $color-white;
    padding: 0 52px 0 20px;
    text-transform: uppercase;
  }

  #day_off_request {
    position: relative;
  }

  #days_off_remove {
    margin-bottom: 10px;
    position: relative;
    width: 200px;
  }

  #days_off_remove:not(.show-checkbox) .day_off_delete {
    display: none;
  }

  #detailed_options label {
    display: inline-block;
    min-width: 50px;
  }

  #time_taken_info {
    display: inline-block;
    height: 40px;
    line-height: 20px;
    margin: 15px 0;
    text-align: center;
    width: 100%;

    &.red .remaining_time .time {
      color: red;
    }
  }

  #removebutton {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  #savebutton.disabled,
  #removebutton.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.c_month {
  float: left;
  margin: 0 30px 15px 0;
  width: 140px;

  b,
  h5 {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  h5 {
    font-size: 11px;
    line-height: 22px;
    text-align: center;
    width: 140px !important;
  }

  b {
    cursor: pointer;
    float: left;
    font-weight: 400;
    height: 16px;
    line-height: 16px;
    margin: 2px 1px 2px 1px;
    position: relative;
    text-align: center;
    width: 18px;

    em {
      font-style: normal;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    i {
      display: none;
    }

    &.active:not(.keep-background) {
      background-color: #008ae6 !important;
      opacity: 0.6;
      color: $color-white;
    }

    &.dayselected {
      background-color: #008ae6 !important;
      opacity: 0.6;
      color: $color-white;
    }

    &.do {
      background-color: $color-grey-5;
      cursor: pointer;
      color: $color-white;
      font-weight: 400;
    }

    &.full_day_off,
    &.custom {
      font-weight: 400;
      cursor: pointer;
      color: $color-white;
    }

    &.valid_no {
      opacity: 0.5;
    }
  }
}

.c_year {
  float: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  width: 200px;
}

.c_year--flexed {
  display: inline-flex;
  justify-content: center;
  line-height: 30px;
  float: none;
}

.day_off_selected .c_month b.do:not(.keep-background),
.day_off_selected .c_month b.full_day_off:not(.keep-background),
.day_off_selected .c_month b.custom:not(.keep-background),
.day_off_selected .c_month b.before_noon_off:not(.keep-background),
.day_off_selected .c_month b.after_noon_off:not(.keep-background) {
  background-color: $color-grey-5 !important;
  color: $color-white;
}

#config_days_off {
  &.saving {
    pointer-events: none;
  }
}
