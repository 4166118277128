$arrow-down-icon: url("#{$cdn-url}/images/down_arrow.svg");
$arrow-up-icon: url("#{$cdn-url}/images/up_arrow.svg");

#rightcontent .listview.checkboxes .cbb_blue {
  float: right;
  margin-right: 8px;
}

#rightcontent .listview .header .cbb_blue {
  margin-top: -2px;
}

.listview {
  height: auto !important;
  margin-left: -19px;
  min-height: 75px;
  padding-bottom: 0;
  position: relative;
  width: calc(100% + 38px);

  a.icon-checkmark:before {
    margin-top: 2px;
  }

  a.normal {
    color: $color-primary-2;
    text-decoration: none;
  }

  a.pdf {
    height: 19px;
    width: 19px;
    display: block;
    float: left;
    clear: none;
    background-image: url("#{$cdn-url}/images/pdf_ultrasmall_table.gif?v=2");
    margin: 1px 0 0 3px;
  }

  div {
    cursor: pointer;
    display: block;
    float: left;
    height: 35px;
    line-height: 35px;
    overflow: hidden;
    padding: 0 1px 0 5px;
    width: 110px;
    white-space: nowrap;
  }

  em.indicator {
    cursor: help;
    display: block;
    width: 30px;
    margin-top: 11px;
    height: 11px;
  }

  input.checkbox {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  div,
  span {
    box-sizing: border-box;
  }

  p.pagination {
    margin-left: 20px;
    vertical-align: top;
    padding: 15px 0 3px 0;
    margin-bottom: 0;
    width: auto;
    float: left;
    line-height: 20px;
    height: 20px;
    font-size: 11px;
  }

  span {
    border-bottom: 0;
    cursor: pointer;
    float: left;
    line-height: 30px;
    overflow: hidden;
    padding: 0 1px 0 5px;
    position: relative;
    white-space: nowrap;
    width: 110px;

    b {
      &.notpaid {
        color: $color-warning;
        font-weight: 400;
      }

      &.overdue {
        background-color: $color-error;
        color: $color-white;
        display: block;
        height: 39px;
        left: 0px;
        padding-left: 5px;
        position: absolute;
        text-transform: uppercase;
        top: 0px;
        width: 180px;
      }
    }
  }

  .nothing-found-message-container {
    // Override default .listview div styling
    height: 85px;
    padding: 24px;
    width: 100%;

    .nothing-found-message {
      margin-bottom: 0;
    }
  }

  &.overview-page-listview {
    .nothing-found-message-container {
      background-color: white;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
    }
  }

  &:not(.overview-page-listview) {
    margin-top: -19px;
    padding-bottom: 12px;

    .ahoy-pagination {
      margin-left: 20px;
    }

    #toolset {
      margin-right: 20px;
    }
  }

  .big {
    float: left;
    position: relative;
    width: 180px;
  }

  .bigger {
    width: 200px;
  }
  .biggest {
    width: 240px;
  }

  .entry.bigtodos span,
  .entry.bigtodos b,
  .entry.bigtodos i,
  .entry div span {
    line-height: 30px;
  }

  .entry {
    background-color: #fff;
    border: 0px solid $color-error;
    border-top: 1px solid $color-grey-4;
    box-sizing: border-box;
    float: left;
    margin: 0;
    overflow: visible;
    padding: 0 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: flex;

    &.has-color::before {
      content: '';
      background-color: var(--border-left-color);
      height: 100%;
      left: 0;
      width: 6px;
      position: absolute;
    }

    &.entry--no-action,
    &.entry--no-action > * {
      cursor: default;
    }

    .actions_right {
      display: none;
      float: right;
      margin-right: 10px;
      width: auto;
    }

    div,
    span:not(.hasicon) {
      color: $ahoy-color-teal-darkest;
      text-overflow: ellipsis;
    }

    div {
      position: relative;
    }

    span.e {
      border-bottom: 0;
      border-top: 0;
      display: block;
      font-weight: 400;
      height: 39px;
      margin: 0;
      line-height: 39px;
      overflow: hidden;
      padding: 0 1px 0 5px;
      white-space: nowrap;
    }

    &:nth-child(2) {
      border-top: 1px solid $ahoy-color-neutral;
    }

    &:last-child {
      div {
        border-bottom: 0;
      }
    }

    &:hover {
      background-color: $color-grey-1 !important;

      .actions_right {
        display: block;
      }

      .icon-bin2,
      .icon-plus {
        &:before {
          opacity: 0.5;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  .extra {
    color: #999;
    font-style: italic;
    width: 100%;
  }

  .filelogo {
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 24px;
  }

  .group {
    background-color: #f5f5f5;
    font-weight: 700;
    font-size: 13px;
    padding-left: 23px;
    width: 100%;

    i {
      box-sizing: border-box;
      font-size: 12px;
      font-style: normal;
      float: right;
      padding-left: 5px;
      text-align: left;
      width: 15%;
    }
  }

  .headers {
    background-color: #f7f7fa;
    padding: 0 15px;
    width: 100%;
    display: flex;

    .bulk-action-checkbox-container {
      padding-top: 0 !important;
      height: 48px;
    }
  }

  .header {
    box-sizing: border-box;
    border-top: 0;
    cursor: default;
    font-family: $ahoy-font-family-inter;
    font-weight: 500;
    height: 42px;
    line-height: 18px;
    padding: 12px 1px 12px 5px;

    .header-text {
      cursor: default;
      font-size: 14px;
      line-height: 18px;
      max-width: 100%;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      width: auto;
    }

    &.is-sortable {
      cursor: pointer;

      .header-text {
        cursor: pointer;
      }

      &::after {
        background-repeat: no-repeat;
        background-position: center center;
        content: "";
        height: 16px;
        position: relative;
        top: 2px;
        width: 16px;
      }
    }

    &:hover,
    &.ASC,
    &.DESC {
      background-image: none;

      .header-text {
        color: $color-primary-2;
        max-width: calc(100% - 16px);
      }

      &::after {
        display: inline-block;
      }
    }

    &:not(.ASC):not(.DESC)::after {
      background-image: $arrow-up-icon;
      opacity: 0.5;
    }

    &.ASC {
      &::after {
        background-image: $arrow-up-icon;
      }
    }

    &.DESC {
      &::after {
        background-image: $arrow-down-icon;
      }
    }

    &:last-of-type {
      border-right: none;
    }
  }

  .headers.has-bulk-action .header {
    height: 48px;
    padding: 15px 1px 12px 5px;
  }

  .item {
    .left {
      float: left;
      font-style: normal;
      line-height: 24px;
    }

    .se {
      border: 0;
      display: block;
      float: left;
      height: 22px;
      line-height: 24px;
      margin: 3px 3px 3px 3px;
      padding: 0;
      width: 18px;
    }
  }

  .listview_container {
    border-radius: 3px;
    clear: both;
    cursor: default;
    float: left;
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;

    .header {
      border-top: 0;
    }
  }

  .rs4 {
    width: 4%;
  }
  .rs5 {
    width: 5%;
  }
  .rs7 {
    width: 7%;
  }
  .rs8 {
    width: 8%;
  }
  .rs10 {
    width: 10%;
  }
  .rs12 {
    width: 12%;
  }
  .rs15 {
    width: 15%;
  }
  .rs16 {
    width: 16%;
  }
  .rs18 {
    width: 18%;
  }
  .rs20 {
    width: 20%;
  }
  .rs21 {
    width: 21%;
  }
  .rs24 {
    width: 24%;
  }
  .rs25 {
    width: 25%;
  }
  .rs28 {
    width: 28%;
  }
  .rs30 {
    width: 30%;
  }
  .rs33 {
    width: 33%;
  }
  .rs35 {
    width: 35%;
  }
  .rs40 {
    width: 40%;
  }
  .rs43 {
    width: 43%;
  }
  .rs45 {
    width: 45%;
  }
  .rs49 {
    width: 49%;
  }
  .rs50 {
    width: 50%;
  }
  .rs55 {
    width: 55%;
  }
  .rs60 {
    width: 60%;
  }
  .rs65 {
    width: 65%;
  }
  .rs70 {
    width: 70%;
  }
  .rs75 {
    width: 75%;
  }
  .rs80 {
    width: 80%;
  }
  .rs85 {
    width: 85%;
  }
  .rs100 {
    width: 100%;
  }

  .size20 {
    width: 20px;
  }
  .size28 {
    width: 28px;
  }
  .size30 {
    width: 30px;
  }
  .size40 {
    width: 40px;
  }
  .size45 {
    width: 45px;
  }
  .size50 {
    width: 50px;
  }
  .size60 {
    width: 60px;
  }
  .size70 {
    width: 70px;
  }
  .size80 {
    width: 80px;
  }
  .size85 {
    width: 85px;
  }
  .size90 {
    width: 90px;
  }
  .size100 {
    width: 100px;
  }
  .size110 {
    width: 110px;
  }
  .size130 {
    width: 130px;
  }
  .size140 {
    width: 140px;
  }
  .size150 {
    width: 150px;
  }
  .size155 {
    width: 155px;
  }
  .size160 {
    width: 160px;
  }
  .size170 {
    width: 170px;
  }
  .size180 {
    width: 180px;
  }
  .size194 {
    width: 194px;
  }
  .size200 {
    width: 200px;
  }
  .size220 {
    width: 220px;
  }
  .size229 {
    width: 227px;
  }
  .size240 {
    width: 240px;
  }
  .size250 {
    width: 250px;
  }
  .size274 {
    width: 274px;
  }
  .size293 {
    width: 293px;
  }
  .size300 {
    width: 300px;
  }
  .size319 {
    width: 319px;
  }
  .size336 {
    width: 336px;
  }
  .size360 {
    width: 360px;
  }
  .size380 {
    width: 380px;
  }
  .size400 {
    width: 400px;
  }
  .size410 {
    width: 410px;
  }
  .size420 {
    width: 419px;
  }
  .size425 {
    width: 425px;
  }
  .size430 {
    width: 430px;
  }
  .size469 {
    width: 458px;
  }
  .size470 {
    width: 470px;
  }
  .size540 {
    width: 540px;
  }
  .size600 {
    width: 600px;
  }
  .size650 {
    width: 650px;
  }
  .size669 {
    width: 665px;
  }

  .small {
    float: left;
    position: relative;
    width: 50px;
  }

  .smallwidget & {
    display: inline-block;
    margin-top: 10px;
    padding-bottom: 16px;
    width: calc(100% + 38px);
  }

  .todos {
    background-color: transparent;
    line-height: 18px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    width: 100%;

    b {
      clear: none;
      float: none;
      text-align: left;
      width: auto;
    }

    .icon_3 {
      margin-right: 5px;
    }

    .icon_1,
    .icon_2,
    .icon_3,
    .icon_4 {
      float: left;
      width: 20px;
    }
  }

  .total {
    font-size: 11px;
    font-weight: normal;
    text-align: left;
  }

  &.animated {
    .entry {
      transition: all 250ms linear;

      &.deleted {
        overflow: hidden;
        height: 0;
        opacity: 0;
      }
    }
  }

  &.editable,
  &.deletable {
    .entry {
      &:after {
        font-family: "icomoon";
        opacity: 0.35;
        position: absolute;
        right: 0;
        top: 0;
        width: 34px;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &.editable {
    .entry {
      &:after {
        content: "\e074";
      }
    }
  }

  &.deletable {
    .entry {
      &:after {
        content: "\e9ad";
      }
    }
  }

  .icon-bin2,
  .icon-plus {
    &:before {
      opacity: 0.35;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &.grouped_listview {
    float: left;
    margin-top: 0;
    margin-bottom: 10px;

    .entry {
      background-color: $color-white !important;
      height: auto;

      a.edit {
        float: none;
        display: none;
        opacity: 0.75;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        background-color: #e6e6e6 !important;

        a.edit {
          display: block;
          position: absolute;
          top: 0px;
          right: 60px;
        }
      }
    }
  }

  &.inline {
    display: inline-block;
    padding-bottom: 16px;

    i.border {
      clear: both;
      display: block;
      float: left;
      width: 868px;
    }

    .active_0 {
      display: none;
      opacity: 0.5;
    }

    .first {
      padding-right: 0;
    }

    .table_hidden {
      display: none !important;
    }

    &.show_inactive {
      .active_0 {
        display: block;
        opacity: 0.5;
      }
    }

    &.show_table_hidden {
      .table_hidden {
        display: block !important;
      }
    }
  }

  &.notopmargin {
    span {
      margin-top: 0;
    }
  }
}

.content .listview .entry .unplanned,
.content .listview .entry .planned {
  margin-top: 8px;
}

.content .listview.column-grow .headers,
.content .listview.column-grow .entry {
  display: flex;
}

.content .listview.column-grow .headers .header {
  flex-grow: 1;
}

.listview.column-grow .entry > div {
  flex-grow: 1;
}

.listview-actions {
  padding: 30px 19px 0;
}

#totals {
  margin: 10px 5px 0 0;
  text-align: right;
}

.bulk-actions-container {
  width: 100%;
  height: auto;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
}

.bulk-action-checkbox-container {
  z-index: 2;
  width: auto !important;
  margin-right: 18px;
  display: flex !important;
  align-items: center;
  padding-top: 2px !important;
  overflow: visible !important;

  .bulk-action-checkbox {
    overflow: visible !important;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 36px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
