.tabletview {
  float: left;
  clear: both;
  margin: 0 0 8px 0;

  b {
    float: left;
    font-weight: 700;
    width: 150px;
  }

  i {
    background-color: #f3f4f4;
    border: 1px solid #e3e4e4;
    border-radius: 3px;
    float: left;
    padding: 0 0 0 5px;
    width: 240px;
  }
}
