@import "utils/variables";

.api {
  padding-bottom: 5px;

  h2 {
    color: $color-primary-2;
    font-size: 14px;
    padding: 0 0 16px 0;
  }

  input.copyme,
  textarea.copyme {
    &:focus {
      background-color: $color-grey-4;
      outline: none;
    }
  }

  input.copyme {
    margin-bottom: 18px;
    border: 1px solid $color-grey-4;
    border-radius: 1px;
    background-color: $color-grey-3;
    line-height: 25px;
    height: 25px;
    width: 200px;
    padding: 0 0 0 15px;
    color: $color-primary-2;
  }

  textarea.copyme {
    margin-bottom: 2px;
    border: 1px solid $color-grey-4;
    background-color: $color-grey-3;
    border-radius: 1px;
    line-height: 25px;
    height: 100px;
    width: 425px;
    resize: none;
    padding: 15px;
    color: $color-primary-2;
  }
}

.api-imgtext {
  float: right;
  line-height: 76px;
  font-size: 40px;
  padding-left: 5px;
}

.api-image {
  float: right;
  height: 76px;
  padding-left: 15px;
}

#rightcontent {
  .api {
    p,
    &.tl_description p,
    &.tl_description ul li {
      font-size: 12px;
    }
  }
}
