/* Projects > Roadmap: different paddings because the roadmap-dots have different sizes; */
.roadmap {
  padding: 10px 8px 10px 11px;
}

/* Projects > Roadmap meetings */
.roadmap-dashes {
  border-left: 1px dashed #aaa;
  position: absolute;
  width: 0;
}

a.roadmap-meeting {
  background: $color-white;
  color: #999;
  display: block;
  font-size: 18px;
  height: 20px;
  margin-left: -16px;
  line-height: 32px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  width: 32px;

  &:hover {
    color: $color-primary-2;
    text-decoration: none;
  }
}

/* Projects > Roadmap milestones */
.roadmap-ms {
  font-size: 14px;
  line-height: 20px;
  pointer-events: none;
  position: relative;
}

.roadmap-border {
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  position: absolute;

  &--clickable:hover {
    border-color: $color-primary-2;
    max-width: none !important;
    z-index: 1;
  }
}

.roadmap-text {
  background: $color-white;
  border: 0px solid #ccc;
  display: block;
  overflow: hidden;
  padding-left: 3px;
  padding-right: 5px;
  pointer-events: all;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;

  &--clickable:hover {
    border-color: $color-primary-2;
    color: $color-primary-2;
    max-width: auto;
    overflow: visible;
    position: relative;
    text-decoration: none;
    z-index: 2;
  }
}

.roadmap-time {
  color: $color-primary-2;
  font-size: 12px;
  opacity: 0.5;
}

/* Roadmap milestones > Variations > Positioned with top: 0 */
.roadmap-top {
  .roadmap-text {
    border-bottom-width: 1px;
    padding-top: 3px;
  }
}

/* Roadmap milestones > Variations > Positioned with bottom: 0 */
.roadmap-bottom {
  .roadmap-text {
    border-top-width: 1px;
    padding-bottom: 3px;
  }

  > .roadmap-border {
    bottom: 0;
  }
}

/* Roadmap road: odd height allows to align to a 1px line */
.roadmap-road {
  height: 5px;
  padding: 5px 0;
  position: relative;
}

.roadmap-line {
  height: 5px;
  position: absolute;
  top: 5px;
  z-index: 1;

  &.bg-neutral {
    z-index: 0;
  }
}

.roadmap-dot {
  border: 2px solid $color-white;
  border-radius: 50%;
  height: 15px;
  margin-right: -9px;
  margin-top: -7px;
  position: absolute;
  width: 15px;
  z-index: 2;
}

.roadmap-start {
  background-color: $color-primary-2;
  border: 2px solid $color-white;
  border-radius: 50%;
  height: 21px;
  margin-top: -10px;
  margin-right: -12px;
  right: 100%;
  width: 21px;
}

#img_roadmap {
  max-width: 100%;

  #roadmap_loader &,
  #roadmap_outer_small & {
    width: 100%;
  }
}

#roadmap {
  a {
    color: #333;
    display: block;
    float: left;
    line-height: 24px;
    padding: 0 25px 0 0;
    text-decoration: none;

    img {
      margin-bottom: 0;
    }

    span {
      display: block;
      float: left;
      line-height: 24px;
    }
  }

  img {
    float: left;
    margin: 0 10px 20px 0;
  }
}

#roadmap_outer {
  min-height: 160px;
}

#roadmap_outer_small {
  min-height: 120px;
}
