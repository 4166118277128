#sync_calendar {
  clear: none;
  float: right;
  margin-right: 3px;
  width: auto;
}

#sync_state {
  background-image: url("#{$cdn-url}/images/loading_frozen.png?v=3");
  cursor: pointer;
  display: block;
  height: 16px;
  margin-top: 4px;
  opacity: 0.5;
  width: 16px;

  &:hover {
    opacity: 1 !important;
  }
}
