.tl-widget {
  background: $color-white;
  box-shadow: $shadow-boxes;
  margin-bottom: 20px;
  * {
    box-sizing: border-box;
  }
}

.tl-widget-header {
  border-bottom: 1px solid $color-grey-2;
  padding: $pad-half $pad;

  .tl-widget-header-title {
    display: inline-block;
    font-size: 16px;
    margin: 0;
    padding: $pad-half 0;
  }
}

.tl-widget-content {
  padding: $pad;

  h4 {
    color: $color-primary-2;
    font-size: 16px;
    margin: $space-quad 0 $space;
  }
}

.tl-widget-footer {
  border-top: 1px solid $color-grey-2;
  padding: $pad-half $pad;
}

.tl-widget-highlight {
  background: $color-grey-2;
  padding: $pad-half $pad;

  .tl-widget-highlight-title {
    color: $color-primary-2;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1.2px;
    margin: $space-half 0;
  }

  span + .btn {
    margin-left: $space;
  }
}
