#day_off_stats {
  .day_off_color {
    background-color: $color-grey-2;
    border-radius: 0px;
    height: 30px;
    min-width: 2px;
    padding: 0;
    width: 7px;
  }

  .day_off_text_wrapper {
    display: inline-block;
    height: 30px;
    text-align: left;
    background-color: $color-grey-2;
    width: calc(100% - 30px);

    .day_off_text {
      background-color: $color-grey-2;
      text-align: left;
      width: 100%;
      color: black;
      line-height: 15px;
      height: 15px;
    }

    .day_off_type {
      background-color: $color-grey-2;
      text-align: left;
      width: 100%;
      color: $color-primary-2;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      height: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .limited {
    .day_off_color {
      height: 45px;
    }
  }
}
