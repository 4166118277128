.middle_switches {
  display: inline-block;
  margin: 0;
  width: auto !important;

  a.btn,
  .tab {
    background-color: $color-grey-2;
    clear: none;
    color: $color-body-text;
    cursor: pointer;
    display: block;
    float: left;
    line-height: 24px;
    padding: 0 14px 0 14px;
    text-align: center;
    text-decoration: none;

    &:first-child {
      margin-left: 0;
    }
  }

  a.btn4 {
    margin-left: 10px;
    width: 30px;
  }
}
