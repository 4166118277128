#filtering_menu {
  background-color: $color-white;
  box-shadow: 0 0 5px #999;
  border-radius: 1px;
  left: -5px;
  right: -5px;
  top: -30px;
  padding: 0 30px 30px 33px;
  position: absolute;
  z-index: 10;
  margin-bottom: 20px;

  .btn {
    font-size: 14px;
    position: absolute;
    right: 30px;
    top: 5px;
  }

  div {
    line-height: 18px;
    margin: 8px 0 0 0;

    input {
      margin-left: 9px;
    }
  }

  h2 {
    background-color: #e4e4e4;
    clear: both;
    color: #444;
    float: left;
    font-size: 17px;
    font-weight: 400;
    margin: 0 0 10px -33px;
    padding: 10px 33px 10px 30px;
    width: 100%;
  }

  h3 {
    color: $color-primary-1;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    margin: 33px 0 0 0;
    padding: 0 0 10px 0;
    width: 151px;

    input {
      margin-left: 0;
      margin-top: 5px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  input {
    float: left;
    height: 12px;
    margin: 2px 0 0 0;
    padding: 0;

    &[type="checkbox"] {
      display: none;

      &:checked + label {
        opacity: 1;
      }
    }
  }

  label {
    cursor: pointer;
    display: block;
    opacity: 0.4;

    .avatar {
      float: left;
    }
  }

  select.select {
    margin: 10px 0 0 0;
    width: 250px;
  }

  .biglabel {
    display: block;
    float: left;
    width: 100%;

    label {
      line-height: 35px;
    }
  }

  .division {
    clear: both;
    float: left;
    margin: 0 0 30px 0;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .teamdiv {
    display: inline-block;
    margin: 20px 0 0 0;
    vertical-align: top;
    width: 25%;
  }

  &.users {
    top: 47px;
  }
}
