// Variables
$c-light-gray: #ccc;
$c-lighter-gray: $color-grey-4;
$c-very-light-gray: #eee;

// Default select
select::-ms-expand {
  display: none;
}

// Custom select
.select:not(.div) {
  -moz-appearance: none;
  border-radius: 0;
  background-image: url("#{$cdn-url}/images/select_bg.gif");
  background-repeat: repeat-y;
  background-position: center right;
  background-color: $color-white;
  border: 1px solid $color-grey-5;
  height: 22px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-right: 25px !important;
  width: 179px;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 12px;
    line-height: 20px;
    padding-left: 5px;
  }
}

.select-small {
  float: right;
  width: 60px !important;
}

.select-label {
  line-height: 22px;
}

.select-padded {
  padding: 5px 0 10px;
}
