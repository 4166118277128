.preloader {
  #header & {
    background-color: $ahoy-color-neutral-lightest;
    background-image: url("#{$cdn-url}/images/preloader.gif");
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px;
    height: 150px;
    width: 480px;
  }

  #alt_body & {
    margin-top: 50px;
  }

  #alt_body & {
    margin-top: 50px;
  }

  #alt_body &,
  #pages & {
    background-image: url("#{$cdn-url}/images/preloader.gif");
    background-position: center center;
    background-repeat: no-repeat;
    height: 500px;
    width: 100%;
  }
}
