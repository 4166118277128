img.contact_thumb {
  border-radius: 50%;
  border: 0;
  float: left;
  height: 55px;
  padding: 1px;
}

.userinfo {
  position: relative;
  width: 100%;

  h1 {
    float: left;
    font-size: 17px;
    margin: -2px 0 0 0;
    width: 330px;
  }

  i {
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    margin-right: 5px;
    vertical-align: top;
    width: 120px;
  }

  // make sure we don't mess up the specificity of imageupload since it relies on width
  span:not(.disable-legacy-rule):not(.imageupload):not(.hidden) {
    display: block;
    margin-bottom: 3px;
    width: 100%;
  }

  b {
    display: inline-block;
    font-weight: 400;
  }

  .adres {
    float: left;
    width: 350px;
    clear: both;
    margin: 0 0 6px 0;
  }

  .b {
    float: left;
    margin: 0 0 5px 0;
    min-height: 28px;
    position: relative;
    width: 100%;
  }

  .bigger {
    width: 200px;
  }

  .biggy {
    width: 340px;
  }

  .employee_h1 {
    float: left;
    width: auto;
    margin-right: 6px;
  }

  .employee_h2 {
    float: left;
    font-weight: 400;
    font-size: 13px;
    width: auto;
    line-height: 25px;
  }

  .employee_h3 {
    clear: both;
    float: left;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
  }

  .imageupload {
    display: block;
    float: left;
    margin: 0 12px 12px 0;
    position: relative;
    width: 48px;

    a {
      background-color: rgba($color-black, 0.33);
      border-radius: 200px;
      color: $color-white;
      display: none;
      height: 48px;
      left: 0;
      line-height: 55px;
      text-decoration: none;
      text-align: center;
      position: absolute;
      top: 0;
      width: 48px;

      &:before {
        left: 2px;
        position: relative;
        top: -2px;
      }
    }

    &:hover a {
      display: block;
    }
  }

  .inlineblock {
    display: inline-block;
    font-weight: 400;
    width: calc(100% - 125px);

    &.word-break {
      word-break: break-word;
    }
  }

  .left {
    float: left;
  }

  .progressbar {
    margin-top: 3px;
  }

  .right {
    float: left;
    max-width: calc(100% - 85px);

    h1 {
      width: 100%;
    }

    span {
      width: auto;

      a {
        display: block;
        margin-top: 5px;
      }
    }
  }
}

#userinfo {
  a {
    cursor: pointer;

    &.geocode {
      display: inline-block;
      height: 12px;
      position: relative;
      width: 20px;

      i {
        background-image: url("#{$cdn-url}/images/geocode.png?v=2");
        display: block;
        height: 17px;
        left: 7px;
        position: absolute;
        top: 0px;
        transition: all 150ms linear;
        width: 18px;

        &:hover {
          opacity: 0.65;
        }
      }
    }

    &.linkedin {
      background-image: url("#{$cdn-url}/images/linkedin.svg");
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 16px;
      margin-top: 1px;
      position: relative;
      transition: all 150ms linear;
      vertical-align: top;
      width: 16px;

      &:hover {
        opacity: 0.65;
      }
    }

    &.small {
      font-size: 13px;
    }
  }

  em {
    font-style: normal;
    margin-bottom: 6px;
    margin-top: 6px;
  }

  h1 > a.small {
    margin-left: 10px;
  }

  p {
    font-size: 12px;
    margin: 10px 0 0 0;
  }

  .se {
    border: 0;
    display: block;
    float: left;
    height: 22px;
    line-height: 24px;
    margin: 3px 3px 3px 3px;
    padding: 0;
    width: 18px;
  }

  .sep_line {
    &:last-child {
      display: none;
    }
  }

  .trendstop {
    background-color: $color-secondary-2;
    border-radius: 2px;
    color: $color-white;
    float: left;
    margin-bottom: 4px;
    margin-top: 4px;
    opacity: 1;
    padding: 0 6px 0 6px;
    transition: all 150ms linear;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }

  #burndown_stats {
    margin-bottom: 7px;
    margin-top: 3px;
  }

  #toolset {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
