.state {
  background-image: url("#{$cdn-url}/images/overlay_status.png?v=2");
  clear: both;
  float: left;

  .e & {
    background-image: url("#{$cdn-url}/images/overlay_status_grey.png?v=2") !important;
  }
}

.state_Niet-actief {
  background-color: #57abff;
  display: none !important;
}

.state_Prospect {
  background-color: #ea3a00;
}

.state_Suspect {
  background-color: #fd8500;
}

.state_Freelancer {
  background-color: #2bae1c;
}

.state_Student {
  background-color: #ea3a00;
}

.state_Docent {
  background-color: #fd8500;
}

.state_Leverancier {
  background-color: #2bae1c;
}

.state_Kennis {
  background-color: #ff473b;
}

.state_Actief,
.state_Klant {
  background-color: $color-valid;
}

.state_Gestopt {
  background-color: #ff473b;
}

.emailstate {
  display: block;
  height: 24px;
  margin: 7px 0 0 0px;
  width: 24px;
}
