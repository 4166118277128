.select-wrapper {
  align-items: center;
  display: flex;
  height: 36px !important;
  margin-left: 12px;

  &.segfilter-active.segfilter-editable .segment-tools {
    display: flex;
  }

  select#id_select_segment {
    height: 25px;
    width: 210px !important;
  }

  select#id_select_segment {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4gIDxwYXRoIGZpbGw9IiM4MjgyOGMiIGZpbGwtb3BhY2l0eT0iMC44NCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMy40NjQ0NjYwOSw2LjA4NzEwNjkgTDcsOS42MjI2NDA4IEwxMC41MzU1MzM5LDYuMDg3MTA2OSBDMTAuOTI2MDU4Miw1LjY5NjU4MjYgMTAuOTI2MDU4Miw1LjA2MzQxNzYzIDEwLjUzNTUzMzksNC42NzI4OTMzMyBDMTAuMTQ1MDA5Niw0LjI4MjM2OTA0IDkuNTExODQ0NjQsNC4yODIzNjkwNCA5LjEyMTMyMDM0LDQuNjcyODkzMzMgTDcsNi43OTQyMTM2OCBMNC44Nzg2Nzk2Niw0LjY3Mjg5MzMzIEM0LjQ4ODE1NTM2LDQuMjgyMzY5MDQgMy44NTQ5OTAzOSw0LjI4MjM2OTA0IDMuNDY0NDY2MDksNC42NzI4OTMzMyBDMy4wNzM5NDE4LDUuMDYzNDE3NjMgMy4wNzM5NDE4LDUuNjk2NTgyNiAzLjQ2NDQ2NjA5LDYuMDg3MTA2OSBaIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: 97.5% 11px;
    border: 0;
    padding: 0 24px 0 6px;
    z-index: 2;
  }

  .segment-tools {
    display: none;
    margin: 0 0 0 12px;
  }

  .segment-tool {
    display: block;
    width: 18px;
    height: 18px;
  }

  label {
    margin-right: 6px;
  }
}
