#document {
  #margins_draggable {
    display: none;
    height: 806px;
    left: 0;
    position: absolute;
    top: 0;
    width: 570px;
    z-index: 1;

    div {
      cursor: move;
      position: absolute;
    }

    .mtop {
      border-top: 5px solid #999;
      left: 1px;
      top: 10px;
      width: 100%;
    }

    .mbottom {
      border-top: 5px solid #999;
      bottom: 10px;
      left: 1px;
      width: 100%;
    }

    .mleft {
      border-left: 5px solid #999;
      height: 100%;
      left: 10px;
      top: 1px;
      width: 0px;
    }

    .mright {
      border-left: 5px solid #999;
      height: 100%;
      right: 10px;
      top: 1px;
      width: 0px;
    }

    &.active {
      display: block;
    }
  }

  .margindrag {
    background-image: url("#{$cdn-url}/images/margin.png");
    border-radius: 2px;
    cursor: pointer;
    display: block;
    float: left;
    height: 42px;
    margin-right: 20px;
    opacity: 0.5;
    width: 42px;

    &.active {
      opacity: 1;
    }
  }
}
