// Tickets > Reply
.reply-textareaholder {
  position: relative;
}

.reply-textarea {
  border: 1px solid $color-grey-4;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  height: 100px;
  line-height: 1.5em;
  padding: 10px 15px 30px;
  width: 100%;

  &[contenteditable] {
    background-color: $color-white;
    height: auto;
    min-height: 65px;
    padding: 10px 15px;

    &:empty::before {
      color: #999;
      content: attr(placeholder);
      display: block;
    }
  }
}

.reply-label {
  padding-right: 10px;
}

.reply-controls {
  min-height: 32px;
  line-height: 22px;
  overflow: visible;
  padding: 10px 0;
  width: 100%;

  .onoffholder {
    padding: 0;

    label {
      font-style: normal;
      padding-right: 10px;
      width: auto;
    }
  }

  .ahoy-legacy-toggle {
    margin-top: 12px;
  }
}

.reply-internal,
.reply-status,
.reply-responsible,
.reply-escalate_third_party {
  float: left;
  margin-top: 5px;
  margin-right: 30px;
}

.reply-template {
  display: inline-block !important;
  margin-top: 5px;
}
