#invoice_stats {
  a.button {
    margin-top: 30px;
  }

  h1 {
    display: block;
    float: left;
    font-size: 19px;
    position: relative;
    width: 200px;

    b {
      background-color: $color-valid;
      border-radius: 2px;
      color: $color-white;
      cursor: help;
      display: block;
      float: left;
      font-size: 13px;
      font-weight: bold;
      height: 14px;
      line-height: 14px;
      margin: 4px 0 0 6px;
      overflow: hidden;
      padding: 1px 10px 1px 10px;
    }
  }

  em {
    border-bottom: 1px solid #e0e0e0;
    clear: both;
    display: block;
    float: left;
    font-size: 13px;
    font-style: normal;
    padding-bottom: 1px;
    position: relative;
    width: 190px;
  }

  i {
    clear: both;
    cursor: help;
    display: block;
    float: left;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    width: 150px;
  }

  .rm {
    margin-right: 0px;
  }

  .st {
    float: left;
    margin-bottom: 15px;
    display: block;
    width: 200px;
  }

  .targets {
    float: left;
    display: block;
    margin-top: 3px;
    line-height: 24px;
  }

  .blue b,
  .green b,
  .red b {
    color: $color-white;
    border-radius: 2px;
    display: block;
    float: left;
    font-size: 10px;
    height: 11px;
    line-height: 9px;
    margin: 3px 0 0 6px;
    overflow: hidden;
    padding: 2px 5px 0px 5px;
  }

  .red b {
    background-color: $color-error;
  }

  .blue b {
    background-color: $color-primary-1;
  }

  .green b {
    background-color: $color-valid;
  }

  .fl {
    display: block;
    float: left;
  }

  .st {
    .fl {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .form {
    margin-top: 29px;

    select {
      margin-left: 0;
    }
  }
}
