#results_area {
  .message_results {
    .bad,
    .good {
      background-repeat: no-repeat;
      line-height: 23px;
      margin: 0 0 12px 0;
      padding: 0 0 0 30px;
    }

    .bad {
      background-image: url("#{$cdn-url}/images/check_notify_big.png");
    }

    .good {
      background-image: url("#{$cdn-url}/images/check_green_big.png");
    }
  }
}
