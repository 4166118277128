.overview-page-container {
  box-sizing: border-box;

  padding-top: 0;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 66px;

  max-width: none; // Override the .container styling
  min-width: 984px; // The current default

  margin: 0 auto;
}

.overview-page-listview .entry {
  font-size: 12px;
}

@media screen and (min-width: 1280px) {
  .overview-page-container {
    padding-left: 66px;
    padding-right: 66px;
  }
}

@media screen and (min-width: 1440px) {
  .overview-page-listview .entry {
    font-size: 13px;
  }
}

@media screen and (min-width: 1920px) {
  .overview-page-listview .entry {
    font-size: 14px;
  }
}
