#stats_info .statphase {
  float: left;
  clear: both;
  min-height: 20px;
  width: 100%;
  margin: 0 0 4px 0;
  position: relative;

  &.has_department_logo {
    max-width: 83%;

    .lineholder {
      width: calc(100% - 225px) !important;
    }
  }
}

#stats_info .statphase .right {
  float: left;
  width: 83%;
}

#stats_info .statphase .line {
  display: block;
  border-radius: 3px;
  border: 0;
  min-width: 80px;
  height: 17px;
  float: left;
  margin-top: 8px;
  background-color: $ahoy-color-aqua-dark;
}

#stats_info .statphase .lineholder {
  float: left;
  clear: none;
  margin-top: 0;
  width: 74%;
}

#stats_info .statphase h3 {
  text-transform: uppercase;
  float: left;
  width: 175px;
  color: #333;
  font-size: 12px;
  margin: 8px 0 0 15px;
  font-weight: normal;
}

#stats_info #mstats .statphase h3 {
  font-weight: bold;
}

#stats_info .statphase .right h3 {
  width: 100%;
  font-size: 14px;
  margin: 6px 0 6px 0;
  font-weight: bold;
}

#stats_info .statphase .right h3 a {
  text-transform: none;
}

#stats_info .statphase .line i {
  float: right;
  font-style: normal;
  color: $color-white;
  text-align: right;
  padding: 0 5px 0 0;
  width: 100px;
  display: block;
  line-height: 18px;
  font-size: 12px;
}

#stats_info .statphase b {
  display: inline-block;
  height: 40px;
  line-height: 38px;
  padding: 0 30px 0 30px;
  color: $color-white;
  text-align: center;
  position: relative;
  margin-top: 5px;
  font-weight: bold;
  color: $color-primary-2;
}

#stats_info .statphase b span {
  display: block;
  height: 40px;
  width: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
}

#stats_info .mstat p {
  text-align: center;
  margin-bottom: 0;
  font-size: 12px;
  color: #999;
}

#stats_info .mstat p i {
  font-size: 10px;
  font-style: normal;
}

#stats_info #mstats .mstat {
  border-left: 1px dotted #ccc;
}

#stats_info #mstats .mstat:first-child {
  border-left: 0;
}

#stats_info .mstat span.bal {
  width: 24px;
  height: 24px;
  color: $color-white;
  line-height: 24px;
  text-align: center;
  font-size: 13px;
  position: absolute;
  top: -18px;
  right: 70px;
  background-color: #9dc353;
  border-radius: 16px;
}

#stats_info h1 {
  text-transform: uppercase;
  color: #333;
  margin: 20px 20px 40px 15px;
  height: 22px;
  line-height: 22px;
  display: block;
  float: left;
}

#stats_info h2 {
  text-transform: uppercase;
  color: #333;
  margin-bottom: 10px;
  margin-left: 15px;
  font-size: 14px;
}

#stats_info h3 .float {
  font-size: 11px;
  color: $color-black;
  font-weight: normal;
  float: left;
  display: block;
}

#stats_info .logo {
  position: absolute;
  top: 60px;
  right: 22px;
  max-height: 120px;
  max-width: 150px;
  margin: 20px 20px 0 0;
}

#stats_info i.people {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-top: 22px;
  color: #c1c9d2;
  font-size: 15px;
}

#stats_info i.people.done {
  background-image: none;
  margin-left: 2px;
}

#stats_info .statphase strong {
  display: none;
}

#stats_info .slide {
  display: block;
  float: left;
  width: 100%;
  height: 17px;
  border-radius: 3px;
  background-color: $color-grey-4;
  position: relative;
  margin: 15px 0;
}

#stats_info .slide div {
  position: absolute;
  background: $ahoy-color-aqua-dark;
  top: 0px;
  left: 0px;
  height: 17px;
}

#stats_info .phase_offerte em {
  background-position: 0 -74px;
}

#stats_info .phase_aanvaard em {
  background-position: 0 -220px;
}

#stats_info .phase_meeting em {
  background-position: 0 -146px;
}

#stats_info .phase_gratis em {
  background-position: 0 -298px;
}

#stats_info .phase_on em {
  background-position: 0 -146px;
}

#stats_info .mstat em {
  margin: 0 auto 0 auto;
  position: relative;
  cursor: help;
  background-image: url('#{$cdn-url}/images/stats/bollen.png?v=2');
  width: 75px;
  height: 75px;
  display: block;
}

#stats_info .mstat {
  float: left;
  margin: 75px 0 70px 0;
  height: auto;
  clear: none;
  width: 24%;
}

#stats_info .mstat h3 {
  text-align: center;
  width: 100%;
  margin: 10px 0 0 0;
}

#stats_info .mstat span {
  float: left;
  clear: both;
  font-size: 19px;
  line-height: 19px;
  margin: 16px 0 3px 0;
  font-weight: normal;
  color: #3c3c3c;
  width: 100%;
  text-align: center;
}

#stats_info .phase_project em {
  background-position: 0 -296px;
}

#stats_info .phase_offerte em {
  background-position: 0 -74px;
}

#stats_info .phase_invoices em {
  background-position: 0 -369px;
}

#stats_info .phase_targets {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 15px;
  width: 100%;
}

#stats_info .phase_targets em {
  margin: 0 20px 0 0;
  float: left;
  background-position: 0 -449px;
  background-image: url('#{$cdn-url}/images/stats/bollen.png?v=2');
  width: 75px;
  height: 75px;
  display: block;
}

#stats_info .phase_prediction {
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: 15px;
  text-transform: uppercase;
  width: 100%;
}

#stats_info .phase_prediction em {
  margin: 0 20px 0 0;
  float: left;
  background-position: 0 -526px;
  background-image: url('#{$cdn-url}/images/stats/bollen.png?v=2');
  width: 75px;
  height: 75px;
  display: block;
}

#stats_info .left {
  float: left;
  font-size: 12px;
  display: block;
  color: #333;
}

#stats_info .right {
  float: right;
  display: block;
}

#stats_info .right .right {
  float: right;
  font-size: 12px;
  width: auto;
  display: block;
}

#stats_info .statphase.phase_targets .bollen {
  max-width: 900px;
}

#stats_info .slide i {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: $color-white;
  font-style: normal;
  top: -15px;
  background: $ahoy-color-aqua-dark;
  border-radius: 30px;
  border: 5px solid $color-white;
  font-size: 13px;
  box-shadow: 0 0 5px #d1d1d1;
}

#stats_info .phase_prediction b {
  margin-left: 20px;
}

#stats_info .phase_prediction div {
  float: left;
  width: 250px;
}
