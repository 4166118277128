#general_contextmenu,
#contextmenu,
#contextmenu2,
#contextmenu3,
#contextmenu4 {
  background-color: $color-white;
  border: 1px solid $color-grey-5;
  padding: 0px;
  width: 188px;
}

#general_contextmenu {
  position: absolute;

  a {
    clear: both;
    display: block;
    font-size: 12px;
    float: left;
    line-height: 21px;
    padding-left: 8px;
    width: 180px;

    &:first-letter {
      text-transform: capitalize;
    }

    &:hover {
      background-color: $color-grey-4;
      color: #333;
      margin: 0;
      text-decoration: none;
    }
  }
}

#contextmenu,
#contextmenu2,
#contextmenu3,
#contextmenu4 {
  backface-visibility: hidden;
  position: fixed;

  a {
    clear: both;
    display: block;
    float: left;
    line-height: 21px;
    padding-left: 8px;
    width: 180px;

    &:hover {
      background-color: $color-grey-4;
      color: #333;
      margin: 0;
      text-decoration: none;
    }
  }
}
