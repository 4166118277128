// form builder
.rgb_form_fg {
  float: left;
  width: 865px;

  div.div {
    clear: both;
    float: left;
    margin: 0 0 7px 0;
  }

  input {
    &.cmsfile {
      margin: 0 0 22px 0;
      width: 260px;
    }

    &.text {
      border: 1px solid #aaa;
      color: #333;
      float: left;
      font-size: 11px;
      height: 20px;
      line-height: 20px;
      padding: 0 0 0 2px;
      width: 274px;
    }
  }

  label {
    clear: both;
    cursor: pointer;
    float: left;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    position: relative;
    width: 600px;
  }

  textarea {
    border: 1px solid #aaa;
    color: #333;
    float: left;
    font-size: 11px;
    height: 80px;
    line-height: 20px;
    padding: 0 0 0 2px;
    resize: none;
    width: 274px;
  }

  select.select {
    font-size: 11px;
    float: left;
    clear: both;
    color: #333;
    border: 1px solid #aaa;
    height: 20px;
    line-height: 20px;
    padding: 0 0 0 2px;
    width: 278px;
  }

  .fck {
    clear: both;
    float: left;
    position: relative;
    width: 600px;
  }
}

.form_left {
  float: left;
  width: 600px;
}

.form_right {
  border-left: 1px solid #ccc;
  float: right;
  min-height: 200px;
  padding: 0 0 0 10px;
  width: 250px;

  label {
    width: auto;
  }
}

input {
  &.baduser {
    background-image: url("#{$cdn-url}/img/no_activity.gif?v=2");
    background-position: center right;
    background-repeat: no-repeat;
  }

  &.inuse {
    background-image: url("#{$cdn-url}/img/inuse.gif?v=2");
    background-position: center right;
    background-repeat: no-repeat;
  }
}

#sales_dateform {
  label {
    font-weight: 700;
  }

  .sales_date {
    float: left;
    margin: 0 0 15px 0;
    width: 200px;
  }
}
