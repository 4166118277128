.side-drawer-button {
  background: $ahoy-color-teal-darkest;
  color: $color-white;
  cursor: pointer;
  font-size: 16px;
  line-height: 12px;
  position: fixed;
  z-index: 3;
  padding: 9px 18px 10px 18px;
  border-radius: 5px 5px 0 0;
  right: 0;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  bottom: 170px;

  i {
    font-size: 14px;
    margin-right: 3px;
  }

  .demo-account & {
    bottom: 200px;
  }
}
