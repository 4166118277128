.warning {
  background-color: #efefef;
  border: 1px solid #ccc;
  clear: both;
  color: $color-error;
  float: left;
  font-size: 13px;
  font-weight: 700;
  margin: 0 0 10px 0;
  padding: 10px;
}

.progresswarning {
  background-image: url("#{$cdn-url}/img/warning.png?v=2");
  background-repeat: no-repeat;
  clear: both;
  float: left;
  line-height: 32px;
  min-height: 32px;
  padding: 0 0 0 40px;
}

.goodwarning {
  background-image: url("#{$cdn-url}/img/accept.png?v=2");
  background-repeat: no-repeat;
  clear: both;
  float: left;
  line-height: 32px;
  min-height: 32px;
  padding: 0 0 0 40px;
}

.attention {
  color: $color-error !important;
  font-weight: 700;

  .upgrade & {
    color: $color-primary-1;
  }
}

.attention_red {
  color: #ff1111;
  font-weight: 400;
}

.nothingfoundmessage {
  float: left;
  margin: 0 0 0 20px;
}

.messages-collapse.hide_ticket_messages {
  display: none;
}

#pricey_message {
  background-color: $color-error;
  border-bottom: 1px solid $color-grey-4;
  color: $color-white;
  display: none;
  font-size: 11px;
  text-align: center;
  padding: 6px 0 6px 0;
  width: 100%;
}
