#unified_telecom_calls {
  a.callbackbutton,
  a.hangupbutton {
    background-color: $color-error;
    border-radius: 2px;
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    left: 17px;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 0 10px 0 10px;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
  }

  a.callbackbutton {
    bottom: 10px;
  }

  a.hangupbutton {
    bottom: 40px;
  }

  a.minus {
    background-image: url("#{$cdn-url}/img/close.png?v=2");
    cursor: pointer;
    height: 26px;
    position: absolute;
    top: -13px;
    right: -13px;
    width: 26px;
  }

  h2.top {
    background-image: url("#{$cdn-url}/img/phonecall.png");
    background-repeat: no-repeat;
    color: #3c3c3c;
    font-size: 15px;
    line-height: 23px;
    margin-top: 0;
    padding-bottom: 12px;
    padding-left: 32px;
  }

  h2.top_bad {
    background-image: url(#{$cdn-url}/img/phonecall_bad.png);
  }

  input.top {
    background-repeat: no-repeat;
    border: 0;
    border-bottom: 1px solid $color-grey-3;
    color: #3c3c3c;
    font-size: 15px;
    line-height: 23px;
    margin-top: 0;
    margin-bottom: 12px;
    padding-left: 12px;
    width: 237px;

    &:hover {
      border-bottom: 1px solid #a6a6a6;
    }
  }

  textarea {
    background-color: #ebebeb;
    border: 0;
    font-size: 16px;
    line-height: 21px;
    min-height: 234px;
    padding: 6px 6px 6px 6px;
    resize: none;
    width: 238px;
  }

  #UT_content {
    float: left;
    padding-bottom: 0;
    padding-top: 0;
  }

  .callee_nr {
    background-color: #efefef;
    border: 1px solid $color-grey-4;
    border-radius: 2px;
    color: #444;
    font-size: 13px;
    font-weight: 700;
    line-height: 28px;
    margin: 8px 0 16px;
    text-align: center;
  }

  .optional_sales {
    label {
      float: left;
      font-size: 12px;
      margin: 10px 0 2px;
    }

    select {
      border: 1px solid #c3c3c3;
      border-radius: 3px;
      height: 24px;
      margin-left: 0;
      width: 232px;
    }
  }
}

#suggestions_ut_corc_id {
  a {
    display: block;
    font-size: 12px;
    line-height: 20px;
    margin-left: 32px;

    &:last-child {
      margin-bottom: 12px;
    }
  }
}

#UT_incoming_call_entry a {
  color: $color-error;
  font-weight: 700;
}

#ut_calling_button {
  background-image: url(#{$cdn-url}/img/ut_status.png);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  height: 25px;
  margin: 3px 0 0;
  width: 25px;
}
