#buttons {
  display: flex;

  a {
    border-right: 1px solid $ahoy-color-neutral;
    box-sizing: border-box;
    clear: none;
    color: $ahoy-color-aqua-dark;
    cursor: pointer;
    flex: 1;
    float: left;
    font-weight: 700;
    line-height: 43px;
    text-align: center;
    width: 25%;

    &:last-child {
      border-right: 0;
    }

    &:hover {
      text-decoration: none;
      background-color: #f7f7f7;
    }

    &.disabled {
      color: #ccc;

      &:hover {
        background-color: $color-white;
        cursor: default;
      }
    }
  }
}

.plusbutton:not(#projectpickerdiv_plusbutton) {
  background-color: #008ae5;
  background-image: url("#{$cdn-url}/img/plus_button.png?v=2");
  background-repeat: no-repeat;
  color: $color-white !important;
  float: right;
  height: 21px;
  line-height: 21px;
  padding: 0 10px 0 22px;
}

a.plusbutton:not(#projectpickerdiv_plusbutton):hover {
  color: $color-white;
  text-decoration: none;
}

a.delete {
  background-image: url("#{$cdn-url}/img/trash.png?v=3");
  background-repeat: no-repeat;
}
a.edit {
  background-image: url("#{$cdn-url}/img/pencil.png?v=2");
  background-repeat: no-repeat;
}
a.edit.inlinebtn {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 3px;
}
a.arrow_down {
  background-image: url("#{$cdn-url}/img/arrow_down.png?v=2");
}
a.arrow_up {
  background-image: url("#{$cdn-url}/img/arrow_up.png?v=2");
}
a.view {
  background-image: url("#{$cdn-url}/img/view.png?v=2");
}

a.icon-checkmark {
  &:before {
    color: $color-primary-2;
    font-size: 13px;
  }

  &:hover {
    opacity: 0.8;
  }
}

a.clicktodial {
  cursor: pointer;
}

a.invoicenowbutton {
  float: right;
}

.content a.button {
  background-color: $color-primary-1;
  border-radius: 0.2em;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  line-height: 24px;
  opacity: 1;
  padding: 0 10px 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: top;
}

#template_items a.button {
  margin-top: 0;
}

.content a.redbutton {
  background-color: $color-error;
}

/* Button variations */
.btn-primary {
  &:hover {
    background: #0aa;
    background-color: #009d99;
  }
}

.btn-undo {
  margin-left: 10px;
}

/* Button variations > Send button in ticket reply */

.btn-tooltip {
  &:after {
    background: $color-primary-2;
    box-shadow: 0px 1px 2px #999;
    color: $color-white;
    content: attr(tooltip);
    left: 0;
    margin-top: 3px;
    line-height: 1;
    opacity: 0;
    padding: 5px 8px;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transition: opacity 0.3s;
    white-space: nowrap;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.btn-internal {
  background-color: $color-primary-2 !important;
}

.btn-reply-type {
  background-color: $color-white;
  border-radius: 14px;
  border: 1px solid #dadada;
  color: #999;
  float: right;
  font-weight: 700;
  margin-left: 10px;
  padding: 3px 15px;
  position: relative;
  text-transform: uppercase;

  &::after {
    bottom: 0;
    border: 10px solid transparent;
    border-bottom: 0;
    content: "";
    height: 0;
    left: 50%;
    margin-left: -8px;
    position: absolute;
    width: 0;
    z-index: 0;
  }

  &:hover {
    background-color: $color-grey-4;
    border-top-color: $color-grey-4;
    color: $color-primary-2;
    text-decoration: none;
  }
}

.third_party_ticket_selected {
  .btn-arrow-down,
  .btn-arrow-down:hover {
    background-color: $ahoy-color-aqua-dark;
  }
}

.btn-reply-public.btn-arrow-down,
.btn-reply-public.btn-arrow-down:hover {
  background-color: $color-primary-1;
}

.btn-reply-public.btn-arrow-down::after {
  border-top-color: $color-primary-1;
}

.btn-arrow-down,
.btn-arrow-down:hover {
  background-color: $color-primary-2;
  border-color: transparent;
  color: $color-white;
}

.btn-arrow-down::after {
  border-top: 10px solid $color-primary-2;
  transform: translateY(10px);
  transition: transform 0.3s;
}

/* Button variations > Important button with icon (use btn-brand if you don't want an icon) */
.btn-link {
  font-size: 13px;
  font-weight: 700;
  color: #959da5;
  line-height: 30px;

  &:focus-visible,
  &:hover {
    text-decoration: none;
    color: $color-primary-2;
  }

  &.active {
    color: $color-primary-2;
    pointer-events: none;
  }
}

.btn-external-link {
  display: flex;
  align-items: center;
  width: fit-content;

  .icon-14x14-external-link-outline-aqua {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 6px;
  }
}

.statsbtn {
  width: 25px;
  height: 25px;
  background-image: url("#{$cdn-url}/img/stats.png?v=3");
}
.firstdatebtn {
  width: 25px;
  height: 25px;
  font-size: 17px;
  line-height: 25px;
  vertical-align: middle;
}

a.backbutton_active:before {
  content: "\f054";
  position: relative;
  left: -2px;
}

.content a.button {
  background-color: $color-primary-1;

  &:hover {
    opacity: 0.8;
  }
}

.content a.redbutton {
  background-color: $color-warning;
}

.content {
  .entry {
    a.button {
      font-size: 11px;
      left: 758px;
      line-height: 18px;
      position: absolute;
      top: 1px;

      &.float {
        left: auto;
        margin: 6px 0 0 9px;
        position: relative;
        top: auto;
      }
    }
  }
}

#user_selection_active {
  .button {
    background-color: $ahoy-color-aqua-dark;
    border-radius: 3px;
    color: $color-white;
    cursor: pointer;
    float: left;
    font-size: 11px;
    line-height: 20px;
    min-width: 60px;
    padding: 0 16px 0 12px;
    text-align: left;
    text-decoration: none;
    width: auto !important;
  }
}

#pro_rel_project_thirdparties .entry a.button {
  left: 755px;
  top: 5px;
}

a.goback {
  background-image: url("#{$cdn-url}/img/goback.png?v=3");
  height: 14px;
  margin: 3px 0px 0px 4px;
  padding: 0;
  position: absolute;
  width: 14px;
}

a.backbutton_active {
  background-color: $color-primary-1;
  background-position: top right;
  border-radius: 6px;
  box-sizing: border-box;
  clear: none;
  color: $color-white;
  display: block;
  float: left;
  font-size: 13px;
  height: 25px;
  line-height: 27px;
  margin-right: 15px;
  padding-right: 7px;
  text-align: right;
  text-decoration: none;
  transform: scale(-1, 1);
  vertical-align: middle;
  width: 28px;
}

a.backbutton {
  background-position: top left;
  background-image: url("#{$cdn-url}/img/backbtn.jpg");
  clear: none;
  cursor: default;
  display: block;
  float: left;
  height: 25px;
  margin-right: 15px;
  width: 28px;
}

// Loading indicator after a button was clicked
button.buttonactivity,
a.buttonactivity {
  color: transparent !important;
  opacity: 0.5 !important;
  pointer-events: none;
}

button.buttonactivity:after,
a.buttonactivity:after,
a.delete_button.activity:after,
#file_widget .dropbox:not(.synced) .external_file_save:after {
  content: " ";
  animation: spin 0.8s infinite linear;
  background-image: url("#{$cdn-url}/images/activity_indicator.svg?v=2");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -7px;
  margin-left: -7px;
  width: 14px;
  height: 14px;
  display: block;
  pointer-events: none;
}

.deelnemerspasbutton {
  margin-left: 19px;
}
