.alldayevents {
  .all_dayentry {
    background-image: url("#{$cdn-url}/img/event_fullday.png");
    background-repeat: no-repeat;
    display: block;
    margin: 0 481px 4px 15px;
    min-height: 18px;
    overflow: hidden;
    position: relative;

    &.bday {
      background-image: url("#{$cdn-url}/img/event_bday.png");
    }

    &.milestone {
      background-image: url("#{$cdn-url}/img/event_milestone.png");

      span {
        cursor: pointer;
        padding-top: 1px;
      }

      &.done {
        span {
          text-decoration: line-through;
        }
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.content {
  .alldayevents {
    .all_dayentry {
      em {
        position: relative;
      }

      .inner {
        padding-left: 22px;
      }
    }
  }
}

.dagplanning {
  height: 570px;
  left: 0px;
  margin: 0 0 0 0;
  position: absolute;
  top: 0;
  width: 100%;

  b {
    border-bottom: 1px dotted $color-grey-4;
    display: block;
    left: 15px;
    position: absolute;
    top: 0px;
    width: 385px;
  }

  i {
    position: absolute;
    border-bottom: 1px solid #ccc;
    width: 385px;
    display: block;
    left: 15px;
    top: 0px;
  }

  span {
    display: block;
    font-size: 10px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 400px;
  }

  .entry {
    color: $color-black;
    font-size: 11px;
    height: 30px;
    left: 15px;
    line-height: 14px;
    opacity: 1;
    position: absolute;

    &.dark {
      color: $color-white;
    }
  }
  .caldav,
  .ews,
  .google {
    cursor: default;
  }

  .entry2 {
    width: 129px;
  }
}

.planningtodos {
  height: 582px;
  margin: 0;
  border-left: 1px solid $color-grey-3;
  position: relative;

  > div {
    height: 100%;
  }

  .empty-state {
    text-align: center;

    &-title {
      color: $color-primary-2;
      font-size: $font-size-med-3;
    }

    &-description {
      color: $color-primary-2;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header {
    background-color: $color-white;
    border-bottom: 1px solid $color-grey-3;
    padding: $pad;

    &-subtitle {
      margin: 0;
    }

    &-title {
      font-size: $font-size-large-2;
      line-height: 1.2;
      margin: 0;
    }
  }

  &-empty {
    background-color: $color-grey-2;
    border-bottom: 1px solid $color-grey-3;
    padding: $pad;
  }

  &-body {
    background-color: $color-grey-2;
    flex: 1;
    padding: $pad;
  }

  .dragdroptodo {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: $shadow-drag-boxes;
    cursor: pointer;
    cursor: -webkit-grab !important;
    cursor: -moz-grab !important;
    margin-bottom: $space;
    padding: $pad $pad $pad $pad * 3.375;
    position: relative;
    transition: all 100ms linear;

    &-due-date {
      color: $color-warning;
      font-size: $font-size-small-2;
      margin-left: $space;
    }

    &-mark-as-done {
      @include size(14px);
      align-content: center;
      border: 2px solid $color-primary-1;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      left: $pad;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        background: $color-grey-3;
        opacity: 1;
      }
    }

    &-time-planned {
      background: $color-primary-1;
      border-radius: 3px;
      color: $color-white;
      display: inline-block;
      font-size: $font-size-small-2;
      line-height: 1.375;
      margin-right: $space-half;
      padding: 0 4px;
      width: auto;
    }

    &-time-status {
      color: $color-grey-5;
      font-size: $font-size-small-2;
      position: absolute;
      right: $pad;
      top: $pad;
    }

    &-deadline-today {
      font-size: $font-size-small-2;
      margin-right: $space-half;
    }

    &-subtitle {
      margin: 0;
    }

    &-title {
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    &-done-icon {
      color: $color-white;
      font-size: 11px;
      opacity: 0;
    }

    &:active {
      cursor: -webkit-grabbing !important;
      cursor: -moz-grabbing !important;
    }

    &:not(.done):not(.done-planned):hover {
      box-shadow: $shadow-drag-boxes-hover;
      cursor: pointer !important;
      cursor: pointer !important;
    }

    &.done,
    &.done-planned {
      opacity: 0.5;

      .dragdroptodo-mark-as-done {
        background: $color-body-text;
        border-color: $color-body-text;
        opacity: 1;
      }

      .dragdroptodo-done-icon {
        opacity: 1;
      }

      .dragdroptodo-time-planned {
        background: $color-grey-6;
      }
    }

    &.done {
      .dragdroptodo-title {
        text-decoration: line-through;
      }
    }

    &.done-planned {
      .dragdroptodo-time-planned {
        text-decoration: line-through;
      }
    }
  }
}

//TODO: remove this css after PP goes live
.old-planning-todos {
  .todos {
    span,
    i,
    b {
      line-height: 23px;
    }

    div {
      i {
        margin-right: 3px;
      }

      .unplanned {
        margin-top: 6px;
      }
    }
  }
}

.schedulable-work-list {
  &-overdue {
    .list-title-label {
      color: $color-warning !important;
    }
  }
}

.collapsible {
  .list-title {
    color: $color-primary-2;
    cursor: pointer;
    opacity: 0.8;
    position: relative;

    &-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      opacity: 1;
    }
  }

  .list-show-all {
    margin-bottom: $space;
    text-align: center;

    .list-show-all-link {
      padding: $pad-half $pad;
    }
  }

  &.collapsed {
    .list-content,
    .list-show-all {
      display: none;
    }
  }
}
