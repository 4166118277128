.dp_container,
.tp_wrapper {
  z-index: 1;
}

#popup_move_surface {
  z-index: 2;
}

.fixed-right,
.fixed-right-backdrop {
  z-index: 18;
}

#TL_extra_overlays {
  z-index: 19;
}

#general_contextmenu,
#contextmenu,
#contextmenu2,
#contextmenu3,
#contextmenu4,
.widgettitle .dropdownmenu {
  z-index: 21;
}

.drag-input {
  z-index: 22;
}

#mouseinfo {
  z-index: 23;
}

#popupbg {
  z-index: 401;
}

#popup-container,
.popupform .tooltip:hover,
#teamviewer_support_popup_bg {
  z-index: 401;
}

/** Override for the CKEditor z-index, also @see static/js/ckeditor_4.16.1/config.js */
.cke_dialog_container {
  z-index: 403 !important;
}

@media screen and (min-width: 1580px) {
  #TL_extra_overlays.visible_during_popups {
    z-index: 401;
  }
}

#dd,
#bigclicker,
.ajax_cc_suggestions,
#teamviewer_support_popup {
  z-index: 402;
}

#dd_loading {
  z-index: 403;
}

#connectivity_info {
  z-index: 404;
}

#errorinfo {
  z-index: 405;
}

#inline_loginwindow {
  z-index: 406;
}
