.content {
  #document {
    a.download,
    .upload {
      background-color: $color-primary-1;
      background-image: url("#{$cdn-url}/images/download.png");
      background-position: 7px 5px;
      background-repeat: no-repeat;
      line-height: 29px;
      border-radius: 0.2em;
      color: $color-white;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      opacity: 1;
      padding: 0 13px 0 37px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: top;
    }

    .upload {
      background-image: url("#{$cdn-url}/images/upload.png?v=2");
      background-position: center right;
      float: left;
      margin-right: 10px;
      padding: 0 42px 0 13px;
    }
  }
}
