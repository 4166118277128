.hr-users-list {
  .navigation {
    float: right;
    margin-top: -1px;
    margin-right: 3px;
  }

  .select-wrapper {
    height: 28px !important;
    font-weight: normal;
  }

  .invited {
    height: 49px;

    div {
      height: 40px;
      line-height: 1.3em;
      padding: 8px 0;
    }

    .expires-in {
      color: $ahoy-color-neutral-darkest;
      font-size: 12px;
    }

    .expired-at {
      color: $ahoy-color-ruby-darkest;
      font-size: 12px;
    }
  }

  .icon.resend {
    color: #333333;
    float: left;
    margin: 11px 8px 0 4px;
  }
}

.hr-users-list {
  .content,
  .entry div,
  span {
    cursor: default;
  }
}
