.funnel {
  margin-bottom: -16px;
}

.funnelrow {
  border-top: 1px solid #e6e6e6;
  height: 42px;
  padding: 30px 100px 15px 10px;

  &:first-of-type {
    border-top: none;
    padding-top: 25px;
  }
}

.funnellabel {
  color: #848484;
  display: inline;
  font-size: 20px;
  overflow: hidden;
  position: absolute;
  text-indent: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

.funnelabsolute {
  color: $color-primary-1;
  font-size: 24px;
  left: 20%;
  margin-top: -2px;
  position: absolute;
}

.funnel_relative {
  color: $color-white;
  margin-left: calc(50% - 7px);
  margin-top: 2px;
}

.funnelline {
  background-color: $color-primary-1;
  border: 1px solid $color-white;
  border-radius: 8px;
  height: 17px;
  left: 27%;
  margin-top: 6px;
  position: absolute;
}

.funnellost {
  left: 45px;
  position: absolute;
  top: 0px;
  white-space: nowrap;
}

.funnellost_abs {
  background-color: $color-error;
  border-right: 5px solid $color-white;
  color: $color-white;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 20px 3px 20px;
}

.funnellost_rel {
  color: #ff7070;
}

.funnel_left_relative {
  color: #838383;
  left: 11%;
  margin-top: -5px;
  padding: 10px;
  position: absolute;

  .left_relative_label {
    background-color: $color-white;
    padding: 0 5px 0 6px;
    position: absolute;
    top: 0%;
  }

  .arrow-down {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #cdcdcd;
    height: 0;
    width: 0;
  }
}

.funnel_money {
  color: #028ae2;
  float: right;
  left: 100%;
  line-height: 18px;
  margin-left: 12px;
  margin-top: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.sales_won .funnellabel,
.sales_won .funnelabsolute,
.sales_won .funnel_money {
  color: $color-valid;
}

.sales_won .funnelline {
  background-color: #a7d35a;
}
