#timesheets .snake span.interactive {
  background-color: #0089e4;
  cursor: pointer;
  transition: all 150ms linear;
  width: 100%;
  z-index: 2;
}
#timesheets .snake span.break {
  background: $color-secondary-3;
  width: 100%;
  z-index: 1;
}
#timesheets .snake span.interactive:hover {
  background: rgba(0, 137, 228, 0.65);
}
#timesheets .snake p {
  float: left;
  width: 25px;
  color: #333;
}
#timesheets .snake b {
  width: 232px;
  float: left;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  height: 17px;
  position: relative;
}
#timesheets .snake i {
  float: left;
  width: 34px;
  font-style: normal;
  font-size: 11px;
  height: 17px;
  line-height: 20px;
  padding: 0 8px 0 8px;
  margin: 0 0 0 4px;
  text-align: center;
}
#timesheets .snake span {
  position: absolute;
  top: 0px;
  bottom: 0px;
}
#timesheets .snake span.interactive:hover .tooltip2 {
  opacity: 1;
}
#timesheets .snake .timetrackingtooltip .timetrackingtooltip-content {
  z-index: 3;
  visibility: hidden;
  position: relative;
  width: 100px;
  padding: 5px;
  color: $color-white;
  background: rgba(89, 89, 89, 0.8);
  height: auto;
  line-height: 20px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  display: inline-block;
  margin: 0;
  left: 50%;
  right: 50%;
  top: 0;
  margin-left: -55px;
  margin-top: 28px;
  z-index: 99;
}
#timesheets
  .snake
  .timetrackingtooltip
  .tooltiphover:hover
  + .timetrackingtooltip-content {
  visibility: visible;
}
#timesheets .timetrackingtooltip .timetrackingtooltip-content:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-bottom: 8px solid rgba(89, 89, 89, 0.8);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
#starttime {
  float: left;
  color: #999;
  float: left;
  line-height: 25px;
  clear: none;
  width: 60px;
  text-align: center;
}
#endtime {
  float: right;
  color: #999;
  float: right;
  line-height: 25px;
  clear: none;
  width: 110px;
  text-align: center;
}

#timesheets .noticep {
  float: left;
  margin: 20px 0 20px 0;
  font-style: italic;
}
#timesheets .spacer {
  float: left;
  width: 100%;
  clear: both;
  height: 12px;
}

#timesheets .spacer.big {
  height: 45px;
}
#timesheets .timesheet_day h2 {
  float: left;
  min-width: 229px;
  line-height: 21px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  color: #333;
}
#timesheets .timesheet_day h2 i {
  font-style: normal;
}
#rightcontent #timesheets .timesheet_day.today h2 i {
  background-color: $color-primary-1;
  color: $color-white;
  display: inline-block;
  padding: 0 4px 0 4px;
  margin-left: -4px;
}
#timesheets .timesheet_day.hidden .listview,
#timesheets .timesheet_day.hidden br.clear {
  display: none;
}
#timesheets .timesheet_day.hidden .spacer {
  height: 12px;
}
#timesheets .timesheet_day .snake {
  float: right;
}
#timesheets .timesheet_day a.btn {
  position: relative;
  margin-left: 10px;
  padding: 0;
  float: left;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: block;
  line-height: 25px;
  text-align: center;
  background-color: $color-primary-1;
}
#timesheets .timesheet_day h2.day_off i {
  display: inline-block;
  padding: 0 4px 0 4px;
  margin-left: -4px;
  background-color: #d1d1d1;
}
#timesheets .timesheet_day .listview {
  margin-top: 12px;
  min-height: 0;
}
#timesheets .timesheet_day a.btn:hover {
  background: #0aa;
  background-color: #009d99;
}
#timesheets .time_snake div {
  float: left;
  border: 0;
  height: 20px;
  margin: 4px 0 0 0;
}
#timesheets #timesheets_form .big {
  width: auto;
  float: right;
}
#timesheets #timesheets_form {
  float: right;
}

#timesheet_stats {
  background-color: $ahoy-color-neutral-light;
  margin-bottom: 24px;
  margin-top: 24px;
}

#timesheet_stats .slab {
  float: left;
  width: 42%;
  margin: 20px 0 15px 17px;
}
#timesheet_stats .slab.small {
  float: right;
  width: 22%;
  margin: 30px 27px 0 0;
}
#timesheet_stats .slab h3 {
  color: #4e4e4e;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 3px;
}
#timesheet_stats .slab h3 {
  margin-top: 15px;
}
#timesheet_stats .slab h3 i {
  font-size: 12px;
  color: #999;
  font-weight: normal;
  cursor: help;
}
#timesheet_stats .slab h3:first-child {
  margin-top: 0;
}
#timesheet_stats .slab p,
#timesheet_stats .slab a {
  vertical-align: top;
  margin-bottom: 5px;
  margin-top: 0;
  color: #4e4e4e;
  font-size: 12px;
}
#timesheet_stats .slab a {
  color: #0089e4;
}
#timesheet_stats .slab p img {
  margin-right: 6px;
  box-sizing: border-box;
  margin-top: -4px;
  vertical-align: middle;
}
#timesheet_stats .slab i {
  font-style: normal;
  color: #333;
  font-weight: bold;
  font-size: 20px;
}
#timesheet_stats .slab .legend {
  float: left;
  margin-top: 10px;
  margin-bottom: 6px;
}
#timesheet_stats .slab .legend i {
  margin-top: 3px;
  margin-right: 7px;
  display: block;
  float: left;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  background-color: $color-primary-1;
}
#timesheet_stats .slab .legend i.grey {
  background-color: $color-grey-4;
}
#timesheet_stats .slab img.target {
  margin: -26px 0 0 14px;
}
#timesheet_stats .slab img.big_target {
  margin: -18px 0 0 14px;
}

#timesheets #timesheet_day_week,
#timetrackinglist_header span {
  float: left;
  display: block;
  line-height: 24px;
  width: 36%;
  text-align: center;
  padding: 0 0 0 0;
  color: $color-primary-2;
}

#rightcontent .timesheet_center {
  height: 20px;
  margin: 0 auto;
  width: 210px;
}
