@import '../utils/variables';

/*------------------------------------*\
    $GLOBAL CLASSES
\*------------------------------------*/

/* Clearfix */
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

/* Completely remove from the flow and screen readers. */
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

/* Completely remove from the flow but leave available to screen readers. */
.is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Floats */
.right {
  float: right;
}

.left {
  float: left;
}

/* Text-Align */
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

/* Text-break */
.break-word {
  word-break: break-word;
}

//States
.valid {
  color: $color-valid;
}

.error {
  color: $color-error;
}

.warning {
  color: $color-warning;
}

.information {
  color: $ahoy-color-aqua-dark;
}

.font-secondary {
  font-family: $font-secondary;
}

a.bold {
  font-weight: 700;
}

.centerthis {
  .button {
    margin: 15px auto;
  }

  &.content {
    clear: both;
    float: left;
    margin-left: 30px;
    position: relative;
    text-align: center;
    width: 930px;
  }
}

.clearleft {
  clear: left;
}

.cursor {
  cursor: pointer;
}

.currentlybeingdragged {
  opacity: 0.35;
}

.disappear {
  transition: all 0.25s ease-out;
  transition-property: opacity, border-width, height, min-height, padding-top, padding-bottom, margin-top, margin-bottom;
}

.disappeared {
  opacity: 0 !important;
  border-width: 0px !important;
  height: 0px !important;
  min-height: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  pointer-events: none;
}

.hiddenbydefault {
  display: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.forcedragcursor {
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
  }
}

.margintop {
  margin-top: 20px;
}

.smallmargin {
  clear: none;
  float: left;
  margin: 15px 20px 0 0;
}

.trans,
.content a.trans {
  opacity: 0.5;
}

.nocursor {
  cursor: move !important;
}
.nocursor * {
  cursor: move !important;
}
.notallowedcursor {
  cursor: not-allowed !important;
}
.notallowedcursor * {
  cursor: not-allowed !important;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.posrel {
  position: relative;
  float: left;
  clear: both;
}

.text-center {
  text-align: center;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.veryhidden {
  display: none;
}

.has-cursor-help {
  cursor: help;
}

/***************** LAYOUT UTILITIES *****************/
/* container: horizontal alignment for widgets */
.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 87%;
  min-width: 990px;
  padding: 0 30px;
}

.container-centering {
  margin: 0 auto;
}

.container-max990 {
  max-width: 990px;
}

.container-min990 {
  min-width: 990px;
}

.nicemargins {
  margin: 20px 0 10px 0;
}

/* Utility background colors */
.bg-green {
  background: $color-valid;
}
.bg-red {
  background: $color-error;
}
.bg-blue {
  background: $ahoy-color-aqua-dark;
}
.bg-grey2 {
  background: $color-grey-2;
}

.img-middle {
  vertical-align: middle;
}
