.project-detail-header {
  z-index: 2;
  margin-top: -42px;
}

.project-detail-container {
  max-width: 972px;
  margin: 0 auto;
}

.project-detail-header + .project-detail-container {
  position: relative;
  margin-top: 42px;
  z-index: 1;
}

