html {
  height: 100%;
}

body {
  font: 100%/1.5 $font;
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: $ahoy-color-teal-darkest;
  transition: background-color 0.25s ease-out;
  height: 100%;

  .app-container {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto auto auto minmax(0, 1fr) auto;
    grid-template-areas:
      "service-message service-message"
      "notice-banners notice-banners"
      "primary-navigation header"
      "primary-navigation content"
      "primary-navigation demo-banner";
    height: 100%;
  }

  .notice-banners {
    grid-area: notice-banners;
  }

  .main-content {
    grid-area: content;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    overflow: auto;
  }

  &:not(.has-tertiary-navigation-opened) .main-content {
    grid-template-rows: 100%;
  }

  .demo-banner {
    grid-area: demo-banner;
  }

  .ck-editor__editable {
    min-height: 200px;

    h1, h2, h3, h4, p {
      background: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      clear: none;
      color: $color-primary-2 !important;
      float: none; 
      font-family: $ahoy-font-family-inter !important;
      height: auto;
      line-height: 1.5;
      margin: 0;
      padding: 8px 0px;
      width: auto;
    }

    h1 {
      font-size: 20px !important;
      font-weight: 700 !important;
    }

    h1::before {
      content: none;
    }

    h1::after {
      content: none;
    }

    h2{
      font-size: 17px !important;
      font-weight: 700 !important;
    }

    h3 {
      font-size: 14px !important;
      font-weight: 700 !important;
    }

    h4 {
      font-size: 13px !important;
      font-weight: 700 !important;
    }

    p {
      font-size: 12px !important;
    }

    ol, ul {
      font-family: $ahoy-font-family-inter !important;
      font-size: 12px !important;
      color: $color-primary-2 !important;
      padding-inline-start: revert;
      padding-left: revert;
      margin-block-start: 12px;
      margin-block-end: 12px;
    }

    figure {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .ck-source-editing-area {
    height: 202px;

    textarea {
      width: 100%;
      height: 100%;
      overflow: visible;
    }
  }
}
