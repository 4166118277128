#bookkeeping_service_options {
  .action_needed {
    display: block;
    background-image: url("#{$cdn-url}/images/alert_blue.png?v=4");
    background-repeat: no-repeat;
    height: 16px;
    line-height: 16px;
    margin: 5px 0 10px 0;
    padding-left: 25px;
  }

  .backbtn {
    clear: both;
    float: left;
    margin: 10px 0 10px 0;
  }

  .services_square {
    border: #e4e4e4 solid;
    border-radius: 4px;
    border-width: 1px;
    clear: both;
    float: left;
    margin: 10px 0 10px 0;
    padding-bottom: 10px;
    width: 300px;

    p {
      float: right;
    }

    h4 {
      float: left;
      padding-top: 2px;
    }

    div.margindiv {
      display: block;
      margin-top: 15px;
      margin-left: 20px;
      margin-right: 20px;
      height: 20px;
    }

    select {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      width: 260px;
    }

    span {
      display: block;
      float: right;
      width: 17px;

      &.active {
        background-image: url("#{$cdn-url}/images/check_green.png?v=3");
        background-repeat: no-repeat;
        display: block;
        height: 16px;
        line-height: 16px;
        margin: 5px 0 0 0;
        padding-left: 25px;
      }
    }
  }
}

#bookkeeping_edit_holder {
  > .edit.inlinebtn {
    float: right;
    margin-left: 13px;
    margin-top: 3px;
  }
}
