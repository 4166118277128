#gizmo_currently_planning {
  a.milestone {
    background-image: url("#{$cdn-url}/images/icons_todo_15.png?v=8");
    background-position: 0 2px;
    padding-left: 22px;
  }

  a.minus {
    background-image: url("#{$cdn-url}/img/close.png?v=2");
    cursor: pointer;
    height: 26px;
    position: absolute;
    right: -13px;
    top: -13px;
    width: 26px;
  }

  a.project {
    background-image: url("#{$cdn-url}/images/icons_todo_15.png?v=8");
    background-position: 0 -19px;
    cursor: pointer;
    padding-left: 22px;
  }

  a.redbutton {
    background-color: $color-error;
    bottom: 10px;
    left: 10px;
    position: absolute;
    right: auto;
  }

  em {
    float: left;
    font-style: normal;
    font-size: 16px;
  }

  h1 {
    height: 23px;
    margin: 0 0 7px 0;
  }

  a.inline,
  i.inline {
    background-repeat: no-repeat;
    clear: both;
    display: block;
    float: left;
    font-size: 12px;
    font-style: normal;
    line-height: 24px;
  }

  i.good {
    background-image: url("#{$cdn-url}/images/check_green.png");
    background-position: center left;
    background-repeat: no-repeat;
    padding-left: 22px;
  }

  i.planned {
    em {
      background-color: #7ecb49;
      border-radius: 2px;
      display: block;
      float: left;
      height: 10px;
      margin: 6px 10px 0 2px;
      width: 10px;
    }
  }

  i.unplanned {
    em {
      background-color: #7ecb49;
      border-radius: 2px;
      display: block;
      float: left;
      height: 10px;
      margin: 6px 10px 0 2px;
      opacity: 0.32;
      width: 10px;
    }
  }

  p {
    background-color: #f3f4f4;
    border-radius: 7px;
    clear: none;
    color: #333;
    cursor: pointer;
    float: left;
    font-size: 12px;
    height: 78px;
    hyphens: auto;
    display: block;
    overflow: hidden;
    padding: 10px 12px 10px 12px;
    margin: 15px 0 55px 0;
    width: 206px;
    word-break: break-word;
    -webkit-hyphens: auto;
    -ms-word-break: break-all;
    -moz-hyphens: auto;
  }

  .progressbar_wrapper {
    background-color: #f3f4f4;
    border: 1px solid #ccc;
    float: left;
    height: 17px;
    margin: 5px 0 7px 0;
    overflow: hidden;
    position: relative;
    width: 228px;
  }

  .progress_wrapper {
    float: left;
    cursor: help;
    background-color: $color-valid;
    height: 18px;
  }

  .progress_light {
    float: left;
    cursor: help;
    background-color: #c7e398;
    height: 18px;
  }

  .screen {
    min-height: 260px;
    min-width: 230px;
  }

  .tooltip {
    cursor: help;

    &.textbased {
      background: none;
      background-image: none;
      color: #777 !important;
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;

      &:before {
        display: none;
      }
    }
  }
}
