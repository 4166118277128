.settings_backup {
  .current_backups {
    float: left;
    width: calc(100% - 280px);
    padding-left: 20px;
    min-height: 120px;

    .empty_backup_text {
      margin-top: 22px;
      display: inline-block;
      font-size: 14px;
      color: rgba(43, 59, 76, 0.4);
      font-style: italic;
    }

    .backup_empty_text {
      color: rgba(43, 59, 76, 0.4);
      display: block;
      font-size: 14px;
      font-style: italic;
      margin-left: 30px;
      margin-top: 22px;
    }
  }

  .current_backup {
    border: 2px solid $color-grey-4;
    display: inline-block;
    height: 87px;
    line-height: 20px;
    max-height: 100px;
    padding-bottom: 10px;
    padding-top: 20px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 190px;

    .backup_failed {
      display: inline-block;
      position: relative;
      top: 12px;
    }

    .download_backup {
      margin-top: 10px;
    }
  }

  .create_backup_wrapper {
    border: 2px dashed $color-grey-4;
    color: rgba(43, 59, 76, 0.7);
    display: block;
    float: left;
    font-size: 14px;
    min-height: 76px;
    padding: 20px;
    text-align: center;
    width: 160px;

    .backup_failed {
      font-weight: 700;
      color: $color-error;
    }

    .create_backup_btn {
      cursor: pointer;
      display: block;
      padding: 15px 15px 0;
    }

    &.creating_backup {
      background-color: rgba(84, 136, 195, 0.1);
      border-color: rgba(84, 136, 195, 0.2);
      color: $ahoy-color-aqua-dark;
    }
  }
}
