#rightcontent {
  #div_weekplanning {
    .GhostPlanningViewer {
      left: 0;
    }
  }

  .GhostPlanningViewer {
    background-color: #ccc;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    width: auto;
  }
}
