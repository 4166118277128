#sys_files {
  .biggest {
    width: 600px;
  }

  .entry {
    .a {
      &.icon {
        background-repeat: no-repeat;
        background-position: left 8px;
        color: $ahoy-color-aqua-dark;
        line-height: 35px;
        padding-left: 22px;
      }
    }
  }

  .nothingfoundmessage {
    margin-top: 20px;
  }
}
