/* DragDrop > Shows where the dragged files will land */
.drag-input {
  outline: 10px solid $color-primary-1;
  position: relative;
}

.drag-container #popupbg {
  opacity: 0.5 !important;
  visibility: visible !important;
  z-index: 10 !important;
}

#dd {
  backface-visibility: hidden;
  background-color: $color-black;
  height: 100%;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
}

#dd_loading {
  backface-visibility: hidden;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;

  a.button {
    background-color: $color-primary-1;
    border-radius: 0.3em;
    clear: both;
    color: $color-white;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
    margin: 5px 0 0 160px;
    opacity: 0.8;
    padding: 0 10px 0 10px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      opacity: 1;
    }
  }

  a.close {
    background-image: url("#{$cdn-url}/img/close.png?v=2");
    height: 26px;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 26px;
  }

  b {
    background-color: $color-primary-1;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  em {
    left: 0;
    position: absolute;
    top: 40%;
    width: 100%;
  }

  i {
    background-color: #333;
    border: 5px solid $color-white;
    border-radius: 3px;
    display: block;
    height: 18px;
    margin: 15px auto 0 auto;
    position: relative;
    width: 150px;
  }

  select,
  input.text {
    margin-left: 0;
    width: 185px;
  }

  input {
    margin: 5px 0 0 0;
  }

  label {
    display: block;
    float: left;
    font-size: 11px;
    font-style: normal;
    line-height: 22px;
    margin: 0;
    width: 160px;
  }

  p {
    color: $color-white;
    font-size: 12px;
    left: 0;
    line-height: 18px;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
  }

  #folder_selection {
    float: left;
  }

  .a {
    background-color: $color-white;
    border: 4px solid $color-white;
    border-radius: 2px;
    display: block;
    margin: 0 auto 0 auto;
    min-height: 54px;
    padding: 18px;
    position: relative;
    width: 360px;
  }

  .div {
    clear: both;
    float: left;
    margin: 0 0 3px;
  }
}

#rawmouseinfo {
  backface-visibility: hidden;
  position: fixed;
  z-index: 5;

  .week {
    background-image: url("#{$cdn-url}/images/btnWeek.png");
    color: $color-white;
    cursor: pointer;
    cursor: -webkit-grab !important;
    cursor: -moz-grab !important;
    display: block;
    font-size: 12px;
    height: 60px;
    left: 0px;
    padding-top: 23px;
    position: absolute;
    text-align: center;
    top: 0px;
    width: 82px;
  }
}

#mouseinfo {
  backface-visibility: hidden;
  background-color: #98cc40;
  border: 1px solid #98cc40;
  border-radius: 2px;
  color: #333;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  line-height: 30px;
  padding: 0 5px 0 5px;
  position: fixed;
}