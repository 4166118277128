#rightcontent {
  #team_controls {
    width: calc(100% - 85px);

    a.button {
      border-radius: 2px;
      font-size: 26px;
      font-weight: 700;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 139px;
    }

    a.setup_dates {
      background-color: #ececec;
      background-image: url("#{$cdn-url}/img/planning-icon.jpg");
      background-position: 15px 7px;
      background-repeat: no-repeat;
      border: 1px solid #ccc;
      border-radius: 2px;
      color: #333;
      cursor: pointer;
      display: block;
      float: right;
      line-height: 35px;
      padding: 0 20px 0 57px;
    }

    select {
      font-size: 13px;
      height: 24px;
      width: 158px;
    }

    #add_user {
      display: none;
    }
  }
}
