#filter_toolbox {
  clear: both;
  float: left;
  margin: -10px 0 20px 0;
  width: 100%;

  label {
    display: block;
    float: left;
    line-height: 21px;
    min-width: 100px;

    &:first-child {
      margin-left: 0;
      margin-right: 4px;
      width: 25%;
    }
  }

  select.select {
    float: left;
    margin-right: 60px;
    width: 140px;
  }
}
