#time_taken_info {
  .hour {
    font-weight: 700;
  }

  .remaining_time {
    display: inline-block;
    width: 50%;
  }

  .selected_time {
    display: inline-block;
    width: 50%;
  }

  .text {
    color: #545458;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    width: 100%;
  }

  .time {
    display: inline-block;
    font-size: 17px;
    width: 100%;
  }
}
