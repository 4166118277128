#timesheet_stats {
  p.target_done {
    background-image: url("#{$cdn-url}/images/done.png?v=2");
    background-repeat: no-repeat;
    float: left;
    line-height: 22px;
    padding: 0 0 0 28px;
    width: auto;
  }
  p.target_notdone {
    background-image: url("#{$cdn-url}/images/notdone.png?v=2");
    background-repeat: no-repeat;
    float: left;
    line-height: 22px;
    padding: 0 0 0 28px;
    width: auto;
  }
}
