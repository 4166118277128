#toolset {
  border: 0;
  box-sizing: content-box;
  float: right;
  height: 30px;
  line-height: 20px;
  margin-right: 0;
  margin-top: 24px;
  opacity: 1;
  overflow: visible;
  padding: 0 !important;
  width: auto;

  a {
    background-repeat: no-repeat;
    display: block;
    float: right;
    height: 20px;
    line-height: 20px;
    width: 26px;

    &:hover {
      text-decoration: none;
    }
  }

  a.excel {
    background-image: url("#{$cdn-url}/img/excel.jpg");
  }

  a.etiketbutton {
    background-image: url("#{$cdn-url}/img/table-export.png?v=2");
  }

  a.tools:before {
    content: "\e991";
    font-family: "icomoon";
    font-size: 20px;
    color: #999;
  }

  a.multitxt {
    background-image: url("#{$cdn-url}/img/multitxt.gif?v=3");
  }

  a.multipdf {
    background-image: url("#{$cdn-url}/img/multipdf.png?v=2");
  }

  &:hover {
    opacity: 1;
  }
}

#evt_event_participants #toolset {
  display: none;
}

#planning_form a.multipdf {
  background-image: url("#{$cdn-url}/img/multipdf.png?v=2");
  display: block;
  height: 20px;
  margin: 2px 0 0 0;
  width: 20px;
}

a.googlemaps {
  background-repeat: no-repeat;
  background-image: url("#{$cdn-url}/img/googlemaps.gif?v=2");
  display: block;
  height: 19px;
  line-height: 21px;
  margin: 0 0 0 13px;
  padding: 0 0 0 25px;
}
