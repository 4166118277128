.clicktel {
  background-color: $ahoy-color-neutral-lightest;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(42, 59, 77, .24);
  color: #333;
  padding: 0 0 0 0;
  position: absolute;
  z-index: 7000;
  max-height: 220px;
  overflow: auto;
}

.clicktel_line {
  display: block;
  padding: 6px 12px;

  i {
    float: left;
    padding-right: 10px;
    position: relative;
    top: 2px;
  }

  &.click_to_dial_out {
    background-color: $ahoy-color-neutral-lightest;
    color: #333;
    font-size: 14px;
    height: 25px;
    min-width: 140px;
    padding: 0 13px 0px 10px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $ahoy-color-neutral-light;
      color: $ahoy-color-teal-darkest;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &.subtitle {
    background-color: $ahoy-color-neutral-lightest;
    color: $ahoy-color-teal-darkest;
    font-family: $ahoy-font-family-inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: .6px;
    padding-left: 10px;
    text-transform: uppercase;
    user-select: none;
  }
}
