html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
img,
strong,
sub,
sup,
ol,
fieldset,
form,
label,
legend {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
