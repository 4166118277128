.entry {
  font-size: 12px;

  a.arrow_up {
    width: 14px;
    height: 14px;
    float: left;
    margin: 11px 0 0 4px;
  }
  a.arrow_down {
    width: 14px;
    height: 14px;
    float: left;
    margin: 11px 0 0 4px;
  }
  a.edit {
    width: 14px;
    height: 14px;
    float: left;
    margin: 11px 0 0 4px;
  }
  a.edit.inline {
    display: inline-block;
    float: none;
  }
  a.view {
    width: 14px;
    height: 14px;
    float: left;
    margin: 11px 0 0 4px;
  }
  a.delete {
    width: 14px;
    height: 14px;
    float: left;
    margin: 11px 0 0 4px;
  }
  a.pdf {
    margin: 8px 0 0 4px;
  }

  div {
    span {
      border: 0;
      display: block;
      float: left;
      height: 22px;
      line-height: 24px;
      margin: 3px 3px 3px 3px;
      padding: 0px;
      width: 18px;
    }
  }

  img {
    margin-top: 2px;
    max-height: 76px;
    max-width: 99%;

    .confine_images & {
      max-height: 24px;
    }
  }

  #crm_todos & .planned,
  #crm_todos & .unplanned,
  #crm_todos & .unplanned_alert {
    float: left;
    line-height: 30px;
    margin: 12px 5px 0 1px;
    vertical-align: top;
  }
}
