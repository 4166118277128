/* fixed-right: overlay on the right on small screens
pushes center to the left on bigger screens */
.fixed-right {
  backface-visibility: hidden;
  background: $color-white;
  bottom: 0;
  box-sizing: border-box;
  box-shadow: 0 0 5px #d1d1d1;
  max-width: 90%;
  overflow: hidden;
  padding-left: 10px;
  position: fixed;
  right: -505px;
  top: 0;
  transition: right 0.3s, width 0.2s;
  width: 490px;
  word-break: break-word;

  .container {
    max-width: 600px;
    min-width: 0;
    padding: 0;
  }

  .widgettitle {
    .content_t {
      text-transform: uppercase;
    }
  }

  .widget {
    border: 0;
    box-shadow: none;
    margin-top: 30px;
    width: 100%;
  }

  .right-open & {
    right: 0;
  }

  &.narrow {
    width: 250px;
  }
}

.fixed-right-large {
  width: 700px;
}

.fixed-right-backdrop {
  backface-visibility: hidden;
  background: $color-black;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
  visibility: hidden;

  .right-open & {
    cursor: default;
    opacity: 0.3;
    visibility: visible;
  }

  &.active {
    opacity: 0.3;
    visibility: visible;
  }
}
