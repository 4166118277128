#target_history {
  clear: both;
  float: left;
  margin-bottom: 40px;
  margin-top: 40px;
}

#targets_info {
  a.button {
    float: right;
  }

  h2 {
    a {
      cursor: pointer;
      font-size: 11px;
    }

    &.big {
      background-color: $color-grey-3;
      border: 1px solid $color-grey-4;
      border-left: 0;
      border-radius: 4px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      clear: both;
      color: black;
      float: left;
      font-size: 14px;
      font-weight: 700;
      margin: 20px 0 15px -19px;
      padding: 6px 16px 6px 20px;
      width: auto;
    }
  }

  .edit {
    clear: both;
    display: block;
    float: left;
    height: 14px;
    margin: 0 0 7px 0;
    opacity: 0.5;
    position: absolute;
    right: 18px;
    top: 18px;
    width: 14px;
  }

  .preview {
    margin-top: 20px;

    p {
      float: left;
      line-height: 20px;
      margin: 90px 30px 0 40px;
      padding: 20px;
      text-align: center;
      width: 250px;
    }
  }

  .snapshot {
    clear: both;
    float: left;
    margin: 15px 0 20px 0;
  }

  .target {
    border: 1px solid #ccc;
    border-radius: 3px;
    float: left;
    height: 230px;
    margin: 20px 30px 30px 20px;
    min-height: 150px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    width: 355px;

    h2 {
      font-size: 14px;
      min-height: 36px;
      padding-right: 30px;
    }

    &:hover {
      .edit {
        opacity: 1;
      }
    }
  }
}

#target_snapshot_container {
  position: relative;
}
