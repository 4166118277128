.lineholder {
  clear: both;
  float: left;
  height: 1px;
  margin: 14px 0 4px 0;
  position: relative;
  width: 100%;

  .line {
    border-top: 1px solid #ccc;
    font-size: 0;
    height: 0;
    left: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
