@use 'sass:math';

/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

//Colors
$color-black: #000;

// Primary
$color-white: #fff;
$color-primary-1: #00aca9;
$color-primary-2: #2b3b4c;
$color-body-text: #1a1c20;

// Functional
$color-error: #e8423b; // Red
$color-valid: #a3ce51; // Green
$color-warning: #f68d43; // Orange
$color-information: #5488c4; // Blue

// Grey
$color-gray: #808080;

$color-grey-1: #fbfbfb;
$color-grey-2: #f4f4f4;
$color-grey-3: #e9e9e9;
$color-grey-4: #dadada;
$color-grey-5: #bababa;
$color-grey-6: #979797;

//Secondary
$color-primary-lightest: #c0e2e2;
$color-primary-light: #6dbaba;
$color-primary-darker: #019a98;
$color-primary-darkest: #007873;

$color-secondary-2: #c93456; // Pink
$color-secondary-3: #ffce44; // Yellow

// Social
$color-facebook: #3b5998;
$color-twitter: #4099ff;
$color-linkedin: #0077b5;

//Typography
$font: 'proximanova', trebuchet ms, Verdana, Arial, Helvetica, sans-serif;
$font-secondary: 'proximanova-bold', 'Helvetica', 'Arial', trebuchet ms, Verdana, Arial, Helvetica, sans-serif;

$font-size-small: 0.75em;
$font-size-small-2: 0.875em;
$font-size-med: 1em;
$font-size-med-2: 1.0625em;
$font-size-med-3: 1.1429em; // 16px
$font-size-large: 1.4375em;
$font-size-large-2: 2em;

//Defaults
$space: 1em;
$space-and-half: $space * 1.5;
$space-double: $space * 2;
$space-quad: $space * 4;
$space-half: math.div($space, 2);
$pad: 1em;
$pad-and-half: $pad * 1.5;
$pad-double: $pad * 2;
$pad-half: math.div($pad, 2);
$pad-quarter: math.div($pad, 4);

// Shadows
$shadow-boxes: 0 0 5px rgba($color-black, 0.2);
$shadow-drag-boxes: 0 1px 4px 0 rgba(43, 59, 76, 0.1);
$shadow-drag-boxes-hover: 0 1px 10px 0 rgba(43, 59, 76, 0.2);
$shadow-buttons: 0 2px 4px 0 rgba($color-black, 0.2);

// Old ChromeExtension colors
$c-pure-blue: #008ae6;
$c-dark-gray: #999;
$c-strong-green: #8ec900;
$c-lightest-gray: #efefef;

// AHOY
$ahoy-color-black: #000000;
$ahoy-color-white: #ffffff;

$ahoy-color-teal-lightest: #f0f5fc;
$ahoy-color-teal-light: #c1cede;
$ahoy-color-teal: #64788f;
$ahoy-color-teal-dark: #2a3b4d;
$ahoy-color-teal-darkest: #1a1c20;

$ahoy-color-mint-lightest: #f0fafa;
$ahoy-color-mint-light: #57d3d2;
$ahoy-color-mint: #00b2b2;
$ahoy-color-mint-dark: #008a8c;
$ahoy-color-mint-darkest: #004b4d;

$ahoy-color-violet-lightest: #e9e8ff;
$ahoy-color-violet-light: #d3d1fe;
$ahoy-color-violet: #4f1fff;
$ahoy-color-violet-dark: #2800b8;
$ahoy-color-violet-darkest: #004da6;

$ahoy-color-ruby-lightest: #fff0ec;
$ahoy-color-ruby-light: #ffbca6;
$ahoy-color-ruby: #ff7040;
$ahoy-color-ruby-dark: #e84b17;
$ahoy-color-ruby-darkest: #992600;

$ahoy-color-gold-lightest: #fff6e5;
$ahoy-color-gold-light: #ffda8f;
$ahoy-color-gold: #ffcc66;
$ahoy-color-gold-dark: #ed9e00;
$ahoy-color-gold-darkest: #8f3c00;

$ahoy-color-aqua-lightest: #f2f8ff;
$ahoy-color-aqua-light: #cce4ff;
$ahoy-color-aqua: #99c9ff;
$ahoy-color-aqua-dark: #0071f2;
$ahoy-color-aqua-darkest: #004da6;

$ahoy-color-neutral-lightest: #ffffff;
$ahoy-color-neutral-light: #f7f7fa;
$ahoy-color-neutral: #e4e4e6;
$ahoy-color-neutral-dark: #c0c0c4;
$ahoy-color-neutral-darkest: #82828c;

$ahoy-font-family-inter: 'Inter', trebuchet ms, Verdana, 'Arial', sans-serif;
