#filter_users_button {
  color: $color-primary-2;
  cursor: pointer;
  float: right;
  font-size: 18px;
  margin-right: 22px;
  margin-top: -32px;

  &:hover {
    opacity: 0.5;
  }
}
