#ckbrowser {
  padding: 30px;

  img {
    cursor: pointer;
    float: left;
    margin: 0 40px 40px 0;
    max-height: 150px;
  }
}
