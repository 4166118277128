@import "../utils/mixins";

/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/
.btn-group {
  display: inline-block;

  .btn-text {
    margin-right: $space;
  }
}

.btn {
  @include transition(all, 0.2s, ease-out);
  background-color: $color-primary-1;
  border: 0;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: 100%;
  line-height: 1;
  padding: $pad-half $pad;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:focus-visible {
    background-color: $color-primary-darker;
    box-shadow: $shadow-buttons;
    color: $color-white;
    text-decoration: none;
  }

  &.facebook {
    background-color: $color-facebook;

    &:hover,
    &:focus-visible {
      background-color: darken($color-facebook, 5%);
    }
  }

  &.twitter {
    background-color: $color-twitter;

    &:hover,
    &:focus-visible {
      background-color: darken($color-twitter, 5%);
    }
  }

  &.linkedin {
    background-color: $color-linkedin;

    &:hover,
    &:focus-visible {
      background-color: darken($color-linkedin, 5%);
    }
  }

  &.disabled {
    background-color: $color-primary-lightest;
    box-shadow: none;
    color: $color-white;
    cursor: default;
  }
}

.btn-text {
  @include transition(all, 0.2s, ease-out);
  color: $color-primary-2;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: $pad-half;
  text-transform: uppercase;

  &:hover,
  &:focus-visible {
    background-color: $color-grey-2;
    color: $color-primary-2;
    text-decoration: none;
  }

  &.disabled {
    background-color: transparent;
    color: $color-grey-4;
    cursor: default;
  }

  &.alt {
    &:hover,
    &:focus-visible {
      background-color: $color-grey-4;
    }

    &.disabled {
      color: $color-grey-5 !important;
    }
  }
}

.btn-icon {
  @include transition(all, 0.2s, ease-out);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: $color-grey-5;
  cursor: pointer;
  display: inline-block;
  font-size: 100%;
  line-height: 1;
  padding: $pad-half;
  position: relative;

  .counter {
    @include size(14px);
    @include transition(all, 0.2s, ease-out);
    background-color: $color-error;
    border-radius: 50%;
    color: $color-white;
    font-size: $font-size-small;
    font-weight: 700;
    position: absolute;
    text-align: center;
    top: 7px;
    right: 7px;
  }

  &.disabled {
    cursor: default;
  }

  &:hover,
  &:focus-visible {
    color: $color-primary-2;
    text-decoration: none;
  }

  &.alt {
    background-color: $color-primary-1;
    color: $color-white;

    &:hover,
    &-visible {
      background-color: $color-primary-darker;
      box-shadow: $shadow-buttons;
      color: $color-white;
    }
  }
}
