a.invoice_denied {
  color: #ff1111;
}

input.invoicecheckbox {
  margin: 6px 5px 0 1px;
}

#bulk_invoicer {
  .table-icon {
    color: inherit;
    font-size: 14px;
    padding: 0 5px;
    text-decoration: none;
  }
}

#invoice_overview {
  height: auto;
  min-height: 0;

  .entry {
    .subtitle {
      &:first-child {
        width: 100%;
        font-weight: 700;
      }
    }
  }
}

.invoice_extra_lines {
  font-size: 12px;
  line-height: 20px;
  margin-top: 10px;

  .edit {
    background-image: url("#{$cdn-url}/img/pencil_smaller.png");
    bottom: 4px;
    display: block;
    float: left;
    height: 14px;
    position: absolute;
    right: -23px;
    width: 14px;
  }

  i {
    display: block;
    float: left;
    font-style: normal;
    position: relative;
    min-height: 20px;
  }
}
