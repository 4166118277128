.ajax_cc_suggestions {
  background-color: $color-white;
  border: 1px solid $color-grey-4;
  left: 100px;
  position: absolute;
  top: 22px;
  width: 178px;

  a {
    color: $color-body-text;
    cursor: pointer;
    display: block;
    font-size: 11px;
    line-height: 22px;
    padding: 0 10px 0 4px;
    text-decoration: none;

    &.advancedsearch {
      background-image: url("#{$cdn-url}/img/loep.png");
      background-repeat: no-repeat;
      background-position: 4px 4px;
      padding-left: 25px;
    }

    &:hover {
      background-color: $color-grey-3;
      text-decoration: none;
    }

    &.activeSelection {
      background-color: $color-grey-4;
    }
  }

  .sg_con,
  .sg_com,
  .sg_pro {
    background-position: top right;
    background-repeat: no-repeat;
    padding-right: 22px;
    width: 152px;
  }

  .sg_con {
    background-image: url("#{$cdn-url}/img/contact_small.png?v=2");
  }

  .sg_com {
    background-image: url("#{$cdn-url}/img/company_small.png?v=2");
  }

  .sg_pro {
    background-image: url("#{$cdn-url}/img/project_small.png?v=2");
  }
}

.inline_segment {
  .ajax_cc_suggestions {
    left: 0px;
  }
}

.third_party_ticket_corc {
  float: left;
  min-height: 24px;
  position: relative;
  top: -1px;
  width: 289px;

  .label {
    display: inline-block;
    width: 100px;
  }

  &.newCorc {
    background-color: $color-white;
    border: 1px solid $color-grey-4;
    padding: 10px;
    position: absolute;
    z-index: 50;
  }
}

.new-corc-popup {
  background-color: $ahoy-color-neutral-light;
  margin-top: -24px;
  padding-left: 10px;
  position: relative;
  z-index: 100;
}

.add-corc .reply-controls {
  height: auto;
  min-height: 32px;
}

.new-corc-fields .goback {
  top: 0;
}

input + .icon-input {
  display: block;
  position: absolute;
  top: 0px;
  right: 8px;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  pointer-events: none;
}

.noactivity + .icon-input:before {
  content: "\ea0f";
  color: $color-secondary-2;
  font-size: 12px;
}

.noactivity {
  border-color: $color-secondary-2 !important;
}

.goodactive + .icon-input:before {
  content: "\ea10";
  color: $color-primary-1;
}

.goodactive {
  border-color: $color-grey-5 !important;
}
