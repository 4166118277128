.stats_dash {
  clear: both;
  float: left;
  font-size: 13px;
  line-height: 24px;
  margin: 20px 0;
  width: 100%;

  .stats_flow {
    background-color: $color-primary-1;
    background-image: url("#{$cdn-url}/images/funnel.png");
    background-repeat: no-repeat;
    background-position: center center;
    float: right;
    height: 31px;
    width: 420px;

    div {
      color: $color-white;
      float: left;
      height: 31px;
      line-height: 31px;
      padding-left: 20px;
      width: 120px;
    }

    .a1 {
      width: 110px;
    }

    .a2 {
      color: #444;
      padding: 0;
      text-align: center;
      width: 140px;
    }

    .a3 {
      padding-left: 55px;
      width: auto;
    }
  }

  i {
    display: block;
    float: left;
    font-style: normal;
    width: 190px;
  }

  span {
    display: block;
    float: left;
    font-style: normal;
    width: 210px;
  }

  .stats_numbers {
    float: left;
  }
}