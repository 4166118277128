#template_item_holder {
  left: 0;
  position: absolute;
  top: 0;

  a.bigbutton {
    height: 22px;
    line-height: 22px;
    text-align: center;
    width: 112px;
  }

  a.giantbutton {
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 247px;
  }

  input.fileinput {
    float: left;
    line-height: 22px;
    margin: 0;
    padding-left: 2px;
    width: 127px;
  }

  input.text {
    border: 1px solid #aaa;
    border-radius: 2px;
    line-height: 22px;
    padding-left: 2px;
    width: 127px;
  }

  label {
    float: left;
    font-style: normal;
    font-weight: 700;
    color: #333;
    cursor: pointer;
    padding-right: 0;
    padding-bottom: 15px;
    width: 135px;

    span {
      color: $color-error;

      &.float {
        color: #333;
        float: left;
      }
    }
  }

  select {
    border: 1px solid #aaa;
    border-radius: 2px;
    float: left;
    line-height: 22px;
    padding-left: 2px;
    width: 132px;
  }

  #advanced_stuff_clicker,
  #advanced_stuff {
    display: block;
    float: left;
    margin-top: 20px;
  }

  #document & {
    h2 {
      color: #333;
      margin-bottom: 15px;
      font-size: 13px;
    }

    .divi {
      background-color: #eeeff1;
      border: 1px solid #ccc;
      border-radius: 2px;
      cursor: pointer;
      float: left;
      line-height: 26px;
      margin: 5px 10px 0 0;
      overflow: hidden;
      padding-left: 8px;
      width: 118px;
    }

    .div {
      clear: both;
      float: left;
      margin: 15px 0 0 0;
      width: 70px;

      &.compact {
        margin: 0 0 10px 0;
      }
    }
  }

  .fadingboxes & {
    position: relative;
  }

  .minimal {
    float: left;
    margin: 0 10px 4px 0;

    .onoffholder {
      margin-bottom: 8px;
      margin-top: 0;

      label {
        text-align: left;
        margin-right: 0;
        padding-right: 0;
        width: 50px;
      }
    }
  }

  .document_select {
    margin-bottom: 5px;
    width: 266px;
  }

  .notice {
    &.progresswarning {
      background-position: 13px 11px;
      color: $color-black;
      line-height: 20px;
      margin: 17px 0 0 0;
      padding: 12px 9px 12px 60px;
      width: 196px;
    }
  }
}
