#subscription_stats h1 {
  font-size: 19px;
  position: relative;
  margin: 0 0 6px 0;
  float: left;
  display: block;
}
#subscription_stats h1 b {
  cursor: help;
  display: block;
  float: left;
  padding: 1px 10px 1px 10px;
  font-size: 13px;
  font-weight: bold;
  line-height: 14px;
  height: 14px;
  overflow: hidden;
  border-radius: 4px;
  color: $color-white;
  background-color: $color-valid;
  margin: 4px 0 0 6px;
}
#subscription_stats em {
  display: block;
  position: relative;
  padding-bottom: 1px;
  float: left;
  clear: both;
  font-size: 13px;
  font-style: normal;
  border-bottom: 1px solid #e0e0e0;
  width: 190px;
}
#subscription_stats i {
  cursor: help;
  display: block;
  float: left;
  clear: both;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  width: 150px;
  font-size: 13px;
}
#subscription_stats .st {
  float: left;
  margin-bottom: 15px;
  display: block;
  width: 200px;
}
#subscription_stats .rm {
  margin-right: 0px;
}
#subscription_stats .red b {
  cursor: help;
  display: block;
  float: left;
  padding: 2px 5px 0px 5px;
  font-size: 10px;
  line-height: 9px;
  height: 11px;
  overflow: hidden;
  border-radius: 4px;
  color: $color-white;
  background-color: $color-error;
  margin: 3px 0 0 6px;
}
#subscription_stats .blue b {
  cursor: help;
  display: block;
  float: left;
  padding: 2px 5px 0px 5px;
  font-size: 10px;
  line-height: 9px;
  height: 11px;
  overflow: hidden;
  border-radius: 4px;
  color: $color-white;
  background-color: #0098f4;
  margin: 3px 0 0 6px;
}
#subscription_stats .fl {
  display: block;
  float: left;
}
#subscription_stats .st .fl {
  font-size: 13px;
  line-height: 20px;
}
#subscription_stats p {
  margin: 4px 0 0 0;
  width: 200px;
  height: 20px;
}

.subscription_stats .viz {
  float: left;
  margin: 20px 0 0 0;
}
.subscription_stats h2 {
  color: #333;
  text-transform: uppercase;
  float: left;
  font-size: 13px;
  margin-top: 30px;
}
.subscription_stats h2:first-child {
  margin-top: 0;
}
.subscription_stats h2 i {
  color: #999;
  font-style: normal;
  font-size: 11px;
}
.subscription_stats h2 b {
  float: left;
  width: 11px;
  height: 11px;
  display: block;
  background-color: $ahoy-color-aqua-dark;
  border-radius: 2px;
  margin: 3px 5px 0 0;
}
