.onoff {
  background-color: #ccc;
  border-radius: 4px;
  clear: none;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  position: relative;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  width: 56px;

  b {
    background-color: $color-valid;
    border-radius: 4px;
    display: block;
    height: 100%;
    left: 0%;
    padding-right: 8px;
    position: absolute;
    text-align: center;
    top: 0px;
    transition: left 200ms ease-out, opacity 50ms ease-out;
    width: 48px;
  }

  em {
    display: block;
    font-size: 11px;
    font-style: normal;
    line-height: 20px;
    margin: 0 auto 0 auto;
    padding-top: 2px;
    text-align: center;
  }

  i {
    background-color: $color-white;
    border: 1px solid #999;
    border-radius: 4px;
    display: block;
    height: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: all 200ms ease-out;
    width: 7px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.off {
    b {
      left: -85%;
      opacity: 0;
      right: auto;
    }

    i {
      right: 47px;
    }
  }
}

.onoffholder {
  display: block;
  overflow: auto;
  padding: 5px 0 10px 0;

  label {
    color: #333;
    float: left;
    line-height: 22px;
    padding: 0 22px 0 0;
    width: calc(100% - 82px);
  }

  .slim & {
    margin-top: 0;

    label {
      float: left;
      font-style: normal;
      padding-right: 0;
      width: 150px;
    }
  }
}
