#template_items {
  float: left;
  min-height: 1020px;
  position: relative;
  width: 280px;

  a {
    margin-top: 20px;
  }
}
