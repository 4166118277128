#invoicewiz {
  a#searchbutton {
    height: 24px;
    left: 152px;
  }

  h2 {
    display: none;
  }

  h4 {
    height: 24px;
    margin-top: 10px;

    a {
      color: #333;
      font-weight: 700;
    }
  }

  input.text {
    border: 1px solid #aaa;
    color: #333;
    font-size: 12px;
    float: left;
    height: 22px;
    line-height: 24px;
    padding: 0 0 0 4px;
    width: 173px;
  }

  label {
    font-style: normal;
  }

  .select {
    font-size: 12px;
    height: 22px;
    width: 275px;
  }

  .alignleft {
    position: relative;
    float: left;
    height: 30px;

    &.big {
      width: 100%;
    }
  }

  .alignright {
    margin-right: 20px;
    float: right;
  }

  .details {
    opacity: 0.5;
  }

  .footer {
    background-color: #f1f5f8;
    height: 40px;
    margin: 0 0 -18px -18px;
    padding: 10px 13px 10px 23px;
    width: 100%;
  }

  .formcontrols {
    clear: both;
    float: left;
    margin: 0 0 10px -20px;
    min-height: 22px;
    padding: 0 19px 15px 20px;
    width: 100%;

    label {
      min-width: 300px;
      padding-right: 10px;
      line-height: 26px;
      width: 390px;
    }

    .compact {
      float: left;
      width: 56px;
    }

    .div {
      width: 70%;
    }

    .float {
      float: left;
    }

    .onoffholder {
      margin-top: 2px;
    }

    .select {
      height: 23px;
      width: 179px;
    }

    .tooltip {
      margin-top: 4px;
    }
  }

  .iitem {
    clear: both;
    float: left;
    width: 100%;

    b.warningms {
      color: #777;
      float: left;
      font-weight: normal;
      line-height: 32px;
      margin: 0 0 0 40px;
    }

    .div {
      float: left;
    }

    .m {
      color: #333;
      float: left;
      margin-bottom: -9px;
      line-height: 33px;
      width: 432px;

      a {
        color: #333;
        font-weight: 700;

        &.long {
          display: inline-block;
          height: 33px;
          line-height: 33px;
          max-width: 179px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.small_link {
          display: none;
          float: right;
          margin-right: 15px;
        }
      }

      i {
        display: inline-block;
        float: left;
        font-style: normal;
        margin: 0 10px 0 0;
      }
    }

    .newline {
      float: left;
      clear: both;
      width: 100%;
    }

    .project_detail {
      display: none;
      margin: 0 0 0 20px;
    }

    &.active {
      .details {
        opacity: 1;
      }

      .project_detail {
        display: block;
      }
    }
  }

  .invoiceable {
    border-bottom: 1px solid #cecece;
    clear: both;
    float: left;
    margin: 0 0 0 -19px;
    padding: 0 18px 5px 20px;
    width: 100%;

    &:first-child {
      border-top: 1px solid #cecece;
    }

    &:hover .iitem .m a.small_link {
      display: block;
    }
  }

  .message {
    background-color: #e8e8e8;
    color: #333;
    font-size: 12px;
    line-height: 22px;
    margin: -20px 0 20px -19px;
    padding: 15px 19px 15px 19px;
    width: 100%;
  }

  .money_amounts {
    float: right;
    line-height: 33px;
  }

  .pagination {
    float: left;
    margin-top: 5px;
    width: 280px;

    b {
      font-size: 11px;
      padding-left: 15px;
    }

    .text {
      font-size: 11px;
    }
  }

  .switches {
    float: left;
    width: 100px;
  }

  #advanced_stuff {
    width: 100%;
  }

  #advanced_stuff_clicker {
    float: left;
    margin: 4px 0 0 0;
  }

  #preview_area p.notice_more,
  #overview_area {
    label {
      width: 390px;
    }

    select {
      float: left;
      margin: 5px 0 0 30px;
    }

    p.bad {
      margin: 6px 0 0 0;
      background-image: url("#{$cdn-url}/images/check_notify_big.png");
      background-repeat: no-repeat;
      padding: 0 0 0 30px;
      line-height: 23px;
    }
  }

  #pagination {
    margin-right: auto;
  }

  #preview_area {
    height: 30px;

    label {
      margin-right: 20px;
    }

    .onoffholder {
      padding: 0;
    }

    .btn {
      &.gobutton {
        margin-left: 20px;
      }
    }

    .compact {
      width: 56px;
    }
  }
}
