.dialerwrapper {
  float: left;
  width: 250px;

  #actions {
    a {
      width: 33.3%;
    }
  }

  #dialpad {
    list-style: none;
    margin: 0;
    padding: 0;

    b {
      background: #ededed;
      color: #333;
      cursor: pointer;
      display: inline-block;
      font-size: 17px;
      font-weight: 400;
      line-height: 60px;
      margin: 0 2px 2px 0;
      outline: 0;
      padding: 0;
      text-align: center;
      vertical-align: middle;
      width: 82px;

      &:active {
        background-color: #b2b2b2;
      }

      &:hover {
        background-color: #d1d1d1;
      }

      p {
        .dialerwrapper {
          #actions {
            nav {
              position: relative;
            }
          }
        }
      }
    }

    li {
      font-size: 0;
      margin: 0;
      padding: 0;
      width: inherit;
      white-space: nowrap;
    }
  }
}
