#add_page {
  width: 570px;
  height: 130px;
  border: 1px dotted #999;
  background-color: $color-white;
  float: left;
  clear: both;
  margin: 0;
  float: left;
  clear: both;

  p {
    font-weight: 700;
    line-height: 130px;
    text-align: center;
    text-transform: uppercase;
  }
}
