.third_party_ticket {
  float: left;
  min-height: 24px;
  position: relative;
  width: 289px;
}

.reply-textareaholder {
  #ticket_uploaded_attachments {
    .escalate_third_party_content {
      display: none;
    }
  }
}

.third_party_ticket_selected {
  #ticket_uploaded_attachments {
    .link {
      &.escalate_third_party_content {
        display: block;
      }
    }
  }

  .btn-arrow-down,
  .btn-arrow-down:hover,
  .btn-arrow-down::after {
    border-top-color: $ahoy-color-aqua-dark;
  }

  .reply-controls {
    .reply-escalate_third_party {
      display: block;
    }
  }

  .btn {
    background-color: $ahoy-color-aqua-dark;

    &:hover,
    &:focus-visible {
      background-color: darken($ahoy-color-aqua-dark, 5%);
    }
  }
}

.reply-controls {
  .reply-escalate_third_party {
    display: none;
  }
}

.third_party_ticket_corc {
  .text {
    line-height: 16px;
    width: 174px;
  }

  input + .icon-input {
    line-height: 27px;
  }

  .ajax_cc_suggestions {
    left: 103px;
  }
}
