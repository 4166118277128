#TL_extra_overlays {
  backface-visibility: hidden;
  bottom: 0;
  height: 0;
  overflow: visible;
  position: fixed;
  right: 0;

  select#phone {
    height: 22px;
    margin: 11px 0 0 11px;
    width: 219px;
  }

  #actionButtons {
    a.button {
      background-color: $ahoy-color-aqua-dark;
      border-radius: 3px;
      bottom: auto;
      color: $color-white;
      cursor: pointer;
      display: block;
      font-size: 12px;
      left: auto;
      letter-spacing: 1px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-top: 20px;
      margin-right: auto;
      padding: 0;
      position: relative;
      right: auto;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: auto;

      &.blue {
        background-color: $ahoy-color-aqua-dark;
        background-image: none;
        height: 44px;
        line-height: 44px;
        padding: 0 10px 0 10px;
        width: 82px;
      }

      &.float {
        float: left;
        margin-left: 16px;
      }

      &.red {
        background-color: #bc2e2e;
        background-image: url("#{$cdn-url}/images/phone_icons.png");
        background-position: 31px -51px;
        height: 44px;
        width: 82px;

        &:hover {
          background-color: #ab001f;
        }

        &.text {
          background-image: none;
          padding: 0 10px 0 10px;
          line-height: 44px;
        }
      }

      &.green {
        background-color: #98cc40;
        background-image: url("#{$cdn-url}/images/phone_icons.png");
        background-position: 30px -19px;
        height: 44px;
        width: 82px;
      }
    }

    .btnholder {
      clear: both;
      float: left;
      width: 100%;
    }

    #during_incoming {
      a.button {
        &.green {
          margin-left: 20px;
        }
      }
    }

    #savecall {
      width: 198px;
    }

    #voicetimer {
      font-size: 24px;
      float: left;
      margin: 25px 0 0 17px;
      text-align: center;
    }

    .smallbtn {
      background-image: url("#{$cdn-url}/images/phone_icons.png?23");
      background-repeat: no-repeat;
      float: left;
      height: 30px;
      margin: 26px 0 0 11px;
      width: 23px;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }

      &.redirect {
        background-position: 1px 8px;
      }

      &.numpad {
        background-position: 1px -96px;
      }

      &.mute {
        background-position: 1px -176px;

        &.muted {
          background-position: 1px -138px;
        }
      }

      &.wait {
        background-position: 1px -208px;
      }
    }
  }

  .screen {
  }

  .screen {
    background-color: $color-white;
    border: 1px solid #dcdcdc;
    border-bottom-width: 2px;
    border-color: #dcdddd #cbccd0 #cfcfcf;
    border-radius: 1px;
    bottom: 40px;
    left: 0;
    padding: 11px 15px 0 15px;
    position: absolute;

    a.button {
      background-color: $color-primary-1;
      background-repeat: no-repeat;
      background-image: url("#{$cdn-url}/images/v.png?v=3");
      background-position: top right;
      border-radius: 3px;
      bottom: 10px;
      color: $color-white;
      cursor: pointer;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      margin: 25px 7px 0 0;
      min-width: 60px;
      padding: 0 12px 0 12px;
      padding-right: 27px;
      position: absolute;
      right: 6px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 60px;

      &.auto {
        width: auto;
      }
    }

    &.twilio {
      padding: 0;
      width: 250px;

      #ut_form {
        float: left;
      }
    }
  }

  .screenholder {
    display: none;
    float: left;
    height: 2px;
    margin-right: 80px;
    position: relative;
    width: 230px;

    .done_notice {
      color: $color-body-text;
      display: block;
      margin: 90px 0 0 0;
      text-align: center;
    }
  }

  #incoming_call {
    background-color: $color-white;
    color: $color-primary-2;
    float: left;
    height: 100%;
    margin: 0;
    padding: 0px 5px 25px 5px;
    text-align: center;
    width: 96%;

    h4 {
      font-size: 17px;
      padding: 25px 0 0 0;
      text-align: center;
    }

    #viacall {
      text-align: center;
      display: block;
      font-size: 15px;
    }
  }

  #incoming_call_from {
    background-color: $color-primary-1;
    cursor: pointer;
    float: left;
    height: 210px;
    text-align: center;
    width: 250px;

    b {
      display: none;
    }

    img.contact_thumb {
      border: 0;
      border-radius: 40px;
      float: none;
      margin: 39px 0 20px 0;
    }

    p {
      color: $color-white;
      font-size: 16px;
      margin: 0 0 10px 0;

      &.number {
        font-size: 15px;
      }
    }

    &.active {
      background-color: $color-primary-1;
      height: auto;
      margin: 0;
      min-height: 87px;
      padding: 0 0 10px 0;

      b {
        color: $color-white;
        display: block;
        float: left;
        font-size: 14px;
        margin: 17px 0 7px 20px;
        text-align: left;
        width: 130px;
      }

      img.contact_thumb {
        float: left;
        margin: 15px 3px 0 18px;
      }

      p {
        float: left;
        font-size: 13px;
        line-height: 20px;
        margin: 0px 0 0 20px;
        text-align: left;
        width: 130px;
      }
    }
  }
}
