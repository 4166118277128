/* Grid > row (contains a list of .grid-col) */

.grid-row {
  clear: both;
  padding: 0px;
  margin-left: -10px;
  margin-right: -10px;
}
.grid-row::before,
.grid-row::after {
  content: "";
  display: table;
}
.grid-row::after {
  clear: both;
}

/* Grid > columns (must be surrounded by .grid-row) */

.grid-col {
  box-sizing: border-box;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}
.grid-12 {
  width: 100%;
}
.grid-11 {
  width: 91.666%;
}
.grid-10 {
  width: 83.333%;
}
.grid-9 {
  width: 75%;
}
.grid-8 {
  width: 66.666%;
}
.grid-7 {
  width: 58.333%;
}
.grid-6 {
  width: 50%;
}
.grid-5 {
  width: 41.666%;
}
.grid-4 {
  width: 33.333%;
}
.grid-3 {
  width: 25%;
}
.grid-2 {
  width: 16.666%;
}
.grid-1 {
  width: 8.333%;
}

/* Grid > row (contains a list of .grid-col) */

.grid-row {
  clear: both;
  padding: 0px;
  margin-left: -10px;
  margin-right: -10px;
}
.grid-row::before,
.grid-row::after {
  content: "";
  display: table;
}
.grid-row::after {
  clear: both;
}

/* Grid > row (contains a list of .grid-col) */

.grid-bottom {
  margin: 15px -19px -14px;
  border-top: 1px solid $color-grey-4;
}
.grid-largegutter {
  margin-left: -19px;
  margin-right: -19px;
}
.grid-largegutter .grid-col {
  padding-left: 19px;
  padding-right: 19px;
}

/* Grid > columns (must be surrounded by .grid-row and must be combined with a .grid-<size>) */

.grid-col {
  box-sizing: border-box;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}
.grid-12 {
  width: 100%;
}
.grid-11 {
  width: 91.666%;
}
.grid-10 {
  width: 83.333%;
}
.grid-9 {
  width: 75%;
}
.grid-8 {
  width: 66.666%;
}
.grid-7 {
  width: 58.333%;
}
.grid-6 {
  width: 50%;
}
.grid-5 {
  width: 41.666%;
}
.grid-4 {
  width: 33.333%;
}
.grid-3 {
  width: 25%;
}
.grid-2 {
  width: 16.666%;
}
.grid-1 {
  width: 8.333%;
}

/* Grid > row (contains a list of .grid-col) */

.grid-row {
  clear: both;
  padding: 0px;
  margin-left: -10px;
  margin-right: -10px;
}
.grid-row::before,
.grid-row::after {
  content: "";
  display: table;
}
.grid-row::after {
  clear: both;
}

/* Grid > row (contains a list of .grid-col) */

.grid-bottom {
  margin: 15px -19px -14px;
  border-top: 1px solid $color-grey-4;
}
.grid-largegutter {
  margin-left: -19px;
  margin-right: -19px;
}
.grid-largegutter .grid-col {
  padding-left: 19px;
  padding-right: 19px;
}
.grid-vmargin {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Grid > columns (must be surrounded by .grid-row and must be combined with a .grid-<size>) */

.grid-col {
  box-sizing: border-box;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.grid-12 {
  width: 100%;
}
.grid-11 {
  width: 91.666%;
}
.grid-10 {
  width: 83.333%;
}
.grid-9 {
  width: 75%;
}
.grid-8 {
  width: 66.666%;
}
.grid-7 {
  width: 58.333%;
}
.grid-6 {
  width: 50%;
}
.grid-5 {
  width: 41.666%;
}
.grid-4 {
  width: 33.333%;
}
.grid-3 {
  width: 25%;
}
.grid-2 {
  width: 16.666%;
}
.grid-1 {
  width: 8.333%;
}

.grid-rpad50 {
  padding-right: 50px !important;
}
