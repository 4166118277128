.i-black {
  background: $color-primary-2;
}
.i-blue {
  background: $color-primary-1;
}
.i-red {
  background: $color-error;
}
.i-green {
  background: $color-valid;
}
