#statsloader,
#importloader {
  left: 50%;
  position: absolute;
  top: 50%;
}

#loader_timeline {
  background-image: url("#{$cdn-url}/images/loading_big.gif");
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ccc;
  border-radius: 4px;
  clear: both;
  height: 30px;
  margin-bottom: 62px;
  margin-left: -243px;
  padding: 13px;
  width: 460px;
}

#fileupload_loading {
  background-image: url("#{$cdn-url}/images/timeline_loading.gif?v=2");
  display: none;
  float: left;
  height: 16px;
  margin-top: 5px;
  width: 16px;
}
