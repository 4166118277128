.chart_div_wrapper {
  display: inline-block;
  position: relative;

  .chart_click_overlay {
    bottom: 0;
    cursor: pointer;
    left: 80px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
  }
}

.chart_div {
  clear: both;
  cursor: pointer;
  float: left;
  margin: 0px 0 0 80px;
  position: relative;
}

#piechart,
#chart {
  height: 100%;
  width: 100%;
}

#piechart {
  margin: 44px 0 37px 0;
}

#totalpie {
  color: #6a6a6a;
  font-size: 36px;
  left: 25%;
  margin-top: 5%;
  position: absolute;
  text-align: center;
  top: 80%;
  width: 50%;
}

#innerpie {
  color: #6a6a6a;
  left: calc(50% - 103px);
  position: absolute;
  text-align: center;
  top: calc(50% - 56px);
  width: 200px;
  z-index: 1;

  #labelslice {
    color: #91a1ab;
    font-size: 24px;
    height: 32px;
    margin: 4px auto 4px auto;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 184px;
    white-space: nowrap;
  }

  #percentslice {
    font-size: 48px;
    margin-top: 3%;
    font-weight: 700;
  }

  #totalslice {
    font-size: 15px;
    font-weight: 700;
  }
}

#id_general_chart_legend {
  display: none;
}

#chart_numbers {
  background: #fafafa;
  box-sizing: border-box;
  float: left;
  margin-bottom: -15px;
  margin-left: -19px;
  margin-top: 10px;
  width: calc(100% + 38px);
}

.chart_number1,
.chart_number2,
.chart_number3,
.chart_number4 {
  display: table;
  width: 100%;
}

.chart_number4 {
  float: left;
}

.chart_number {
  margin-top: 10px;
  padding-top: 20px;
  color: #787878;
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  display: table-cell;
  font-size: 27px;
  padding-bottom: 20px;
  text-align: center;
  vertical-align: middle;

  small {
    font-size: 15px;
  }

  .chart_number1 & {
    width: 100%;
  }

  .chart_number2 & {
    width: 50%;
  }

  .chart_number3 & {
    width: 33.333%;
  }

  .chart_number4 & {
    width: 25%;
  }

  .previous {
    font-size: 12px;
    margin-top: 5px;

    &.down:before {
      content: "\25BC";
    }

    &.equal {
      font-size: 18px;
      font-weight: 700;
      line-height: 10px;
    }

    &.neg {
      color: $color-error;
    }

    &.pos {
      color: #86b300;
    }

    &.up:before {
      content: "\25B2";
    }
  }

  &:first-child {
    border-left: 0;
  }
}

.chart_legend_item {
  color: $color-white;
  cursor: pointer;
  float: right;
  font-size: 16px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
  transition: all 0.1s linear;
  width: 93%;
  white-space: nowrap;

  i.floatingicon {
    display: block;
    float: left;
    margin: 1px 10px 0 15px;
  }

  &.selected {
    width: 100%;
  }
}

#chart_legend_0 {
  background-color: $color-valid;
}

#chart_legend_1 {
  background-color: #0098f4;
}

#chart_legend_2 {
  background-color: $color-error;
}

.googlechart {
  .loading {
    background-image: url("#{$cdn-url}/images/loading_big.gif");
    background-repeat: no-repeat;
    background-position: center center;
    height: 250px;
    margin: 0 auto;
    width: 500px;
  }

  .loading_failed {
    background-image: url("#{$cdn-url}/images/check_notify_big.png");
    background-repeat: no-repeat;
    background-position: 2px 108px;
    line-height: 240px;
    margin: 0px auto 0 auto;
    padding: 0 0 0 30px;
    vertical-align: top;
    width: 160px;
  }
}
