.ahoy-popup-form {
  --input-width: 168px;
  --popup-form-padding: 18px;

  display: flex;
  flex: 1;
  padding: var(--popup-form-padding);

  *:not([class*="ahoy"]):not([class^="icon"]):not([class^="ck"] *) {
    font-family: $ahoy-font-family-inter !important;
    font-weight: 400 !important;
  }

  &-grid {
    display: grid;
    gap: 0 24px;
    max-height: fit-content;

    &-row-gap {
      gap: 12px 24px;
    }

    grid-template-columns: 300px 300px;
    position: relative;

    @each $columns in (1, 2, 3) {
      &-#{$columns} {
        grid-template-columns: repeat(#{$columns}, 300px);
      }
    }

    // fix for showing a grid via js (because it adds display: block inline)
    &[style*='display: block'] {
      display: grid !important;
    }


    & .ahoy-popup-form-heading {
      grid-column: 1/3;
    }
  }

  & .dp_upsidedown .dp_container {
    bottom: 24px;
  }
  & .dp_upsidedown .dp_cal {
    bottom: -4px;
    top: auto;
    min-height: 157px;
  }

  & .rel {
    width: 100%;
  }

  & #invoice_items {
    grid-area: invoice-items;
    margin: 0 !important;
    width: 100% !important;
  }

  .cke_chrome {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: none;
  }
}

.ahoy-popup-form-quotation {
  display: grid;
  grid-template-columns: 335px 335px 1fr;
  grid-gap: 0 24px;
  grid-template-areas:
    "banner banner banner"
    ". . ."
    ". . ."
    "warning warning warning"
    "intro-text intro-text intro-text"
    "divider divider divider"
    "invoice-items invoice-items invoice-items"
    "attachments attachments attachments";
}

.ahoy-popup-form-quotation:not(.ahoy-popup-form-quotation-tiny) {
  #expiry-quotation-fields{
    label:first-child {
      display: flex;
      align-items: center;;
      flex: 1;
      font-size: 14px;
      height: 30px !important;
      margin-top: 0;
      margin-right: 3px;
      margin-bottom: 0;
      width: unset;
    }

    label + label {
      flex: 1;
      max-width: 42px;
    }
  }

  #customerPickerOnNewQuotation,
  #layout_selector,
  .ahoy-popup-form-quotation-discount,
  .ahoy-popup-form-quotation-layout,
  .ahoy-popup-form-quotation-currency,
  .ahoy-popup-form-quotation-name
  {
    label {
      display: flex;
      align-items: center;;
      flex: 1;
      font-size: 14px;
      height: 30px !important;
      margin-top: 0;
      margin-right: 3px;
      margin-bottom: 0;
      width: unset;
    }

    label + div:not(.onoff) {
      flex: 1;
      max-width: 235px;
    }

    label + input {
      flex: 1;
      max-width: 235px;
    }

    label + .ahoy-custom-field-wrapper {
      flex: 1;
      max-width: 235px;
    }

    .DatePicker {
      width: 100%;
    }

    .ahoy-legacy-toggle{
      width: 100%;

      .onoffholder {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

// SECTION FOR TINY INPUTS
.ahoy-popup-form-quotation-tiny {

  .ahoy-popup-form-quotation-name,
  #customerPickerOnNewQuotation,
  #department-picker,
  #deal-picker,
  #user-picker,
  #expiry-quotation-fields,
  #base-currency-editor,
  #layout_selector,
  #discountholder,
  #text-template-picker
  {
    label {
      display: flex;
      align-items: flex-start;
      flex: 1;
      max-width: 235px;
      width: unset;
      margin-top: 3px;
      margin-right: 2px;
    }

    [data-teamleader-ui="select"]{
      flex: 1;
      max-width: 235px;
    }

    label + div:not(.onoff) {
      flex: 1;
      max-width: 235px;
    }

    label + input {
      flex: 1;
      max-width: 235px;
    }

    label + .ahoy-custom-field-wrapper {
      flex: 1;
      max-width: 235px;
    }

    .DatePicker {
      width: 100%;
    }

    .ahoy-legacy-toggle{
      width: 100%;

      .onoffholder {
        display: flex;
        justify-content: space-between;
        width: 278px;
      }
    }
  }
}

.ahoy-popup-form-quotation-banner {
  grid-area: banner;
}

.ahoy-popup-form-quotation-warning {
  grid-area: warning;
}

.ahoy-popup-form-quotation-intro-text {
  grid-area: intro-text;
}

.ahoy-popup-form-quotation-attachments {
  grid-area: attachments;
}

.ahoy-popup-form-invoice,
.ahoy-popup-form-project-external-costs {
  display: grid;
  grid-template-columns: 309px 309px 309px;
  grid-gap: 0 24px;
  grid-template-areas:
    ". . ."
    ". . ."
    "divider divider divider"
    "invoice-items invoice-items invoice-items";
}

.ahoy-custom-fields-grid {
  display: grid;
  grid-column: 1;
  grid-gap: 0 24px;

  @each $columns in (1, 2, 3) {
    &-#{$columns} {
      grid-template-columns: repeat(#{$columns}, 300px);

      &.ahoy-custom-fields-grid-wide {
        grid-template-columns: repeat(#{$columns}, 309px);
      }

      & .ahoy-popup-form-heading {
        grid-column: 1 / calc(#{$columns} + 1);
      }
    }
  }
}

.ahoy-popup-form-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  #deal-picker {
    width: 100%;
  }
}

.ahoy-popup-form-column {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  width: 300px;

  &:last-of-type {
    margin-right: 0;
  }
}

.ahoy-popup-form-label {
  display: flex;
  flex-shrink: 0;
  color: $ahoy-color-teal-darkest;
  font-family: $ahoy-font-family-inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  width: 120px;
  margin-right: 12px;
  margin-top: 3px;
  flex-shrink: 0;

  &-required::after {
    content: "\00a0 *";
    color: $ahoy-color-ruby;
  }

  & > span {
    color: $ahoy-color-ruby;
    font-family: $ahoy-font-family-inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.ahoy-popup-form-heading {
  color: $ahoy-color-teal-darkest;
  font-family: $ahoy-font-family-inter;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 0;
}

.DatePicker.ahoy-input.ahoy-input-tiny {
  width: 100%;
  background: $color-white
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8cGF0aCBmaWxsPSIjODI4MjhDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCA3YTEgMSAwIDAwLTEgMWgtMmExIDEgMCAwMC0yIDBjLTEuMTAzIDAtMiAuODk3LTIgMnY1YzAgMS4xMDMuODk3IDIgMiAyaDZjMS4xMDMgMCAyLS44OTcgMi0ydi01YzAtMS4xMDMtLjg5Ny0yLTItMmExIDEgMCAwMC0xLTF6bTEgOEg5di00aDZ2NHoiIGNsaXAtcnVsZT0iZXZlbm9kZCIgb3BhY2l0eT0iLjg0Ii8+Cjwvc3ZnPgo=)
    no-repeat top left;
  background-position-y: -1px;
  font-size: 12px;
}

// Overriding the .noactivity styling
// The classname is added with a generic legacy function,
// so this truly is the easiest way
.popup-without-iframe .ahoy-input-tiny.noactivity {
  border: 1px solid $color-grey-5;
}

.ahoy-input.ahoy-input-tiny {
  &.small {
    width: 120px;
    margin-right: 12px;
  }
  &.smaller {
    width: 36px;
  }
}

.ahoy-custom-field-wrapper {
  width: 100%;
}

.ahoy-popup-field-wrapper {
  display: flex;
  position: relative;
  width: 100%;

  &-constrained {
    width: auto;
  }
}

.ahoy-suggestions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24px;
  right: 0;
  width: calc(100% - 2px);
  background-color: $ahoy-color-white;
  border-radius: 4px;
  border: 1px solid $color-grey-5;
  z-index: 1;

  &-full-width {
    width: calc(100% - 2px);
  }

  & a {
    font-family: $ahoy-font-family-inter;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    color: $ahoy-color-teal-darkest;
    border-bottom: 1px solid $color-grey-5;
    padding: 8px;

    &.activeSelection,
    &:hover {
      background-color: $ahoy-color-neutral-light;
    }

    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }

    &:last-of-type {
      border-bottom: none;
      border-radius: 0 0 4px 4px;
    }
  }
}

.ahoy-social-tag-holder {
  width: var(--input-width);
}

.ahoy-first-milestone-wrapper {
  width: auto !important;
  padding: 0 !important;
}

.ahoy-popup-form-divider {
  background-color: $ahoy-color-neutral;
  display: block;
  height: 1px;
  width: calc(100% + var(--popup-form-padding) * 2);
  margin-left: calc(var(--popup-form-padding) * -1);
  grid-area: divider;
}

.ahoy-remove-button {
  position: absolute !important;
  top: -6px;
  right: 0;
}

.ahoy-full-width {
  width: 100%;
}

.ahoy-tooltip {
  position: relative;
  width: 14px;
  height: 14px;

  i {
    position: absolute;
    display: none;
    z-index: 1;
  }

  &:hover i {
      display: block;
      font-style: normal;
      color:$ahoy-color-teal-darkest;
      width: 230px;
      top: -10px;
      right: 25px;
      left: auto;
      padding: 10px 15px 10px 15px;
      background-color: $color-white;
      font-size: 12px;
      line-height: 19px;
      box-shadow:  0 0 0 1px rgba($ahoy-color-teal-dark, .2);
      border-radius: 4px;
  }
}

/**
 * Invoice items
 */

.ahoy-popup-form .incl_vat_field {
  display: none;
}

.ahoy-popup-form #invoice_items {
  .disabled {
    opacity: 0.48;
  }

  .hidden {
    display: none;
  }

  .incl_vat_field {
    display: none;
  }

  #invoice_total_price,
  #invoice_total_margin {
    text-align: right;
  }

  .quotation_margin_totals {
    display: grid;
    grid-template-columns: auto auto auto;
    min-width: 168px;
    .total_row {
      min-width: auto;
      display: inline;
      &:not(:nth-child(3n)) {
        margin-right: 12px;
      }
    }
  }

  .total_row {
    min-width: 168px;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;

    & > i {
      font-family: $ahoy-font-family-inter !important;
      font-style: normal;
      color: $ahoy-color-neutral-darkest;
      margin-right: 6px;
    }

    & > b {
      font-family: $ahoy-font-family-inter !important;
      font-weight: 500 !important;
    }
  }

  .incl_vat_total,
  .incl_vat_field,
  #vat_rows {
    display: none;
  }

  .show_breakdown {
    #vat_rows,
    .incl_vat_total,
    .excl_vat_total {
      display: flex;
    }
  }

  #vat_rows{
    flex-direction: column;
  }

  &.view_incl_vat {
    .excl_vat_field {
      display: none;
    }

    .incl_vat_field {
      display: flex;
    }

    .vat_totals:not(.show_breakdown) {
      .excl_vat_total {
        display: none;
      }

      .incl_vat_total {
        display: flex;
      }
    }
  }

  .action_holder {
    display: flex;
    align-items: center;
    height: 24px;
  }
}

.ahoy-popup-form #bigclicker {
  height: 30px;
  width: 100%;
  margin-top: 6px;
  position: absolute;
  z-index: 1;
}

.ahoy-popup-form .invoice_item_add_in_between {
  height: 24px;
}

.ahoy-popup-form #invoice_item_plusbutton .invoice_item_add_in_between,
.ahoy-popup-form.dragging .invoice_item_add_in_between {
  pointer-events: none;
  opacity: 0;
}

.ahoy-popup-form #invoice_item_plusbutton .ahoy-input[disabled] {
  background: $ahoy-color-neutral-lightest;
  border-color: $ahoy-color-neutral-dark;
}

.ahoy-popup-form #invoice_item_header {
  display: flex;

  > span {
    margin-right: 12px;
    flex-shrink: 0;
  }

  span.header_no {
    width: 18px;
  }

  span.header_omschrijving {
    width: 197px;

    &.header-description-wide {
      width: 263px;
    }
  }

  span.header_pos {
    width: 76px;
  }

  span.header_price {
    width: 72px;
  }

  span.header_amount {
    width: 60px;
  }

  span.header_subtotaal {
    width: 72px;
  }

  span.header_btw_tariff {
    width: 160px;
  }

  span.header_cost_type {
    width: 136px;
  }

  span.header_account {
    width: 180px;
  }
}

.ahoy-item-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 24px;
  margin-right: 12px;
  cursor: move;
}

.ahoy-popup-form .inputitem {
  display: flex;
  flex-direction: column;

  .action_holder button {
    margin: 0;
  }

  &.opacity {
    opacity: 0.48;

    .action_holder {
      display: none;
    }
  }

  .hasLongDescr .action_holder .ahoy-button-icon.icon-14x14-align-left-outline {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMyA3YzAgLjU1Mi0uNTM3IDEtMS4yIDFIMi4yQzEuNTM3IDggMSA3LjU1MiAxIDdzLjUzNy0xIDEuMi0xaDkuNmMuNjYzIDAgMS4yLjQ0OCAxLjIgMXptLTMgNWMwIC41NTItLjQwMyAxLS45IDFIMS45Yy0uNDk3IDAtLjktLjQ0OC0uOS0xcy40MDMtMSAuOS0xaDcuMmMuNDk3IDAgLjkuNDQ4LjkgMXptMy0xMGMwIC41NTItLjUzNyAxLTEuMiAxSDIuMkMxLjUzNyAzIDEgMi41NTIgMSAycy41MzctMSAxLjItMWg5LjZjLjY2MyAwIDEuMi40NDggMS4yIDF6IiBmaWxsPSIjMDA3MUYyIiBmaWxsLW9wYWNpdHk9IjAuODQiIC8+Cjwvc3ZnPgo=);
  }
}

.ahoy-popup-form .inputitem .discount_per_line_input,
.ahoy-popup-form .inputitem .aankoopprijs_input {
  display: none;
  position: relative;
}

.ahoy-popup-form .inputitem.focused .discount_per_line_input,
.ahoy-popup-form .inputitem.focused .aankoopprijs_input {
  display: flex;
}

.hide_accounts {
  #invoice_item_header span.header_account,
  .select_account {
    display: none;
  }
}

.ahoy-popup-form {
  input.text_omschrijving {
    width: 197px !important;

    &.units_enabled {
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
      border-right-width: 0;

      &:focus {
        border-right-width: 1px;
      }
    }
  }

  input.text_price {
    width: 72px !important;
  }

  input.text_amount {
    width: 60px !important;
  }

  .text_invoice_subtotal,
  .marge {
    display: flex;
    align-items: center;
    width: 72px !important;
  }

  .select_btw {
    width: 160px !important;
  }

  .select_cost_type {
    width: 136px !important;
  }

  .select_account {
    width: 156px !important;
  }

  .ahoy-invoice-item-line .select_units {
    width: 66px;

    .units {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }
  }
}

.ahoy-popup-form #invoice_item_stage {
  .current_target .drop-indicator {
    position: absolute;
    display: block;
    top: -7px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $ahoy-color-mint;
  }
}

.ahoy-popup-form:not(.dragging) {
  .ahoy-invoice-item-line {
    &:before {
      content: '';
      background: linear-gradient(90deg, $ahoy-color-neutral 21px, $ahoy-color-neutral-light 21px);
      border-radius: 4px;
      position: absolute;
      bottom: -3px;
      top: -3px;
      left: -3px;
      right: -3px;
      pointer-events: none;
      opacity: 0;

      &:before {
        opacity: 1;
      }
    }

    .ahoy-item-number {
      .drag-icon {
        display: none;
        position: relative;
        left: -1px;
        width: 14px;
        height: 14px;
        color: $ahoy-color-teal-darkest;
      }
    }

    &:hover {
      .ahoy-item-number {
        .number {
          display: none;
        }

        .drag-icon {
          display: inline;
        }
      }
    }
  }
}

.ahoy-invoice-item-line {
  position: relative;
  margin-top: -12px;

  .ahoy-select-wrapper,
  .ahoy-input {
    flex-shrink: 0;
  }

  > div:first-child {
    flex-wrap: wrap;
  }

  .profit-margin-wrapper {
    display: grid;
    grid-template-columns: 14px 42px 72px auto;
    grid-gap: 0 12px;

    &::before {
      content: "";
      display: block;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjA0NyA1Ljk1NUw3Ljg5IDQuNzg0YTEuMDU0IDEuMDU0IDAgMDEwLTEuNDc4IDEuMDI0IDEuMDI0IDAgMDExLjQ2IDBMMTMgN2wtMy42NSAzLjY5NGExLjAyNCAxLjAyNCAwIDAxLTEuNDYgMCAxLjA1NCAxLjA1NCAwIDAxMC0xLjQ3OGwxLjE1Ny0xLjE3MUgyLjAzMkMxLjQ2MiA4LjA0NSAxIDcuNTc3IDEgN3MuNDYyLTEuMDQ1IDEuMDMyLTEuMDQ1aDcuMDE1eiIgZmlsbD0iIzAwMCIvPgo8L3N2Zz4K);
      width: 14px;
      height: 14px;
    }
  }

  .aankoopprijs_input {
    & > label {
      width: 85px;
      text-align: right;
    }

    margin-left: 141px;

    &-with-price-units {
      margin-left: 208px;
    }
  }

  .discount_per_line_input {
    margin-left: 238px;

    &-with-price-units {
      margin-left: 305px;
    }

    label { z-index: 1; }

    .ahoy-badge:not(.has-discount)::before {
      content: "";
      color: $ahoy-color-neutral-darkest;
      display: inline-flex;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4gIDxwYXRoIGZpbGw9IiNGRkYiIGZpbGwtb3BhY2l0eT0iMC44NCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOCw2IEw4LDMgQzgsMi40NDc3MTUyNSA3LjU1MjI4NDc1LDIgNywyIEM2LjQ0NzcxNTI1LDIgNiwyLjQ0NzcxNTI1IDYsMyBMNiw2IEwzLDYgQzIuNDQ3NzE1MjUsNiAyLDYuNDQ3NzE1MjUgMiw3IEMyLDcuNTUyMjg0NzUgMi40NDc3MTUyNSw4IDMsOCBMNiw4IEw2LDExIEM2LDExLjU1MjI4NDcgNi40NDc3MTUyNSwxMiA3LDEyIEM3LjU1MjI4NDc1LDEyIDgsMTEuNTUyMjg0NyA4LDExIEw4LDggTDExLDggQzExLjU1MjI4NDcsOCAxMiw3LjU1MjI4NDc1IDEyLDcgQzEyLDYuNDQ3NzE1MjUgMTEuNTUyMjg0Nyw2IDExLDYgTDgsNiBaIi8+PC9zdmc+);
      // Teal darkest (#1A1C20)
      filter: brightness(0) saturate(100%) invert(9%) sepia(11%) saturate(657%) hue-rotate(182deg) brightness(97%) contrast(94%);
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }

  .milestone-select-wrapper {
    .ahoy-select-wrapper {
      margin-top: 3px;
    }
  }

  b {
    font-weight: normal;
  }
}

.ahoy-invoice-subtitle {
  width: 437px !important;
}

.wide .ahoy-invoice-subtitle {
  width: 419px !important;
}

.ahoy-calculator-widget {
  .currency,
  .percentage {
    position: absolute;
    font-size: 12px;
    line-height: 18px;
    top: 4px;
  }

  .currency {
    margin-left: 6px;
    left: 0;
  }

  .percentage {
    right: 0px;
    margin-right: 6px;
  }

  .discount-input-wrapper {
    position: relative;

    &::after {
      content: "%";
      position: absolute;
      top: 4px;
      right: 6px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .inv {
    align-self: flex-end;
    height: 24px;
  }
}

/**
 * Advanced product search
 */

.ahoy-advanced-search-container {
  display: grid;
  grid-template-columns: 360px auto;
  grid-template-rows: 24px 405px 55px;
  grid-template-areas:
    "toolbar toolbar"
    "results detail"
    "footer footer";
  padding: 18px;

  #tracking_toolbar {
    grid-area: toolbar;

    #advanced_search {
      display: flex;

      .search {
        width: 360px;
      }

      .segments {
        width: 360px;
      }
    }
  }

  .popup-actions {
    grid-area: footer;
    width: calc(100% - 18px * 2);
    position: absolute;
    bottom: 0;
    right: 0;
    align-items: center;
  }

  #productadvanced_list {
    grid-area: results;
    border-right: 1px solid $ahoy-color-neutral;
    margin-top: 18px;
    overflow-y: auto;

    .ahoy-product-list-item {
      padding: 8px;
      border-top: 1px solid $ahoy-color-neutral;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: $ahoy-color-neutral-light;
      }

      &:last-of-type {
        border-bottom: 1px solid $ahoy-color-neutral;
      }
    }
  }

  #productadvanced_detail {
    grid-area: detail;
    padding: 18px;
    overflow-y: auto;
    // This combination padding and margin ensures that scrollbar and its content are properly styled
    padding: 0 18px 18px;
    margin-top: 18px;
  }
}

/**
 * Timetracking pricing
 */
.connected-right {
  position: absolute;
  font-size: 12px;
  line-height: 18px;
  top: 4px;
  right: 6px;
}

.input-unit {
  width: 196px;
}

/**
 * Alerts
 */

.ahoy-alert {
  .ahoy-icon {
    width: 60px;
    height: 60px;
    background-size: cover;
    margin-top: 6px;

    &-alert {
      background-image: url("#{$cdn-url}/images/60x60_error.svg");
    }

    &-info {
      background-image: url("#{$cdn-url}/images/60x60_info.svg");
    }
  }
}
