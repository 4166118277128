#presale_stats_right {
  border-left: 1px solid #ccc;
  float: right;
  padding-left: 10px;

  .div {
    width: 219px;
  }
}

#sale_stats_trechter_image {
  float: left;
}

#extra_visualization path {
  stroke-width: 1; /* control the countries borders width */
  stroke: $color-body-text; /* choose a color for the border */
}
