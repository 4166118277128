.todos,
.bigtodos {
  a {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    min-height: 25px;
    width: auto;
  }

  a.icon-checkmark:before {
    height: 24px;
  }

  b {
    float: right;
    font-weight: 700;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 20px;
  }

  i {
    float: right;
    font-weight: 700;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 30px;
  }

  .itsdone {
    text-decoration: line-through !important;

    span {
      text-decoration: line-through !important;
    }
  }

  .tb {
    position: absolute;
    right: 0px;
    width: auto;
  }
}

.todos {
  position: relative;

  a.planning {
    background-image: url("#{$cdn-url}/img/clock.png?v=2");
  }

  b {
    a {
      color: $color-primary-2;
      text-decoration: none;
    }

    &.alert {
      background-image: url("#{$cdn-url}/images/alert_small.jpg");
      background-position: 5px 4px;
      background-repeat: no-repeat;
    }

    &.tt {
      color: #999;
      font-weight: 400;
      font-size: 11px;
      margin-right: 7px;
    }
  }

  h2 {
    border-bottom: 1px solid;
    color: $color-primary-1;
    font-weight: 400;
    padding-bottom: 5px;
    text-transform: capitalize;

    &.overdue {
      color: $color-secondary-2;
      border-color: $color-secondary-2;
    }
  }

  div {
    span {
      position: relative;

      &:first-child {
        cursor: pointer;
      }
    }
  }

  span {
    background-color: transparent;
    display: block;
    float: left;
    font-weight: 400;
    line-height: 25px;
    max-width: 500px;
    width: 80%;
    z-index: 1;

    a {
      color: $color-primary-2;
      text-decoration: none;
    }
  }

  .morelink {
    background-color: #e0e1e1;
    background-image: url("#{$cdn-url}/images/more.gif?v=3");
    border-radius: 2px;
    clear: both;
    color: $color-body-text;
    cursor: pointer;
    display: block;
    float: left;
    height: 22px;
    margin: 10px 0 5px 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  .tb {
    top: 3px;
  }

  .tpl {
    border-top: 1px solid $color-grey-5;
    font-weight: 700;
    display: block;
    padding-top: 12px;
    padding-left: 0px;
    padding-bottom: 16px;
    width: 402px;

    a {
      float: none;
      font-weight: 700;
      display: inline;
      color: $ahoy-color-aqua-dark;

      &:hover {
        text-decoration: underline;
      }
    }

    b {
      float: none;
      text-align: left;
      width: auto;
    }

    i {
      font-weight: 400;
    }

    span {
      float: none;
      display: inline;
      width: auto;
    }
  }
}

.bigtodos {
  b,
  i {
    line-height: 22px;
  }

  .tb {
    top: 2px;
  }

  &.entry {
    span {
      background-color: transparent;
      display: block;
      float: left;
      font-weight: 400;
      line-height: 24px;
      max-width: 500px;
      width: 80%;
      z-index: 1;
    }
  }
}

.bigtodos i,
#crm_todos .entry div i {
  color: #e8423b;
  font-style: normal;
}

// TODO View
.detail_todo_select {
  float: right;
  font-size: 11px;
  border: 1px solid #ccc;
  width: 150px;
}

.callbacks {
  span {
    a {
      display: inline;
      width: auto !important;
    }
  }
}
