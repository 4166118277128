#otherPages {
  .page {
    position: relative;

    img {
      left: 0px;
      min-height: 806px;
      position: absolute;
      top: 0px;
      width: 570px;
    }
  }
}
