.planning_warnings {
  cursor: pointer;
  float: left;
  margin: -6px 0 0 35px;

  .progresswarning {
    line-height: 20px;

    a.button {
      float: right;
    }

    .w {
      background-color: $color-white;
      box-shadow: 0 5px 5px #888;
      border: 1px solid $color-body-text;
      border-radius: 8px;
      display: none;
      left: 20px;
      margin-bottom: 40px;
      padding: 25px;
      position: absolute;
      top: 20px;
      width: 589px;
      z-index: 10;
    }

    .ws {
      float: left;
      width: 100%;
      clear: both;
      margin: 0 0 15px 0;
    }
  }

  #morewarnings {
    background-color: $color-error;
    border-radius: 3px;
    clear: both;
    color: $color-white;
    display: block;
    float: left;
    font-size: 12px;
    line-height: 24px;
    margin: 10px 0 10px 0;
    padding: 0 12px 0 12px;
    text-transform: uppercase;
    text-decoration: none;
  }

  &:hover {
    .progresswarning {
      .w {
        display: block;
      }
    }
  }
}
