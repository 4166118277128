#user_selection_active {
  background-color: #fafafa;
  clear: both;
  float: left;
  margin-top: -19px;
  margin-left: -19px;
  padding: 0 0 0 19px;
  width: calc(100% + 19px);

  label {
    color: #999;
    float: left;
    line-height: 37px;

    b {
      display: inline-block;
      min-width: 100px;
    }
  }

  .active_users {
    float: left;
    margin-left: 8px;
    padding: 10px 0;
  }

  .division {
    float: left;
    clear: both;
  }

  .user {
    float: left;
    line-height: 37px;
    margin: 0 0px 5px 0px;
  }

  &.users {
    background: none;
    left: -7px;
    position: relative;
  }
}
