.planning_warnings #morewarnings {
  background-color: $color-warning;
}

#rightcontent .tight .content #settings a.active {
  background-color: $color-information;
}

.popupform label span {
  color: $color-warning;
}

#planning_flow {
  float: left;
  overflow-x: auto;
  overflow-y: visible;
  position: relative;
  width: 80%;

  .line {
    position: absolute;
    height: 20px;
    background-color: $color-information;
  }
}

#planning_form {
  a.left,
  a.right {
    background-color: $color-grey-2;
    border-radius: 1px;
    color: $color-body-text;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 12px;
    height: 24px;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: 28px;

    &:hover {
      background-color: $color-grey-4;
    }
  }

  a.left {
    margin: 0 5px 0 0;
  }

  .multi-view-date {
    float: left;
    display: block;
    line-height: 24px;
    width: 36%;
    text-align: center;
    padding: 0 0 0 0;
    color: $color-primary-2;

    strong {
      color: #777;
      font-weight: 400;
    }
  }

  #user-team-select {
    width: 105px;
    margin-right: 12px;
    float: right;

    #planning_week & {
      width: 160px;
    }
  }

  #project-select {
    width: 105px;
    margin-right: 12px;
    float: right;
  }

  #global_score {
    background-color: $color-error;
    border-radius: 3px;
    color: $color-white;
    cursor: help;
    float: left;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    width: 38px;

    &.neutral {
      background-color: $color-warning;
    }

    &.good {
      background-color: $color-valid;
    }
  }
}

#div_weekplanning {
  clear: none;
  float: left;
  margin: 20px 0 0 0;
  position: relative;
  width: calc(100% - 15px);
}

#div_maandplanning {
  background-image: url("#{$cdn-url}/images/dagplanning_lines.png");
  background-position: 0px -124px;
  background-repeat: repeat;
  clear: none;
  float: left;
  height: 574px;
  margin: 32px 0 0 0;
  width: calc(50% - 20px);
}

#div_maandplanning_hours {
  background-image: url("#{$cdn-url}/images/planning-uren.png");
  background-position: 0px -124px;
  background-repeat: no-repeat;
  clear: none;
  float: left;
  height: 574px;
  margin: 20px 0 0 0;
  width: 15px;
}

#planning_wrapper_widget_id {
  #div_maandplanning_hours {
    margin-top: 32px;
  }
}

.dealinfo_month {
  float: right;
  margin: 15px 0 0 0;
  overflow-x: hidden;
  width: calc(50% - 20px);

  .todos {
    max-height: 570px;
    overflow-y: auto;
    padding-right: 40px;
    width: 100%;
  }
}

.todos div .planned,
.bigtodos div .planned,
.listview .entry .planned {
  display: block;
  margin: 7px 5px 0 5px;
  float: right;
  width: 10px;
  height: 10px;
  background-color: #7ecb49;
  border-radius: 2px;
}
.todos div .unplanned,
.bigtodos div .unplanned,
.listview .entry .unplanned {
  transition: all 150ms linear;
  cursor: pointer;
  display: block;
  background-color: #7ecb49;
  border-radius: 2px;
  opacity: 0.32;
  margin: 7px 5px 0 5px;
  float: right;
  width: 10px;
  height: 10px;
}
.todos div:hover .unplanned,
.bigtodos div:hover .unplanned,
.listview .entry:hover .unplanned {
  opacity: 1;
}
.todos div .unplanned_alert,
.bigtodos div .unplanned_alert,
.listview .entry .unplanned_alert {
  cursor: pointer;
  display: block;
  background-image: url("#{$cdn-url}/images/unplanned.gif?v=2");
  margin: 7px 5px 0 5px;
  float: right;
  width: 10px;
  height: 10px;
}

.clickdrag_planning_active .maandplanning .week_dagplanning {
  cursor: copy;
}
.maandplanning .week_dagplanning {
  float: left;
  height: 570px;
  margin: 0 5px 0 5px;
  top: 0px;
  left: 0px;
  background: url("#{$cdn-url}/images/dagplanning_lines.png");
  background-repeat: repeat-x;
  position: relative;
  width: calc(14.2857% - 10px);
  background-position: 0px -124px;
}
.maandplanning .day_7 {
  margin-right: 0;
}
.week_dagplanning span {
  position: absolute;
  font-size: 10px;
  width: 110px;
  display: block;
  left: 0px;
  top: 0px;
}
.week_dagplanning i {
  position: absolute;
  border-bottom: 1px solid #ccc;
  width: 95px;
  display: block;
  left: 0;
  top: 0px;
}
.week_dagplanning b {
  position: absolute;
  border-bottom: 1px dotted $color-grey-4;
  width: 95px;
  display: block;
  left: 0;
  top: 0px;
}
.week_dagplanning .entry {
  position: absolute;
  line-height: 14px;
  font-size: 11px;
  left: 0;
  color: #333;
  height: 30px;
}
.week_dagplanning .entry.dark {
  color: $color-white;
}
.week_dagplanning .google {
  background-color: #ccc;
  cursor: default;
}
.week_dagplanning .ews {
  background-color: #ccc;
  cursor: default;
}
.week_dagplanning .caldav {
  background-color: #ccc;
  cursor: default;
}
.content .week_dagplanning .entry.meeting,
.content .week_dagplanning .meeting em,
.content .dagplanning .entry.meeting,
.content .dagplanning .meeting em {
  cursor: pointer !important;
  color: $color-white;
}
.content .week_dagplanning .entry.callback,
.content .week_dagplanning .callback em,
.content .dagplanning .entry.callback,
.content .dagplanning .callback em {
  color: $color-white;
  white-space: nowrap;
}
.content .week_dagplanning .entry.callback em .inner:before,
.content .dagplanning .entry.callback em .inner:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e603";
  display: block;
  width: 10px;
  height: 10px;
  float: left;
  font-size: 9px;
  margin-right: 4px;
  line-height: 10px;
  margin-left: -4px;
}
.content .week_dagplanning .entry.callback em .inner,
.content .dagplanning .entry.callback em .inner {
  padding-left: 8px;
}
.content .week_dagplanning .meeting em,
.content .dagplanning .meeting em {
  background-color: $color-information;
  border-radius: 2px;
  cursor: pointer;
}
.content .week_dagplanning .callback em,
.content .dagplanning .callback em {
  background-color: #00b7ea;
  border-radius: 2px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.content .dagplanning .planning em,
.content .week_dagplanning .planning em {
  background-color: $color-valid;
  border-radius: 2px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.content .dagplanning .planning em:active,
.content .week_dagplanning .planning em:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.content .week_dagplanning .callback em:active,
.content .dagplanning .callback em {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.content .dagplanning .suggestion em,
.content .week_dagplanning .suggestion em {
  cursor: pointer;
  background-image: url("#{$cdn-url}/images/sug_stripe.png");
  border-radius: 2px;
}
.content .dagplanning .planning.entry em,
.content .week_dagplanning .planning.entry em {
  border: 1px solid $color-valid;
}
.content .dagplanning .entry.suggestion em,
.content .week_dagplanning .suggestion.entry em {
  border: 1px solid $color-valid;
}
.content .dagplanning .callback.entry em,
.content .week_dagplanning .callback.entry em {
  border: 1px solid #00b7ea;
}
.content .dagplanning .meeting.entry em,
.content .week_dagplanning .meeting.entry em {
  border: 1px solid $color-information;
}
.week_dagplanning .entry em,
.dagplanning .entry em {
  cursor: pointer;
  border: 1px solid $color-grey-4;
  background-color: $color-grey-4;
}
#div_weekplanning .entry i.traveltime,
#dagplanning .entry i.traveltime {
  position: absolute;
  display: block;
  width: 100%;
  left: 0px;
  border: 0;
  z-index: 5;
}
#div_weekplanning .entry i.traveltime b,
#dagplanning .entry i.traveltime b {
  position: absolute;
  border-bottom: 1px solid #efefef;
  bottom: 0;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  left: 6px;
  right: 6px;
  width: auto;
  z-index: 6;
  top: auto;
}
#div_weekplanning .entry i.traveltime i,
#dagplanning .entry i.traveltime i {
  font-style: normal;
  background-repeat: no-repeat;
  background-image: url("#{$cdn-url}/images/icon-car-white.png");
  width: auto;
  height: 100%;
  left: 0px;
  right: 1px;
  position: absolute;
  display: block;
  background-color: $color-information;
  border: 1px solid $color-information;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom: 0;
  background-position: 6px 5px;
  padding: 0 0 0 24px;
  font-size: 10px;
  line-height: 23px;
  word-break: normal;
  white-space: nowrap;
  overflow: hidden;
}
#div_weekplanning .entry i.traveltime.short i,
#dagplanning .entry i.traveltime.short i {
  background-image: none;
}

.content .dagplanning .planning.entry.done_1 em,
.content .week_dagplanning .planning.entry.done_1 em {
  border: 1px solid transparent;
  opacity: 1;
}
.content .dagplanning .planning.entry.done_1 em,
.content .week_dagplanning .planning.entry.done_1 em {
  background-color: #c1e08d;
  opacity: 1;
}
.content .dagplanning .callback.done_1 em,
.content .week_dagplanning .callback.done_1 em {
  background-color: #85d2f3;
  opacity: 1;
}
.content .dagplanning .callback.entry.done_1 em,
.content .week_dagplanning .callback.entry.done_1 em {
  border: 1px solid transparent;
  opacity: 1;
}
.content .dagplanning .meeting.done_1 em,
.content .week_dagplanning .meeting.done_1 em {
  background-color: #65b0e9;
  opacity: 1;
}
.content .dagplanning .meeting.entry.done_1 em,
.content .week_dagplanning .meeting.entry.done_1 em {
  border: 1px solid transparent;
  opacity: 1;
}
.content .dagplanning .done_1 em,
.content .week_dagplanning .done_1 em {
  opacity: 0.9 !important;
}
#dagplanning .meeting.done_1 i.traveltime i,
#div_weekplanning .meeting.done_1 i.traveltime i {
  background-color: #65b0e9;
  opacity: 0.9;
  border-color: #65b0e9;
}

.week_dagplanning .planning .dragbar,
.dagplanning .planning .dragbar {
  background-image: url("#{$cdn-url}/images/transparent.png");
  position: absolute;
  display: block;
  z-index: 4;
  top: auto;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 4px;
  cursor: row-resize;
}

.alldayevents {
  padding-top: 4px;
  margin: 8px 0 -12px 0;
}
.week_dagplanning .entry2 {
  width: 110px;
}
.week_dagplanning .entry em,
.dagplanning .entry em,
.alldayevents .all_dayentry em,
#allday_events_holder .allday_entries em {
  display: block;
  border-radius: 4px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.week_dagplanning .callback.entry .inner,
.dagplanning .callback.entry .inner {
  padding-top: 0;
}
.week_dagplanning .entry .inner,
.dagplanning .entry .inner,
.alldayevents .all_dayentry .inner,
#allday_events_holder .allday_entries .inner {
  font-style: normal;
  display: block;
  width: auto;
  padding: 3px 5px 0 5px;
  font-size: 12px;
  line-height: 12px;
  bottom: 5px;
  right: 0px;
  overflow: hidden;
}
.week_dagplanning .entry.tiny .inner,
.dagplanning .entry.tiny .inner {
  padding-top: 1px;
  bottom: 1px;
}
.week_dagplanning .entry.callback .inner,
.dagplanning .entry.callback .inner,
.week_dagplanning .entry.meeting .inner,
.dagplanning .entry.meeting .inner {
  bottom: 0px;
}
