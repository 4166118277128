#playground {
  float: left;
  margin-right: 28px;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  width: 572px;

  &.fadingboxes {
    .page {
      div {
        opacity: 1;
        transition: all 150ms linear;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  #img_page_bg {
    left: 0;
    position: absolute;
    top: 0;
    width: 570px;
  }
}
