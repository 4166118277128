.pagination {
  -webkit-user-select: none;
  user-select: none;

  b {
    display: inline-block;
    font-weight: 400;
    padding-left: 30px;
    vertical-align: top;

    &.help {
      cursor: help;
    }
  }

  .text {
    background-color: transparent;
    border: 0;
    cursor: default;
    display: inline-block;
    float: none;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    margin: 0;
    min-width: 88px;
    padding: 0;
    text-align: center;
    vertical-align: top;
    width: auto;
  }

  #tracking_select_segment {
    display: inline-block;
    margin-left: 10px;
  }
}

.pagination-mini b {
  padding-left: 0;
}

.prev-next-arrows {
  float: right;
  margin-top: -30px;
  margin-right: -8px;
  line-height: 28px;
}

.prev-next-back,
.prev-next-back:visited,
.prev-next-back:hover {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  color: $color-primary-2;
  font-size: 10px;
  vertical-align: middle;
  text-decoration: none;
  margin-right: 3px;
}

.prev-next-a,
.prev-next-a:visited {
  color: $color-primary-2;
  display: inline-block;
  font-size: 20px;
  opacity: 0.4;
  padding: 0 8px;
}

.prev-next-a::before {
  vertical-align: middle;
}

.prev-next-a:hover {
  color: $color-primary-2;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
}

.prev-next-a.disabled,
.prev-next-a.disabled:hover {
  cursor: not-allowed;
  opacity: 0.2;
}

.pagination-first,
.pagination .nextbtn,
.pagination .prevbtn {
  margin-left: 15px;
  float: none;
  width: 32px;
  height: 22px;
  display: inline-block;
  border: 0;
  background-color: $color-grey-2;
  padding: 0;
  border-radius: 1px;
  color: $color-body-text;
  padding-left: 2px;
  box-sizing: border-box;
  padding-top: 5px;
  vertical-align: top;
  line-height: 15px;
  font-size: 9px;
  text-align: center;

  &:hover {
    text-decoration: none;
    background-color: $color-grey-4;
    color: $color-body-text;
  }
}

.pagination .prevbtn {
  margin-right: 15px;
  margin-left: 0px;
  transform: scale(-1, 1);
}

.pagination-inactive,
.pagination .nextbtn.inactive,
.pagination .prevbtn.inactive {
  color: #aaa;
  background-color: $color-grey-2;
  cursor: default;
  pointer-events: none;
}

.pagination-first {
  margin: 0 15px 0 0;

  &::before {
    content: "\f053";
  }
}

.pagination .nextbtn:before {
  content: "\f054";
}

.pagination .prevbtn:before {
  content: "\f054";
}

p.dash_pagination {
  width: 100%;

  a {
    display: block;
    float: left;
  }
}
