.widgettitle {
  .btn-primary {
    background: $color-primary-1;
    color: $color-white;
  }

  .dropdownmenu {
    background-color: $color-white;
    border: 1px solid #ccc;
    box-shadow: 0 5px 5px #888;
    color: #333;
    position: absolute;
    top: 24px;
    left: 0px;
    width: 185px;
  }

  .widget-bigtitle & .btn-primary-nofloat {
    float: none;
    margin-top: 0;
  }
}

.widget:not(.ahoy-overview-page-content)
  .widgettitle
  .content_t
  + .widget-actions {
  margin-left: 12px;
  position: relative;
  top: -2px;
}

.widgettitleholder {
  &.right {
    left: auto;
    right: 7px;

    .dropdownmenu {
      left: auto;
      right: 0;
      width: 430px;
    }
  }

  &.left {
    .dropdownmenu {
      left: 0;
      right: auto;
      width: 430px;
    }
  }
}
