em.helpme {
  color: $color-body-text;
  cursor: help;
  font-style: italic;
}

.content {
  p.help {
    font-size: 12px;
    line-height: 22px;
    margin: 1px 0 2px 3px;

    a {
      color: #333;
      display: inline-block;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      margin: 0 0 2px 5px;
      min-width: 12px;
      padding: 0 3px 0 3px;
      text-align: center;

      &:hover {
        text-decoration: none;
      }

      &.active {
        background-color: #ccc;
        border-radius: 3px;
        color: $color-white;
      }
    }
  }
}

#view_helpernotice {
  bottom: 14px;
  margin-top: -5px;
  position: absolute;
  right: 42px;

  .no-icons-pagination & {
    right: 15px;
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
}
