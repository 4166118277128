.callrecording_list {
  max-width: 140px;
  position: relative;

  .play-pause {
    height: 26px;
    width: 30px;
  }

  .scrubber {
    background: $color-white;
    display: none;
    margin: 9px;
    padding: 0;
    width: 70px;
  }

  .make_it_bigger .audiojs .icon-settings {
    display: block;
  }

  .make_it_bigger .audiojs .scrubber {
    display: block;
  }

  .icon-settings:before {
    content: "\e60d";
  }

  .audiojs {
    .icon-play,
    .icon-stop,
    .icon-settings {
      color: $color-white;
      display: block;
      float: left;
      margin: 0;
      margin-top: 1px;
      line-height: 29px;
      overflow: hidden;
      padding-right: 0;
      text-align: center;
      width: 30px;
    }
  }

  .audiojs .icon-settings {
    display: none;
    height: 26px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 26px;
  }
}

.play-pause,
.callrecording_list .progress,
.callrecording_list .loaded {
  box-sizing: content-box;
  padding: 0;
  position: absolute;
}
