#graph_area {
  clear: both;
  float: left;
  margin-left: -19px;
  min-height: 400px;
  position: relative;
  width: calc(100% + 38px);

  &.full_map {
    height: 700px;
    margin-bottom: -14px;
    margin-left: -19px;
    width: calc(100% + 40px);
  }

  &.general_chart {
    width: calc(100% + 39px);
  } /* for the first type of chars, we add one pixel to account for subpixel rounding errors */
}

.graph_description {
  border-left: 6px solid #ebebeb;
  color: #868686;
  margin-left: 0%;
  margin-top: 30px;
  padding-left: 2%;
  padding-right: 10%;
}

#graph_header {
  float: left;
  clear: both;
  width: 100%;
}

#graph_header_filter_text {
  color: #9b9b9b;
  line-height: 15px;
  margin: 13px 0 13px 0;
}
