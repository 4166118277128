#ext_services_header {
  float: left;
  margin-bottom: -14px;
  width: 910px;
}

.integration {
  background-image: url("#{$cdn-url}/images/external_services.png?v=18");
  background-repeat: no-repeat;

  &.googleapps {
    background-position: 1px -87px;
  }
  &.stripe {
    background-position: -2px -2370px;
  }
  &.mollie {
    background-position: -2px -2448px;
  }
  &.icloud {
    background-position: -3px -187px;
  }
  &.imap {
    background-position: -2px -283px;
  }
  &.ut_service {
    background-position: 0 -366px;
  }
  &.jira {
    background-position: -3px -2011px;
    height: 70px;
  }
  &.webforms {
    background-position: 0 -2247px;
  }
  &.cm {
    background-position: -3px -550px;
  }
  &.octopus {
    background-position: 0 -639px;
  }
  &.exact {
    background-position: 7px -1927px;
  }
  &.twinfield {
    background-position: 7px -2153px;
  }
  &.yuki {
    background-position: 7px -2065px;
  }
  &.reeleezee {
    background-position: 7px -2634px;
  }
  &.bookkeeping {
    background-position: 0 -1817px;
  }
  &.trendstop {
    background-position: -2px -836px;
  }
  &.chrome {
    background-position: -2px -935px;
  }
  &.outlook {
    background-position: 0 -1039px;
  }
  &.word {
    background-position: 0 -1151px;
  }
  &.dropbox {
    background-position: 0 -1356px;
  }
  &.skydrive {
    background-position: 0 -1459px;
  }
  &.voip {
    background-position: -4px -1742px;
    height: 75px;
  }
  &.mailchimp {
    background-position: 0 -1253px;
  }
  &.gmail {
    background-position: 0 -1558px;
  }
  &.deutschepost {
    background-position: 0 -2530px;
  }
  &.linkedin {
    background-image: url("#{$cdn-url}/images/linkedin.svg");
    background-size: 55px;
    background-position: 15px 15px;
  }
}

.integration-detail {
  float: left;
  height: 80px;
  margin: 10px 26px 26px 0;
  width: 420px;
}
