.hasicon,
[class^="icon-"],
[class*=" icon-"] {
  &:before {
    font-family: "icomoon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

#rightcontent #settings a {
  b {
    &:before {
      display: block;
      font-family: "icomoon";
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      speak: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.icon-clippy:before {
  content: "\f035";
}
.icon-mail-reply:before {
  content: "\f053";
}
.icon-long-arrow-up:before {
  content: "\f068";
}
.icon-downloadfile:before {
  content: "\e9c7";
}
.icon-box-remove:before {
  content: "\e95f";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-search:before {
  content: "\f002";
}
.icon-spinner8:before {
  content: "\e981";
}
.icon-icons_callback:before {
  content: "\e603";
}
.icon-icons_meeting:before {
  content: "\e60a";
}

.icon-plus:before {
  content: "\ea0a";
}
.icon-notifications:before {
  content: "\e24a";
}
.icon-icons_incoming_call:before {
  content: "\e618";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-call:before {
  content: "\e603";
}
.icon-list:before {
  content: "\f00c";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-linkedin:before {
  content: "\e901";
}
.icon-attach_file:before {
  content: "\e226";
}
.icon-format_list_bulleted:before {
  content: "\e241";
}
.icon-keyboard_arrow_down:before {
  content: "\e313";
}
.icon-keyboard_arrow_left:before {
  content: "\e314";
}
.icon-keyboard_arrow_right:before {
  content: "\e315";
}
.icon-keyboard_arrow_up:before {
  content: "\e316";
}
.icon-help:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-th-large:before {
  content: "\e902";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-arrow-left:before {
  content: "\f066";
}
.icon-arrow-right:before {
  content: "\f067";
}
.icon-arrow-up:before {
  content: "\f068";
}
.icon-arrow-down:before {
  content: "\f069";
}
.icon-search:before {
  content: "\f003";
}
.icon-filter:before {
  content: "\f0b4";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-star:before {
  content: "\f006";
}
.icon-star-o:before {
  content: "\f007";
}
.icon-spinner:before {
  content: "\f114";
}
.icon-refresh:before {
  content: "\f022";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-suitcase:before {
  content: "\f0f2";
}
.icon-briefcase:before {
  content: "\f0b5";
}
.icon-folder:before {
  content: "\e607";
}
.icon-folder-open:before {
  content: "\e607";
}
.icon-eur:before {
  content: "\f153";
}
.icon-euro:before {
  content: "\f153";
}
.icon-smile-o:before {
  content: "\f118";
}
.icon-frown-o:before {
  content: "\f119";
}
.icon-file-text:before {
  content: "\f15c";
}
.icon-file-o:before {
  content: "\f016";
}
.icon-hourglass-half:before {
  content: "\f252";
}
.icon-calendar-check-o:before {
  content: "\f274";
}
.icon-th-list:before {
  content: "\f00c";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-check-square-o:before {
  content: "\f048";
}
.icon-square-o:before {
  content: "\f09b";
}
.icon-phone:before {
  content: "\f09a";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-area-chart:before {
  content: "\f1fe";
}
.icon-trophy:before {
  content: "\f091";
}
.icon-exclamation:before {
  content: "\f12a";
}
.icon-bullseye:before {
  content: "\f140";
}
.icon-download:before {
  content: "\f019";
}
.icon-icons_timeduration:before {
  content: "\e61c";
}
.icon-iconsabonnementen:before {
  content: "\e61a";
}
.icon-icons_incoming_call:before {
  content: "\e618";
}
.icon-icons_outgoing_call:before {
  content: "\e619";
}
.icon-icons_milestone:before {
  content: "\e617";
}
.icon-icons_activity:before {
  content: "\e616";
}
.icon-icons_mute2:before {
  content: "\e615";
}
.icon-icons_mute:before {
  content: "\e613";
}
.icon-icons_numpad:before {
  content: "\e614";
}
.icon-icons_percent:before {
  content: "\e612";
}
.icon-icons_events-19:before {
  content: "\e611";
}
.icon-icons_admin:before {
  content: "\e600";
}
.icon-icons_artikelen:before {
  content: "\e601";
}
.icon-icons_bedrijf:before {
  content: "\e602";
}
.icon-icons_callback:before {
  content: "\e603";
}
.icon-icons_crm:before {
  content: "\e604";
}
.icon-user:before {
  content: "\e604";
}
.icon-icons_dashboard:before {
  content: "\e605";
}
.icon-icons_deals:before {
  content: "\e606";
}
.icon-icons_documenten:before {
  content: "\e607";
}
.icon-icons_evenementen:before {
  content: "\e608";
}
.icon-icons_facturen:before {
  content: "\e609";
}
.icon-icons_meeting:before {
  content: "\e60a";
}
.icon-icons_planning:before {
  content: "\e60b";
}
.icon-icons_projecten:before {
  content: "\e60c";
}
.icon-icons_settings:before {
  content: "\e60d";
}
.icon-icons_statistieken:before {
  content: "\e61f";
}
.icon-icons_taak:before {
  content: "\e60e";
}
.icon-icons_tickets:before {
  content: "\e60f";
}
.icon-icons_timetracking:before {
  content: "\e610";
  -webkit-font-smoothing: auto;
}
.icon-edit_pencil:before {
  content: "\e074";
}
.icon-notifications:before {
  content: "\e24a";
}
.icon-google-drive:before {
  content: "\e900";
}
.icon-book:before {
  content: "\e91f";
}
.icon-location:before {
  content: "\e947";
}
.icon-stopwatch:before {
  content: "\e952";
}
.icon-printer:before {
  content: "\e954";
}
.icon-box-remove:before {
  content: "\e95f";
}
.icon-hour-glass:before {
  content: "\e979";
}
.icon-spinner8:before {
  content: "\e981";
}
.icon-wrench:before {
  content: "\e991";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-tab:before {
  content: "\ea45";
}
.icon-font-size:before {
  content: "\ea61";
}
.icon-google_drive:before {
  content: "\e900";
}
.icon-dropbox:before {
  content: "\e904";
}
.icon-flag:before {
  content: "\e9cc";
}

.icm_general:before,
.icm_todos_done:before {
  content: "\e605";
}

.icm_performance:before {
  content: "\e610";
}

.icm_callbacks:before {
  content: "\e603";
}

.listview p.message {
  margin-left: 20px;
}

.icm_products:before {
  content: "\e601";
}

.icm_locations:before {
  content: "\e947";
}

.icm_sales:before,
.icm_sales_phases:before {
  content: "\e606";
}

.icm_extra:before {
  content: "\e608";
}

.icm_invoices:before,
.icm_omzet:before {
  content: "\e609";
}

.icm_planning:before {
  content: "\e60b";
}
.icon-learn:before {
  content: "\e908";
}
.icon-feedback:before {
  content: "\e909";
}

.icm_meetings:before,
.icm_meetings_planned:before {
  content: "\e60a";
}

.icm_projects:before {
  content: "\e60c";
}

.icm_tickets:before {
  content: "\e60f";
}

.icon-plus-circle:before {
  content: "\f055";
}
.icon-plus:before {
  content: "\ea0a";
}

.icon-minus-circle:before {
  content: "\f056";
}

.icon-times-circle:before {
  content: "\f057";
}
.icon-trophy:before {
  content: "\f091";
}
.icon-check-circle2:before {
  content: "\f05f";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-area-chart:before {
  content: "\f1fe";
}
.icon-icons_incoming_call:before {
  content: "\e618";
}
.icon-icons_outgoing_call:before {
  content: "\e619";
}
.icon-icons_missed_call:before {
  content: "\e618";
}
#call_list .icon-icons_missed_call {
  color: red;
}
#call_list .icon-icons_outgoing_call {
  color: #98cc40;
}
#call_list .icon-icons_incoming_call {
  color: #09f;
}

.icon-play:before {
  content: "\e905";
}
.icon-stop:before {
  content: "\e907";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-icons_mute:before {
  content: "\e613";
}

.icon-green:before {
  color: $color-valid;
}
.icon-timetracker:before {
  content: "\e610";
}

.icon-folder-open:before {
  left: 1px;
  position: relative;
  top: 1px;
}

.left.icon-chevron-left {
  padding-right: 2px;
}
.right.icon-chevron-right {
  padding-left: 2px;
}

div.option_checked {
  background-image: url("#{$cdn-url}/images/check_green.png?v=3");
  background-position: 0 4px;
  background-repeat: no-repeat;
  line-height: 20px;
  padding-left: 20px;
  padding-top: 1px;
  position: absolute;
  right: 25px;
}

#nowtime {
  background-image: url("#{$cdn-url}/images/time.png?v=2");
  background-repeat: no-repeat;
  display: block;
  height: 12px;
  position: absolute;
  right: -20px;
  top: 0;
  width: 8px;
}

.icon--clickable {
  cursor: pointer;
}
