#burndown_milestone_selector {
  width: 150px;
}

#ms_progress_top {
  height: 70px;
  margin: -20px 0 0 -19px;
  position: relative;
  width: calc(100% + 20px);

  a.bigbutton {
    color: #444;
    display: block;
    float: right;
    font-weight: 700;
    font-size: 41px;
    height: 60px;
    line-height: 60px;
    padding: 0px;
    text-align: center;
    text-decoration: none;
    width: 60px;

    &:hover {
      opacity: 0.8;
      transition: all 100ms linear;
    }

    &.inactive {
      cursor: default;
      opacity: 0.4 !important;
    }
  }

  .ms_slider {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    white-space: nowrap;
    z-index: 3;

    &:active {
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  .ms {
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    display: inline-block;
    height: 40px;
    text-align: center;
    overflow: hidden;
    padding: 15px 12px 14px 12px;

    a {
      color: #333;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      display: block;
      height: 100%;
      width: 100%;

      &:active {
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }

      &:hover {
        text-decoration: none;
      }
    }

    b {
      clear: both;
      float: left;
      text-align: center;
      width: 100%;
    }

    i {
      display: block;
      font-style: normal;
      font-size: 15px;
      max-width: 236px;
      min-width: 181px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.active {
      background-color: #ededed;
      border-bottom: 1px solid #ededed;

      a {
        color: $color-primary-1;
      }

      i {
        font-size: 18px;
      }
    }

    &.done_1 a {
      color: #999;
      text-decoration: line-through;
    }
  }
}

#ms_progress_top_sider {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: calc(100% - 60px);
}

#ms_progress_bottom {
  .gauge {
    background-color: #cbe59f;
    display: inline-block;
    height: 9px;
    margin: 2px 0 0 0;
    position: relative;
    width: 60px;

    .pct {
      background-color: #97cb3f;
      bottom: 0px;
      left: 0px;
      position: absolute;
      top: 0px;
    }

    .ovf {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      background-color: $color-error;
    }
  }

  .info {
    background-color: #ededed;
    border-top: 1px solid #e6e6e6;
    clear: both;
    float: left;
    margin-bottom: 20px;
    margin-left: -19px;
    margin-top: -1px;
    min-height: 45px;
    padding: 25px 20px 25px 20px;
    position: relative;
    width: calc(100% - 2px);

    a.edit {
      background-image: url("#{$cdn-url}/img/edit.png");
      display: block;
      height: 25px;
      position: absolute;
      right: 14px;
      top: 33px;
      width: 26px;
    }

    .line {
      float: left;
      line-height: 20px;
      width: 292px;

      b {
        font-weight: 400;
        color: $color-body-text;
      }

      i {
        color: #333;
        display: inline-block;
        font-style: normal;
        font-weight: 700;
        min-width: 110px;
        padding: 0 10px 0 0;
        width: auto;

        &.red {
          color: $color-error;
        }
      }
    }
  }

  .ms_comments {
    clear: both;
    float: left;
    margin: 10px 0 0 0;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

#milestone_spinner {
  background-image: url("#{$cdn-url}/images/loading_big.gif");
  background-repeat: no-repeat;
  background-position: center center;
  height: 150px;
}

#milestone_content {
  a {
    &.biglongbutton {
      background-color: $ahoy-color-aqua-dark;
      background-image: url("#{$cdn-url}/img/button_forward.png");
      background-repeat: no-repeat;
      background-position: right center;
      color: $color-white;
      display: inline-block;
      font-size: 13px;
      line-height: 25px;
      opacity: 0;
      padding: 0 27px 0 12px;
      position: absolute;
      right: 16px;
      text-decoration: none;
      top: 11px;
      z-index: 2;

      &:hover {
        opacity: 0.8;
        transition: all 100ms linear;
      }
    }
  }

  .col {
    cursor: help;
    float: left;
    margin-right: 20px;
    width: calc(33% - 20px);

    a {
      &.add_todo {
        background-color: $color-primary-1;
        background-image: url("#{$cdn-url}/img/plus_button_trans.png?v=2");
        background-position: 6px center;
        background-repeat: no-repeat;
        color: $color-white;
        display: block;
        float: left;
        font-size: 12px;
        line-height: 28px;
        margin: 30px 10px 5px 0;
        padding: 0 11px 0 26px;
        text-decoration: none;

        &:last-child {
          margin-bottom: 30px;
        }

        &:hover {
          opacity: 0.8;
          transition: all 100ms linear;
        }
      }

      &.morelink {
        background-color: #efefef;
        clear: both;
        color: #999;
        display: block;
        float: left;
        line-height: 30px;
        margin: 30px 0 30px 0;
        text-align: center;
        text-decoration: none;
        width: 273px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    em {
      &.ol {
        background-color: $color-white;
        bottom: 0;
        left: 70px;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    h4 {
      clear: both;
      color: #3c3c3c;
      float: left;
      font-size: 12px;
      font-weight: 700;
      margin: 20px 0 0 0;
      text-transform: uppercase;
    }

    h5 {
      border-bottom: 1px solid #ccc;
      color: #444;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      margin: 0 0 5px 0;
      padding: 0;
      position: relative;
      width: 100%;

      a {
        font-size: 12px;
      }

      i {
        color: #999;
        font-size: 17px;
        font-style: normal;
      }
    }

    p {
      clear: both;
      float: left;
      height: 70px;
      margin: 30px 0 0px 0;

      a {
        clear: both;
        float: left;
      }

      i {
        clear: both;
        float: left;
        font-size: 11px;
        font-style: normal;
        text-align: center;
        width: 100%;
      }
    }

    .fake_todo {
      background-image: url("#{$cdn-url}/images/fake_todo.png?v=2");
      clear: both;
      float: left;
      height: 59px;
      margin: 24px 0 0 0;
      width: calc(100% - 10px);
    }

    .item {
      clear: both;
      color: #3c3c3c;
      cursor: pointer;
      float: left;
      font-size: 10px;
      margin: 10px 0 5px 0;
      position: relative;
      text-overflow: ellipsis;
      width: auto;
      word-break: break-word;

      i.t {
        clear: both;
        float: left;
        font-size: 12px;
        font-style: normal;
        line-height: 14px;
        margin-top: -1px;
      }

      span.resp {
        clear: both;
        float: left;
        font-size: 12px;
        font-weight: 700;
      }

      &:last-child {
        margin-bottom: 20px;
      }

      &:hover {
        a.biglongbutton {
          opacity: 1;
        }

        .ol {
          display: block;
          opacity: 0.5;
        }
      }

      &.done_1 {
        i.t {
          text-decoration: line-through;
        }
      }
    }

    .hidden {
      display: none;
    }

    .tc {
      float: left;
      width: 193px;
    }

    .timeinfo {
      float: left;
      margin: 0 20px 0 0;
      width: 60px;

      .gauge {
        background-color: #cbe59f;
        clear: both;
        float: left;
        height: 9px;
        margin: 2px 0 0 0;
        position: relative;
        width: 60px;

        .ovf {
          background-color: $color-error;
          bottom: 0;
          left: 0;
          position: absolute;
          top: 0;
        }

        .pct {
          background-color: #97cb3f;
          bottom: 0;
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      .t {
        clear: both;
        color: #8cc034;
        float: left;
        font-size: 10px;
        font-weight: 700;
        line-height: 18px;
        white-space: nowrap;

        &.b {
          color: $ahoy-color-aqua-dark;
        }

        &.r {
          color: #ec0006;
        }
      }

      &.noestimate {
        .gauge {
          opacity: 0.15;
        }
      }
    }

    .done_0 {
      .timeinfo {
        .gauge {
          background-color: #7fc5f1;
        }

        .pct {
          background-color: $ahoy-color-aqua-dark;
        }
      }
    }

    &.showhidden {
      .hidden {
        display: block;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
