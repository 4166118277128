.audiojs {
  background: $color-primary-1;
  font-family: monospace;
  font-size: 12px;
  height: 26px;
  margin-bottom: 10px;
  overflow: hidden;
  padding-left: 0;
  position: relative;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  width: 30px;

  audio {
    left: -1px;
    position: absolute;
  }

  p {
    cursor: pointer;
    display: none;
    height: 40px;
    margin: 0;
    width: 25px;
  }

  .error-message {
    float: left;
    display: none;
    margin: 0px 10px;
    height: 36px;
    width: 400px;
    overflow: hidden;
    line-height: 36px;
    white-space: nowrap;
    color: $color-white;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -icab-text-overflow: ellipsis;
    -khtml-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;

    a {
      border-bottom: 1px solid #999;
      color: #eee;
      padding-bottom: 1px;
      text-decoration: none;
      white-space: wrap;
    }
  }

  .loaded {
    background: #ededed;
    height: 14px;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  .play {
    display: block;
  }

  .play-pause {
    color: $color-white;
    float: left;
    height: 26px;
    margin: 0;
    overflow: hidden;
    width: 20px;
  }

  .progress {
    background: #ccc;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #ccc),
      color-stop(0.5, $color-grey-4),
      color-stop(0.51, #ccc),
      color-stop(1, #ccc)
    );
    background-image: -moz-linear-gradient(
      center top,
      #ccc 0%,
      $color-grey-4 50%,
      #ccc 51%,
      #ccc 100%
    );
    height: 14px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 0px;
    z-index: 1;
  }

  .record {
    margin-left: -3px;
  }

  .make_it_bigger & {
    width: 100%;
  }

  &.error {
    .play,
    .pause,
    .scrubber,
    .loading {
      display: none;
    }

    .error,
    .error-message {
      display: block;
    }

    .play-pause {
      p {
        cursor: auto;
      }
    }
  }

  &.loading {
    .play,
    .pause,
    .error {
      display: none;
    }

    .loading {
      display: block;
    }
  }

  &.isplaying {
    .play,
    .loading,
    .error {
      display: none;
    }

    .pause {
      display: block;
    }
  }
}
