#trendstop_button {
  transition: opacity 600ms ease-out;
}

.trends_finances {
  .data {
    float: left;
    margin-top: 4px;

    b {
      position: relative;

      &.figurename {
        color: $ahoy-color-teal-dark;
        display: inline-block;
        font-size: 12px;
        line-height: 18px;
        padding-right: 10px;
        width: 91px;
      }

      &.figurecomp {
        background-image: url("#{$cdn-url}/img/updownarrows.png");
        background-repeat: no-repeat;
        display: inline-block;
        height: 8px;
        opacity: 1;
        width: 6px;
        position: relative;
      }

      &.figurename em,
      &.figurecomp strong {
        background-color: $ahoy-color-neutral-lightest;
        border-radius: 2px;
        box-shadow: 0 4px 6px 0 rgba(42, 59, 77, 0.32);
        color: $ahoy-color-teal-darkest;
        display: none;
        font-style: normal;
        padding: 5px;
        position: fixed;
        text-align: center;
        margin-left: 10px;
        width: 200px;
        z-index: 1;
        border: 1px solid rgba($ahoy-color-teal-dark, 0.2);
        border-radius: 4px;
        font-weight: 100;
        font-size: 14px;
      }

      &.figurename:hover em {
        display: block;
      }
      &.figurecomp:hover strong {
        display: block;
      }

      &.bad_minus {
        background-position: 0px -16px;
      }
      &.good_plus {
        background-position: 0px -2px;
      }
    }

    i {
      display: inline-block;
      font-size: 11px;
      font-style: normal;
      line-height: 21px;
      width: 90px;
    }

    .figure-value {
      display: inline-block;
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
      width: 87px;
      color: $ahoy-color-teal-dark;
      text-align: end;
      margin-right: 3px;
    }

    .left {
      float: left;
      margin-right: 18px;
      width: 197px;
    }

    .right {
      float: left;
      width: 197px;
    }
  }

  .monster {
    background-color: $color-black;
    border: 2px solid $color-white;
    border-radius: 13px;
    height: 12px;
    margin-left: -11px;
    position: absolute;
    width: 12px;
    margin-top: 3px;
  }

  .monster.c1 {
    left: 10%;
    background-color: $ahoy-color-ruby;
  }

  .monster.c2 {
    left: 30%;
    background-color: $ahoy-color-gold;
  }

  .monster.c3 {
    left: 50%;
    background-color: $ahoy-color-gold;
  }

  .monster.c4 {
    left: 70%;
    background-color: $ahoy-color-mint-light;
  }

  .monster.c5 {
    left: 90%;
    background-color: $ahoy-color-mint-light;
  }

  .rating {
    background-image: url("#{$cdn-url}/images/company_rating.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 22px;
    position: relative;
  }
}

@media only screen and (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .trends_finances .data b.figurecomp {
    background-image: url("#{$cdn-url}/img/updownarrows@2x.png");
    background-size: 6px 32px;
  }

  .trends_finances .rating {
    background-image: url("#{$cdn-url}/images/company_rating@2x.png");
  }
}
