.tl-container {
  .tl-scrollbar-y {
    position: absolute;
    right: 0;
    width: 8px;
    background-color: #aaa;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    transition: background-color 0.2s linear, opacity 0.2s linear,
      width 0.2s linear;
  }

  .tl-scrollbar-y-rail {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    right: 3px;
    transition: background-color 0.2s linear, opacity 0.2s linear,
      width 0.2s linear;
    width: 8px;

    &:hover,
    &.hover,
    &.in-scrolling {
      background-color: #eee;
      opacity: 0.9;
      width: 10px;

      .tl-scrollbar-y {
        background-color: #999;
        width: 10px;
      }
    }
  }

  &:hover,
  &.hover {
    .tl-scrollbar-y-rail {
      opacity: 0.6;
    }
  }

  &.ie {
    .tl-scrollbar-y {
      font-size: 0;
    }
  }
}
