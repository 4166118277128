#subgroup {
  border-radius: 3px;
  clear: both;
  float: left;
  margin: 7px 0 0 20px;

  a {
    margin-right: 22px;
    float: left;
    border: 1px solid $color-white;
    min-width: 200px;
    border-radius: 3px;
    display: block;
    height: 30px;
    padding: 9px 15px 9px 15px;
    color: #333;
    vertical-align: top;
    line-height: 30px;

    img {
      float: left;
      margin-right: 15px;
      width: 30px;
    }

    &:hover {
      text-decoration: none;
    }

    &.active {
      background-color: #eee;
      border: 1px solid #d6d7d9;
    }
  }

  span {
    display: block;
    float: left;
    line-height: 30px;
  }
}
