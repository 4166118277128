#errorinfo {
  backface-visibility: hidden;
  height: 1px;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 500ms ease-out;
  visibility: hidden;
  width: 100%;

  i.toast {
    line-height: 29px;
    font-style: normal;
    font-size: 14px;
    background-color: rgb(235, 9, 9);
    text-align: center;
    padding: 4px 50px 4px 50px;
    border-radius: 2px;
    color: $color-white;
    display: inline-block;
    box-shadow: 0 0 2px #ccc;
    margin: 0 auto 0 auto;
  }

  span {
    display: block;
    height: 1px;
    margin: 0 auto 0 auto;
    max-width: 550px;
    min-width: 400px;
    position: relative;
    text-align: center;
  }

  &.success {
    i.toast {
      background-color: #7ecb49;
      text-align: center;
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
