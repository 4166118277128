.tlpipeline {
  padding-top: 20px;
  position: relative;
  min-height: 80px;
  display: block;
  width: 100%;

  b {
    color: #444;
    display: block;
    float: left;
    font-weight: 700;
    min-height: 20px;
    position: relative;
    text-align: right;

    em {
      color: #999;
      font-size: 12px;
      font-style: normal;
    }

    i {
      display: block;
      margin: 0 0 30px 0;
    }
  }

  .top,
  .middle,
  .bottom {
    clear: both;
    float: left;
    min-height: 20px;
    position: relative;
    width: 100%;
  }

  .bottom b:nth-child(2),
  .top b:first-child {
    width: 13px;
  }

  .middle {
    height: 20px;
    padding: 10px 0 10px 0;

    b {
      u {
        cursor: pointer;
        position: absolute;
        z-index: 1;
      }

      &:after {
        content: " ";
        display: block;
        height: 4px;
        position: absolute;
        right: 100%;
        top: 6px;
        width: 100%;
        z-index: 0;
      }

      &:first-child {
        &:after {
          display: none;
        }
      }

      &.blue u,
      &.blue:after,
      &.black:after {
        background-color: #ccc;
      }

      &.blue {
        u {
          background-color: #ccc;
          border: 4px solid #ccc;
        }
      }

      &.green {
        u {
          background-color: $color-valid;
          border: 4px solid $color-valid;
        }

        &:after {
          background-color: $color-valid;
        }
      }

      &.red {
        u,
        &:after {
          background-color: $color-error;
        }
      }

      &.diamond.black,
      &.circle.black {
        u {
          background-color: #ccc;
          transition: all 0.15s ease-out;
        }
      }

      &.circle {
        u {
          border-radius: 10px;
          content: " ";
          display: block;
          height: 10px;
          margin-top: -1px;
          width: 10px;
        }

        &.current {
          u {
            border-radius: 16px;
            border-width: 3px;
            height: 16px;
            margin-top: -3px;
            width: 16px;
          }
        }

        &.red {
          u {
            margin-top: 3px;
          }
        }
      }

      &.diamond {
        u {
          content: " ";
          display: block;
          height: 16px;
          margin: 0;
          margin-top: 0px;
          transform: rotate(45deg);
          width: 16px;
        }

        &.green,
        &.blue {
          u {
            margin-top: -4px;
          }
        }
      }
    }
  }

  .bottom {
    b {
      &:first-child {
        text-align: left;
      }

      &:last-child {
        margin: 0;
        position: absolute;
        right: 13px;
        text-align: right;
        top: 0px;
      }
    }
  }

  &.abs {
    b {
      position: absolute;
    }

    .middle {
      b {
        u {
          top: 0;
          right: 0;
        }

        &:after {
          right: 0;
        }
      }
    }
  }
}

.pipeline-prediction-tooltip {
  z-index: 1;
}
