.tooltip_info_holder {
  cursor: help;
  font-weight: 700;
}

.tooltip {
  background: $color-black;
  border-radius: 100%;
  color: #999 !important;
  display: inline-block;
  float: left;
  height: 16px;
  margin: 0 0 0 8px;
  position: relative;
  text-align: center;
  width: 16px;

  &:before {
    content: "?";
    color: $color-grey-2;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 16px;
    font-weight: bold;
    font-size: 12px;
    font-family: sans-serif, Arial, Verdana; /* sans-serif has a good question mark, lato doesn't */
  }
}

.tooltip {
  i {
    position: absolute;
    display: none;
    z-index: 5;
  }

  &:hover {
    text-decoration: none;

    i {
      background-color: $color-white;
      border: 1px solid #ccc;
      box-shadow: 0 1px 5px #b3bdc6;
      color: #222;
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      left: 29px;
      line-height: 19px;
      padding: 10px 15px 10px 15px;
      text-transform: none;
      text-align: justify;
      top: -10px;
      width: 240px;
    }
  }
}

.tooltip {
  &.inline {
    float: none;
    margin-top: 2px;
    vertical-align: top;
  }
}

#TL_extra_overlays {
  .tooltipleft {
    &:hover {
      i {
        left: auto;
        right: 25px;
      }
    }
  }

  .tooltiptopleft {
    &:hover {
      i {
        bottom: 20px;
        left: auto;
        right: 21px;
        top: auto;
      }
    }
  }
}

.widget-bigtitle .widgettitle .tooltip {
  float: none;
}
.tooltip2 {
  span {
    background: #595959;
    border-radius: 6px;
    color: $color-white;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 5px;
    position: absolute;
    text-align: center;
    visibility: hidden;
    width: 100px;

    &:after {
      bottom: 100%;
      border-bottom: 8px solid #595959;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      content: "";
      height: 0;
      left: 50%;
      margin-left: -8px;
      position: absolute;
      width: 0;
    }
  }
}

span.tooltip2.addicon {
  background-color: #999999;
  cursor: pointer;
  font-size: 36px;
}

div:hover.tooltip2 span,
span:hover.tooltip2 span {
  left: -105%;
  top: 48px;
  visibility: visible;
  z-index: 999;
}
