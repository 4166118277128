#search_links {
  display: none;
}

#searchform {
  position: relative;

  a.clear_search_term {
    background-image: url("#{$cdn-url}/img/remove-input.png");
    background-position: center center;
    background-repeat: no-repeat;
    color: #999;
    display: none;
    height: 25px;
    left: 130px;
    position: absolute;
    text-decoration: none;
    top: 0px;
    width: 25px;
  }

  select.select {
    border: 0;
    border-radius: 0;
    height: 25px;
    margin-left: 5px;
    width: 233px;
  }

  select.smallselect {
    width: 200px;
  }

  .big {
    clear: none;
    float: right;
    margin: 0 21px 0 0;
  }

  &.search_filled_out {
    a.clear_search_term {
      display: block;
    }
  }
}

a#searchbutton {
  color: $color-white;
  font-size: 13px;
  height: 25px;
  left: 383px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 27px;

  &:hover {
    text-decoration: none;
  }
}

#smallsearchbox {
  border: 1px solid #ccc;
  font-size: 11px;
  font-style: italic;
  height: 23px;
  line-height: 23px;
  padding: 0 0 0 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 250px;
}

// Widgets based on search.php
.widget-search {
  background: none;
  box-shadow: none;

  .content {
    padding: 0;
    min-height: 0;
  }

  .widgettitleholder {
    display: none;
  }

  #searchbutton {
    float: left;
    margin-right: 5px;
    position: static;
    vertical-align: middle;

    &.icon-search {
      background: $color-white;
      color: $color-primary-1;
      margin-right: 0;
    }
  }
}

// View search
.view_search {
  float: left;
  position: absolute;
  left: 0;
  top: -45px;

  #searchbutton {
    &.icon-search {
      background-color: $color-white;
      float: left;
      position: static;
      vertical-align: middle;

      &:before {
        color: $color-primary-1;
      }
    }
  }
}
