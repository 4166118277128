.team_planning_links {
  a.ttlink {
    background-color: $ahoy-color-aqua-dark;
    border-radius: 2px;
    cursor: pointer;
    color: $color-white;
    float: right;
    font-size: 10px;
    margin-left: 11px;
    margin-top: 1px;
    line-height: 20px;
    padding: 0 9px 0 9px;
    text-transform: uppercase;

    &:hover {
      background-color: #3d73af;
      text-decoration: none;
    }
  }

  i {
    color: #333;
    float: left;
    font-size: 11px;
    text-transform: uppercase;
  }

  img.contact_thumb {
    border: 0;
    height: 35px;
    margin-left: 2px;
    margin-right: 13px;
    width: 35px;
  }

  p {
    span {
      b {
        float: left;
        clear: both;
        color: #333;
        font-weight: 400;
        font-size: 11px;
      }

      i {
        color: #333;
        font-weight: 700;
        float: left;
        clear: both;
      }
    }

    .nameinfo {
      float: left;
      width: 190px;
    }

    &.normal {
      border-bottom: 1px solid #ccc;
      clear: both;
      float: left;
      margin: 0px 0 0px 0;
      padding-bottom: 12px !important;
      padding-right: 13px !important;
      padding-top: 12px !important;
      width: 492px;

      &:last-child {
        border: 0;
      }
    }
  }

  &.notice {
    width: 515px !important;
  }
}

#teamplanning_setup {
  display: inline-block;
  width: 36%;
}

p.normal.activenow {
  background-color: #ffe6da;
  margin-bottom: 0 !important;
}
