.breadcrumbs {
  align-items: baseline;
  display: flex;
  margin-bottom: 36px;

  .crumbs {
    margin-top: 0;
  }
}

h2.crumbs {
  display: block;
  float: left;
  font-size: 13px;
  font-weight: 400;
  height: 25px;
  line-height: 25px;
  margin-bottom: 0;
  margin-right: 60px;

  a {
    line-height: 25px;
    display: inline-block;
  }
}
