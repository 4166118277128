.settings_filter {
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: inherit;
  height: 33px;
  line-height: 35px;
  margin-bottom: 10px;
  min-width: 20em;
  padding: 0 0 0 0.5em;
  width: 33%;
}
