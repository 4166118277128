#connectivity_info {
  backface-visibility: hidden;
  height: 30px;
  left: calc(50% - 210px);
  position: fixed;
  text-align: center;
  top: 0px;
  width: 420px;

  p {
    background-color: $color-primary-1;
    border-top: 0;
    color: $color-white;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    height: 34px;
    min-width: 72px;
    margin: 0 auto 0 auto;
    padding: 5px 15px 5px 15px;
    text-align: center;
    text-transform: uppercase;
    line-height: 32px;
    width: auto;
  }
}
