#form_inputs {
  input.text {
    clear: both;
    float: left;
    height: 21px;
    width: 200px;
  }

  label {
    float: left;
    line-height: 22px;
    width: 400px;

    span {
      color: $color-error;
      font-weight: 700;
    }
  }

  textarea {
    clear: both;
    height: 60px;
    float: left;
    overflow: auto;
    width: 200px;
  }

  .arrowlinks {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-right: 5px;
  }

  .upt {
    clear: both;
    float: left;
    margin: 0 0 10px 0;
    position: relative;
    width: 760px;
  }

  .edit {
    background-color: none;
    display: block;
    height: 14px;
    position: absolute;
    right: 16px;
    top: 0;
    width: 14px;
  }

  .upt2 {
    clear: both;
    float: left;

    input.radio {
      float: left;
    }
  }

  .delete {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
  }
}

#template_form {
  div {
    float: left;
  }

  .floatright {
    float: left;
    font-weight: 400;
    width: 150px;
  }
}

.form {
  a {
    margin-top: 15px;
  }

  label.small {
    float: left;
    line-height: 22px;
    width: 200px;
  }

  .div {
    clear: both;
    float: left;
    height: 22px;
    margin-top: 5px;

    input {
      float: left;

      &.checkbox {
        margin-top: 3px;
      }
    }

    label {
      float: left;
      line-height: 22px;
      width: 350px;
    }

    select.select {
      width: 200px;
    }
  }
}

.mini_form {
  a {
    margin-top: 15px;
  }

  .div {
    clear: both;
    float: left;
    margin-bottom: 29px;

    input {
      float: left;

      &.checkbox {
        margin-top: 3px;
      }
    }

    label {
      float: left;
      line-height: 22px;
      width: 100%;
    }
  }

  .rel {
    float: left;
  }
}
