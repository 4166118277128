#ajaxlistdataloaderbar {
  background-color: #cecece;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: none;
  height: 3px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

#ajaxlistdatainnerbar {
  background-color: $ahoy-color-aqua-dark;
  height: 3px;
  left: 0;
  position: absolute;
  right: 100%;
  top: 0;
}

// Quick reload: hidden div containing a dynamicly created form with hidden input values
#tl_hf {
  display: none;
}
