#stats_toggle {
  float: right;
  margin: 4px 27px 0 0;

  .picker {
    color: #d3d3d3;
    cursor: pointer;
    display: inline;
    font-size: 16px;
    line-height: 30px;
    padding: 5px 10px 5px 10px;
    transition: all 0.07s linear;

    &:hover {
      color: #a9a9a9;
    }

    &.selected {
      background-color: $color-primary-1;
      color: $color-white;
    }
  }
}
