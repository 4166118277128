#redirect_phone_client_id {
  float: left;
  margin: 15px 0px 0 14px;
  width: 220px;
}

#confirmredirect {
  float: right;
  margin-top: 5px;
}

#redirectsection {
  margin-bottom: 29px;
}
