#file_widget {
  a {
    height: auto;
    background-repeat: no-repeat;
    padding-left: 20px;
  }

  a.iconlink {
    background-image: url("#{$cdn-url}/images/file-icons/file.png?v=2");
    background-position: 0 3px;
    background-repeat: no-repeat;
    border-top: 0;
    clear: both;
    display: block;
    float: left;
    font-size: 14px;
    margin-bottom: 3px;
    margin-left: 0px;
    line-height: 24px;
    overflow: hidden;
    padding: 0 0 0 22px;
    text-decoration: none;

    b {
      clear: both;
      float: left;
      font-weight: 400;
      line-height: 14px;
      padding-top: 3px;
      text-decoration: none;
      width: 100%;
    }

    i {
      clear: both;
      color: #777;
      display: block;
      float: left;
      font-style: normal;
      font-size: 11px;
      line-height: 14px;
      padding-bottom: 5px;
      width: 100%;
    }

    span {
      clear: both;
      color: #777;
      display: block;
      float: left;
      font-size: 11px;
      line-height: 14px;
      padding-top: 2px;
      width: 100%;
    }

    &:last-child {
      border: 0;
    }
  }

  .content {
    margin-bottom: 20px;
    min-height: 0;
    padding: 0 19px;
  }

  .dropbox_icon,
  .google_drive_icon {
    &:before {
      font-size: 17px;
      left: 10px;
      position: absolute;
      top: 4px;
    }
  }

  .dropbox {
    background-color: $ahoy-color-aqua-dark;
    background-position: 5px 0px;
    background-repeat: no-repeat;
    border-radius: 2px;
    color: $color-white;
    cursor: pointer;
    display: block;
    height: 27px;
    line-height: 27px;
    margin: 7px 0 10px 0;
    margin-top: 24px;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: opacity 100ms linear;
    width: 155px;

    i {
      display: none;
      position: absolute;
      top: 5px;
      right: 6px;
      width: 16px;
      height: 16px;
    }

    &.add {
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    &.syncing,
    &.synced {
      opacity: 1;
    }

    &.syncing {
      pointer-events: none;

      i {
        display: block;
      }
    }

    &.synced {
      i {
        display: block;
        line-height: 21px;
        vertical-align: middle;
      }

      .external_file_save {
        &:before {
          content: "\ea10";
        }
      }
    }

    &:hover {
      background-color: $ahoy-color-aqua-dark;
      color: $color-white;
    }
  }

  #external_files_syncstate {
    background-color: #eee;
    color: #333;
    font-size: 15px;
    line-height: 22px;
    padding: 10px 10px 0 10px;
    position: absolute;
    right: 0px;
    top: 45px;
    width: 138px;

    b {
      clear: both;
      display: block;
      float: left;
      font-weight: 400;
      padding: 0 0 0 30px;
      text-transform: none;
      font-size: 11px;
      background-repeat: no-repeat;
      text-align: left;
      margin-bottom: 10px;
    }

    &:before {
      font-size: 20px;
      left: 10px;
      position: absolute;
      top: 10px;
    }
  }
}

#filesform {
  display: none;
  margin: 10px 0 0 12px;

  input {
    color: #333;
    border: 1px solid #ccc;
    line-height: 20px;
    margin-bottom: 8px;
    padding: 0 0 0 4px;
    width: 149px;
  }
}

#files_content {
  overflow: auto;

  a.activeContext {
    color: $ahoy-color-aqua-dark;
  }
}
