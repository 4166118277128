/* rightcontent */
.rightcontent-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#rightcontent {
  padding-top: 42px;
  width: 100%;
  height: 100%;
  position: relative;

  .has-tertiary-navigation-opened & .container {
    min-width: 810px;
  }

  &.has-micro-service {
    padding-top: 0;

    .micro-service-container {
      height: 100%;
      position: relative;
    }
  }
}

.ahoy-overview-page-content {
  .content {
    background-color: transparent;
    padding: 0 19px 14px 19px;
  }

  .listview_container {
    border: 1px solid $ahoy-color-neutral-dark;
  }
}
