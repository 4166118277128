#ticket_uploaded_attachments {
  bottom: 10px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

#ticket_messages {
  .m {
    .attach {
      a {
        border: 1px solid #ececec;
        color: #333;
        display: block;
        float: left;
        font-size: 10px;
        height: 100px;
        margin: 12px 10px 7px 0;
        overflow: hidden;
        padding: 0 0 8px 0;
        position: relative;
        width: 153px;

        b.preview {
          display: block;
          width: 100%;
        }

        em.bottom {
          background-color: $color-grey-2;
          background-position: 7px 6px;
          background-repeat: no-repeat;
          bottom: 0;
          height: 24px;
          left: 0;
          line-height: 18px;
          padding-left: 30px;
          padding-top: 6px;
          position: absolute;
          right: 0;
        }

        i {
          clear: both;
          display: block;
          float: left;
          font-style: normal;
        }

        span {
          color: $color-white;
          position: absolute;
          right: 3px;
          top: 3px;
          z-index: 2;
        }

        u.hover {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          .shadow {
            background-color: $color-black;
            height: 100%;
            left: 0;
            opacity: 0.3;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
          }
        }

        &:hover {
          background-color: #ececec;

          u.hover {
            display: block;
          }
        }
      }
    }

    .l {
      border-radius: 2px;
      min-height: 55px;
      width: 155px;
    }

    .mm {
      img {
        max-width: 100%;
      }

      .attach {
        border-radius: 4px;
        float: left;
        margin-bottom: 2px;
        padding-left: 0;
        padding-top: 15px;
      }

      .mm_internal {
        .internal {
          display: block;
          font-size: 18px;
          height: 19px;
          opacity: 0.3;
          position: absolute;
          right: -30px;
          top: 1px;
          width: 20px;
        }
      }

      &.left {
        margin: 0 125px 0 20px;
      }

      &.right {
        margin: 0 10px 0 175px;
      }
    }

    .opa {
      opacity: 0.75;
    }
  }

  .ms {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    font-size: 0;
  }

  .staticblue {
    background-color: $ahoy-color-aqua-dark;
    border-radius: 2px;
    color: $color-white;
    display: block;
    float: left;
    height: 68px;
    line-height: 68px;
    margin: 0 10px 0 0;
    text-align: center;
    width: 155px;
  }

  .smallblue {
    line-height: 30px;
    height: 30px;
  }

  .ticket_left {
    float: left;
    width: 155px;
  }
}

#ticket_status,
#ticket_responsible {
  width: 170px;
}
