#weekpicker {
  border-bottom: 1px solid #ccc;
  margin: 0 0 10px;

  a.previous {
    display: block;
    float: left;
    line-height: 21px;
    width: 80px;
  }

  a.next {
    float: left;
    display: block;
    line-height: 21px;
    text-align: right;
    width: 80px;
  }

  p {
    color: #555;
    float: left;
    font-weight: 700;
    line-height: 21px;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 240px;
  }
}
