#stats_snake .legend .floatright {
  float: right;
}
.search #stats_snake {
  margin-top: 0;
}

#stats_snake {
  float: left;
  margin: 6px 0 0 0;
  position: relative;

  #snake {
    height: 25px;
    position: relative;
    width: 100%;

    b {
      background-image: url("#{$cdn-url}/images/snakeskin.png");
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    i {
      float: left;
      display: block;
      height: 25px;
      font-style: normal;
    }
  }

  i {
    &.overdue {
      background-color: $color-error;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.paid {
      background-color: $color-valid;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    &.unpaid {
      background-color: #34a2dc;
    }
  }

  .legend {
    div {
      float: left;
      margin: 10px 0 0 0;
      min-width: 130px;
      width: 33%;
    }

    em {
      float: left;
      font-style: normal;
      line-height: 17px;
      margin: 0 0 0 10px;
    }

    i {
      border-radius: 3px;
      color: $color-white;
      display: block;
      float: left;
      font-style: normal;
      height: 17px;
      line-height: 17px;
      text-align: center;
      width: 22px;
    }
  }

  .total {
    text-align: right;
  }

  .message {
    width: 100%;
    em {
      float: left;
      font-style: normal;
      font-size: 11px;
      margin-top: 10px;
    }
  }
}
