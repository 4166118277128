#csvimportform {
  min-height: 400px;

  h4 {
    margin-bottom: 15px;
  }

  input {
    float: left;

    &.gobacktext {
      border: 1px solid #aaa;
      color: #333;
      font-size: 11px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 2px;
      margin-top: 2px;
      padding: 0 0 0 2px;
      width: 206px;
    }

    &.text {
      clear: both;
      display: none;
      float: left;
      height: 16px;
      line-height: 16px;
      margin: 2px 0 0 5px;
      width: 224px;
    }
  }

  label {
    float: left;
    font-size: 11px;
    margin-top: 2px;
    line-height: 30px;
    width: 175px;
  }

  p {
    font-size: 12px;
  }

  select {
    float: left;
    margin-top: 6px;
    width: 230px;
  }

  span {
    &.first_values {
      float: left;
      display: block;
      overflow: hidden;
      font-style: normal;
      color: #777;
      font-size: 11px;
      margin-top: 2px;
      line-height: 22px;
      margin-left: 10px;
      width: 400px;
    }
  }

  .custom {
    input {
      display: block;
      margin: 7px 0 0 0;
      width: 227px;
    }
  }

  .alert,
  .good,
  .notify {
    background-color: $color-grey-3;
    background-position: 21px 14px;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 22px;
    margin: 0 0 19px -19px;
    padding: 15px 12px 15px 57px;
    width: calc(100% + 38px);
  }

  .good {
    background-image: url("#{$cdn-url}/images/check_green_big.png");
  }

  .alert {
    background-image: url("#{$cdn-url}/images/check_alert_big.png");
  }

  .notify {
    background-image: url("#{$cdn-url}/images/check_notify_big.png");
  }

  .csv_values {
    color: #6c6c6c;
    float: left;
    padding-bottom: 30px;
    width: 60%;
  }

  .csv_value_header {
    background: #f0f0f0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #424242;
    padding: 5px;
    text-transform: uppercase;
  }

  .csv_value_record {
    border: 1px solid $color-grey-3;
    border-bottom: none;
    line-height: 20px;
    padding: 5px 0 3px 5px;

    &.tear_off {
      background-image: url("#{$cdn-url}/images/table-tear-off.png?v=2");
      background-repeat: repeat-x;
      background-position: center bottom;
      padding-bottom: 8px;
    }

    &.no_tear {
      border-bottom: 1px solid $color-grey-3;
    }
  }

  .csv_type_selecter {
    float: right;
    margin-right: 34px;
    padding-top: 6px;
    width: 29%;

    .select {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .csv_values_row {
    clear: both;
  }

  .selectors {
    margin-top: 15px;
    margin-left: 6px;

    .div {
      display: table;
    }
  }
}

.csv_import_part {
  margin-bottom: 30px;
}

.csv_imports {
  a.btn {
    margin-top: 5px;
  }
}
