.datepicker_toolbox {
  height: 20px;
  margin: 0 0 10px 0;
  width: 100%;
  z-index: 50;

  a.left,
  a.right {
    background-color: $color-grey-2;
    border-radius: 1px;
    color: $color-body-text;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 12px;
    height: 24px;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: 28px;

    &:hover {
      background-color: $color-grey-4;
    }
  }

  a.left {
    margin: 0 5px 0 0;
  }

  .c_year {
    width: 100px;
  }

  .centerthis {
    margin: 0 auto 0 auto;
    width: 170px;
  }

  .div {
    span {
      line-height: 24px;
      padding: 0 0 0 0;
      text-align: center;

      strong {
        color: #777;
        font-weight: 400;
      }
    }
  }

  .nextlink {
    line-height: 22px;
    display: block;
    text-align: left;
  }

  .prevlink {
    display: block;
    float: left;
    line-height: 22px;
    margin: 0 0 0 220px;
    text-align: right;
    width: 100px;
  }

  .rel {
    display: block;
    float: left;
    padding: 0 20px 0 20px;
    text-align: center;
    width: calc(33% - 40px);

    #rightcontent #timesheets & {
      margin-right: 50px;
    }
  }

  &.align-calender {
    width: 680px;
  }
}

input.DatePicker,
#tableview_searchform input.DatePicker {
  background: $color-white url("#{$cdn-url}/js/datepicker/date.gif") no-repeat
    top left;
  border: 1px solid $color-grey-5;
  cursor: pointer;
  display: block;
  font-size: 13px;
  height: 15px;
  line-height: 15px;
  padding: 4px 3px 4px 24px;
  width: 150px;
}

input:focus.DatePicker {
  background: #fffce9 url("#{$cdn-url}/js/datepicker/datefocus.gif") no-repeat
    top left;
}

.dp_container {
  padding: 0;
  position: absolute;
}

.dp_cal {
  background-color: $color-white;
  border: 1px solid #684f51;
  left: 0;
  margin: 0px 0px 3px 0px;
  position: absolute;
  top: 24px;
  width: 177px;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  th,
  td {
    font-size: 11px;
    height: 16px;
    line-height: 16px;
    padding: 1px 0;
    text-align: center;
    width: 14.2857%;
  }

  th {
    background: #daf2e6;
    border: solid #aad4f2;
    border-width: 1px 0;
    color: #797774;
    font-weight: 700;
    height: 21px;
    line-height: 21px;
  }

  td {
    cursor: pointer;

    &.dp_roll {
      background: #fff6bf;
      color: $color-black;
    }
  }

  thead {
    th {
      background: #d9eefc;
    }
  }

  .dp_select {
    background-color: $color-white;
    border: 0;
    cursor: pointer;
    font-size: 11px;
    height: 16px;
    opacity: 0;
    position: absolute;
    top: 5px;
    width: 61px;
    z-index: 20;
  }

  .arrowButton {
    background: rgba($color-black, 0);
    border: 0;
    cursor: pointer;
    height: 21px;
    line-height: 21px;
    top: 2px;
    width: 20px;
    position: absolute;

    &:last-child {
      left: 156px;
    }

    &:hover {
      color: #222;
    }
  }

  .yearSelectDP:hover ~ .dateTextboxY,
  .monthSelectDP:hover ~ .dateTextboxM {
    color: #222;
  }

  .dateTextboxM,
  .monthSelectDP {
    left: 31px;
    text-align: right;
  }

  .dateTextboxY,
  .yearSelectDP {
    left: 90px;
    text-align: left;
  }

  .dateTextboxY,
  .dateTextboxM {
    width: 55px;
    position: absolute;
    top: 2px;
    z-index: 10;
  }
}

.dp_empty {
  background: #eee;
}

.dp_hide {
  visibility: hidden;
}

.dp_selected {
  background: #328dcf;
  color: $color-white;
}

.dp_today {
  background: #daf2e6;
}
