.tp_container {
  position: relative;
}

input.timepicker {
  border: 1px solid $color-grey-5;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  padding: 3px 3px 3px 24px;
  width: 150px;
}

.tp_wrapper {
  background-color: $color-white;
  border: 1px solid $color-grey-5;
  opacity: 1;
  position: absolute;
  top: 23px;
  width: 177px;
}

.timepicker_caret {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  color: $color-primary-1;
  text-align: center;
  padding: 8px 0 8px;
  font-size: 22px;

  &:hover,
  &:active {
    color: $color-primary-darker;
  }

  &:active {
    user-select: none;
  }
}

.time_block {
  display: inline-block;
  width: 50%;
}

.timepicker_field {
  background: $color-primary-1;
  color: $color-white;
  cursor: ns-resize;
  display: inline-block;
  float: none !important;
  font-size: 18px;
  text-align: center;
  transition: background-color 250ms linear;
  width: 100% !important;

  .main_h {
    &:after {
      color: $color-white;
      content: "h";
      font-size: 13px;
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  .main_m {
    &:after {
      color: $color-primary-1;
      content: "m";
      font-size: 13px;
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  .the_real_value {
    display: block;
    padding: 9px 0 9px 0;

    &.fader {
      background-color: rgba(206, 234, 253, 1);
      font-size: 14px;
      padding: 2px 0 2px 0;
    }
  }

  &.scrolling {
    background-color: $color-primary-darker;
  }
}

.tp.icon-timetracker,
.tp.icon-timeduration {
  color: $color-primary-2;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 12px;
  height: 21px;
  line-height: 22px;
  margin-right: -23px;
  padding: 0;
  position: relative;
  text-align: center;
  top: 1px;
  width: 23px;
}

#dash_timepicker {
  select {
    border: 1px solid #ccc;
    margin: 13px 0 0 8px;
    width: 175px;
  }
}
