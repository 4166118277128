.ext_service {
  clear: both;
  float: left;
  display: none;
  margin: 23px 0 50px 0;
  position: relative;

  i.syncright,
  i.syncleft {
    background-image: url("#{$cdn-url}/images/sync.gif");
    background-position: 0 3px;
    background-repeat: no-repeat;
    display: block;
    font-style: normal;
    height: 13px;
    margin: 9px 0 0 24px;
    padding-left: 25px;
  }

  .content & i.syncleft {
    background-position: 0 -11px;
  }

  h2 {
    font-weight: 400;
    text-transform: uppercase;
  }

  .active {
    background-image: url("#{$cdn-url}/images/check_green.png?v=3");
    background-repeat: no-repeat;
    height: 16px;
    display: block;
    line-height: 16px;
    margin: 5px 0 0 0;
    padding-left: 25px;
  }

  .action_needed {
    background-image: url("#{$cdn-url}/images/alert_blue.png?v=4");
    background-repeat: no-repeat;
    display: block;
    height: 16px;
    line-height: 16px;
    margin: 5px 0 10px 0;
    padding-left: 25px;
  }

  .button {
    clear: both;
    float: right;
    margin-top: 5px;

    &.red {
      background-color: $color-error;
      margin-top: 5px;
    }
  }

  .comfy {
    .onoffholder {
      label {
        width: 110px;
      }
    }
  }

  .controls {
    clear: both;
    float: left;
    margin: 15px 0 0 0;
    width: 872px;

    .tip {
      margin-bottom: 0;
      margin-top: 25px;
    }
  }

  .invisible {
    label {
      display: none;
    }

    .left {
      float: left;
      min-width: 180px;
    }

    .onoffholder {
      margin-top: 1px;
    }
  }

  .leftbutton {
    clear: both;
    float: left;
  }

  .options {
    float: left;
    width: 80px;

    img {
      cursor: pointer;
      margin: 15px 0 0 0;
      opacity: 0.45;
      width: 80px;
    }

    &:hover,
    &.activeoption {
      img {
        opacity: 1;
      }
    }
  }

  &:first-child {
    i.b {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 25px;
  }
}
