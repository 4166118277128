.navigation-detail-page {
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: -27px;

  .overview-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: background-color 0.35s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 3px;
    border-radius: 4px;

    &:hover {
      background: rgba(130, 130, 140, 0.18);
    }

    > svg {
      fill: currentcolor;
      opacity: 0.84;
      margin-right: 6px;
    }
  }

  &-previous-next-buttons {
    margin-left: 6px;

    .previous-button,
    .next-button {
      font-size: 12px !important;
      color: $ahoy-color-teal-darkest !important;
      font-weight: 400 !important;
    }

    .previous-button {
      background-position: left;
      padding-left: 15px !important;
      margin-right: 13px !important;
      padding-right: 3px !important;
    }

    .next-button {
      padding-left: 3px !important;
      background-position: right;
      padding-right: 15px !important;
    }
  }
}
