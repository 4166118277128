.ahoy-popup-form {
  .dp_container {
    min-height: 203px;
    width: 196px;

    &:before {
      border-radius: 4px;
      bottom: 2px;
      box-shadow: 0 4px 6px 0 rgba(42, 59, 77, 0.32);
      content: '';
      left: 2px;
      position: absolute;
      right: 2px;
      top: 2px;
      z-index: -1;
    }

    .dp_cal {
      border: 1px solid $ahoy-color-neutral;
      border-radius: 4px;
      min-height: 203px;
      overflow: hidden;
      top: 0;
      width: 100%;

      th {
        border: 0;
        font-size: 12px;
      }

      thead th {
        background: $ahoy-color-neutral-light;
        color: $ahoy-color-teal-darkest;
        font-weight: 400;
      }

      tbody th {
        background: $ahoy-color-neutral-light;
        font-weight: 700 !important;
      }

      td {
        font-size: 12px;
        padding: 6px 0;

        &.dp_roll:not(.dp_selected) {
          background-color: $ahoy-color-aqua-lightest;
          color: $ahoy-color-aqua-darkest;
        }
      }

      .monthSelectDP,
      .yearSelectDP {
        left: auto;
        text-align: left;
      }
    }

    .dp_empty {
      background-color: $ahoy-color-neutral-lightest;
    }

    .dp_selected,
    td.dp_roll.dp_selected {
      background-color: $ahoy-color-aqua-darkest;
      color: $ahoy-color-neutral-lightest;
    }

    td.dp_today {
      font-weight: 700 !important;

      &:not(.dp_selected) {
        background-color: $ahoy-color-neutral;
        color: $ahoy-color-teal-darkest;
      }
    }

    .ahoy-select-wrapper {
      flex: 1;
      margin: 3px;
    }

    .datepicker-nav-month-year {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 3px 6px;
    }

    .datepicker-nav-month-next,
    .datepicker-nav-month-prev {
      background-position: center;
      background-repeat: no-repeat;
    }

    .datepicker-nav-month-next {
      background-image: url('#{$cdn-url}/images/14x14_arrow_right_outline_teal_darkest.svg');
    }

    .datepicker-nav-month-prev {
      background-image: url('#{$cdn-url}/images/14x14_arrow_left_outline_teal_darkest.svg');
    }
  }

  .datepicker-position-horizontal-left .dp_container {
    left: 0;
  }

  .datepicker-position-horizontal-right .dp_container {
    right: 0;
  }

  .datepicker-position-vertical-bottom {
    .dp_container {
      bottom: auto;
      margin-top: 4px;
      top: 24px;
    }
  }

  .datepicker-position-vertical-top {
    .dp_container {
      bottom: 24px;
      margin-bottom: 4px;
      top: auto;
    }

    .dp_cal {
      bottom: auto;
    }
  }

  .rel {
    position: relative;
  }
}
