#settings {
  height: 62px;
  margin-top: -20px;
  overflow: hidden;
  width: 928px;

  a {
    clear: none;
    color: $color-body-text;
    float: left;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    width: auto;

    i {
      color: #333;
      display: block;
      font-size: 11px;
      font-style: normal;
      left: 0;
      margin: 38px 0 0 0;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      width: 100%;
      z-index: 4;
    }

    &.active {
      i {
        color: $color-white;
        font-size: 11px;
        font-weight: 400;
      }
    }
  }

  b {
    height: 20px;
    left: 37px;
    position: absolute;
    top: 7px;
    width: 20px;
    z-index: 9;

    #rightcontent &:before {
      color: #777;
      float: left;
      height: 30px;
      font-size: 19px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      width: 26px;
    }
  }
}

.settings_ok {
  background-image: url("#{$cdn-url}/images/notif_accept.png");
  background-position: 0 -2px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 29px;
  line-height: 29px;
  padding-left: 27px;
}
