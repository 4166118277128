#show_teamviewer_popup {
  position: absolute;
  right: 20px;
}

#support-permission-request {
  position: absolute;
  right: 20px;
  top: 100px;
}

#teamviewer_support_popup_bg {
  background: $color-black;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  transition: all 150ms ease-out;
  width: 100%;

  &.hidden {
    opacity: 0;
  }
}

#teamviewer_support_popup {
  background: $color-white;
  box-sizing: border-box;
  left: calc(50% - 200px);
  position: fixed;
  top: 50%;
  transition: all 150ms ease-out;
  width: 400px;

  .close {
    color: $color-white;
    font-size: 10px;
    line-height: 35px;
    opacity: 0.8;
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 0;

    &:before {
      position: relative;
      top: 2px;
    }

    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }

  .content {
    padding: 20px 15px;
    text-align: center;
  }

  .countdown {
    background: $color-grey-3;
    color: rgba($color-black, 0.5);
    font-size: 12px;
    padding: 6px;
    text-align: center;
  }

  .download_button {
    margin: 20px 0 15px 0;
  }

  .header {
    background: $color-primary-1;
    box-sizing: border-box;
    width: 100%;

    .title {
      color: $color-white;
      font-weight: normal;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
    }
  }

  &.hidden {
    bottom: auto;
    opacity: 0;
    top: calc(100% + 20px);
  }
}
