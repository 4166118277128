#datepicker_dagplanning {
  color: $color-primary-2;
  display: block;
  float: left;
  line-height: 24px;
  margin-left: 8%;
  margin-right: 8%;
  padding: 0 0 0 0;
  text-align: center;
  width: 20%;
}

#datepicker_dagplanning_center {
  margin: 0 auto 0 auto;
  width: 174px;
}
