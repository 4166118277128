body,
textarea,
input,
select {
  font-family: $ahoy-font-family-inter;
  font-weight: 400;
}

body {
  background-color: #f7f7fa;
  min-width: 1150px;
}

body.splash-page {
  min-width: auto;
}

:focus {
  outline: 0;
}

br.clear {
  clear: both;

  &.flattr {
    height: 0;
    line-height: 0;
    font-size: 0;
  }
}

// Tables
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: 400;
}

// Quotes
blockquote,
q {
  quotes: "" "";

  &:before,
  &:after {
    content: "";
  }
}

// Links
a {
  color: $ahoy-color-aqua-dark;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;

  &.warning {
    color: $color-error;
  }

  &:hover {
    text-decoration: underline;
  }
}

// Headings
h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: 24px;
  color: #1a1c20;
}

h2 {
  font-size: 16px;
  margin-top: 8px;
}

h3 {
  font-size: 16px;
  margin-bottom: 3px;
}

h4 {
  font-size: 13px;
}

// Horizontal rule
hr {
  border-bottom: 1px dotted #c4c4c4;
  border-top: none;
  border-left: none;
  border-right: none;
  color: $color-white;
}

// Paragraphs
p {
  margin-bottom: 10px;
}

textarea.autogrowing {
  resize: none;
  overflow: hidden;
  transition: height 0.3s;
}
