.progressbar {
  border: 1px solid #ccc;
  float: left;
  height: 13px;
  margin: 10px 0 0 0;
  overflow: hidden;
  width: 200px;

  .reddish {
    background-color: $color-error;
  }
}

.voortgang {
  background-color: $color-primary-1;
  height: 13px;
}

#voortgang {
  background-color: $ahoy-color-aqua-dark;
  height: 15px;
}

.progressbar_wrapper {
  background-color: $ahoy-color-neutral-light;
  height: 12px;
  margin-top: 4px;

  em.cumul {
    border-right: 1px dotted $color-white;
    position: relative;
    float: left;
    max-width: 100%;
    height: 100%;
    background-color: #a6d259;

    a.trash {
      background-image: url("#{$cdn-url}/images/white_trash.png");
      background-repeat: no-repeat;
      cursor: pointer;
      height: 14px;
      opacity: 0;
      position: absolute;
      right: 2px;
      transition: opacity 0.4s;
      -webkit-transition: opacity 0.4s;
      top: 1.5px;
      width: 12px;
    }

    b {
      background-color: $color-white;
      border: 1px solid #ccc;
      border-radius: 4px;
      color: #333;
      display: block;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
      margin: 0 0 0 -67px;
      padding: 10px 0 10px 0;
      position: relative;
      text-align: center;
      width: 134px;

      .bridge {
        background-image: url("#{$cdn-url}/images/bridge.png");
        left: 48px;
        height: 21px;
        position: absolute;
        top: -18px;
        width: 38px;
      }
    }

    i {
      display: block;
      left: 50%;
      position: absolute;
      top: 36px;
      width: 1px;
    }

    &:hover {
      a.trash {
        opacity: 1;
      }
    }

    &:last-child {
      border-right: 0;
    }
  }

  > .bad_voortgang {
    height: 12px;
    background-color: $color-error;
    position: absolute;
  }

  &.overflowallowed {
    overflow: visible;
  }
}

.progress_wrapper {
  float: left;
  cursor: help;
  background-color: $ahoy-color-mint-light;
  height: 12px;
}

.progress_light {
  background-color: $ahoy-color-neutral;
  cursor: help;
  float: left;
  height: 12px;
}

.p_p {
  margin-top: 10px;
}

.pb-wider {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  position: relative;
}

#progress {
  cursor: pointer;
  float: left;
  font-size: 12px;
  height: 17px;
  margin: 18px 10px 0 7px;
}

#progressbar {
  border: 1px solid #ccc;
  float: left;
  margin: 18px 0 0 0;
  height: 15px;
  width: 250px;
}

.time_labels_wrapper {
  padding: 0 19px;

  .time_label {
    display: inline-block;

    &#done_time {
      color: $ahoy-color-mint;
      margin-right: $space-double;
    }

    &#planned_time {
      color: #888;
    }

    &#total_time {
      color: #888;
      float: right;
    }
  }
}
