.tab {
  background-color: $color-grey-2;
  clear: none;
  color: $color-body-text;
  cursor: pointer;
  display: block;
  float: left;
  line-height: 24px;
  padding: 0 14px 0 14px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: $color-grey-4;
    color: $color-body-text;
  }

  &.active,
  &.active:hover {
    color: #555;
    background: $color-grey-4;
  }
}
