#team_view {
  clear: both;
  float: left;
  margin: 20px 0 0 0;
  width: 99%;

  .d {
    background-color: #98cc40;
    border-radius: 1px;
    cursor: pointer;
    height: 37px;
    overflow: hidden;
    position: absolute;
  }

  .day {
    float: left;
    margin: 0 0 0 20px;
    position: relative;
    width: 200px;

    h3 {
      height: 20px;
      line-height: 20px;
      position: relative;
      text-align: center;
      font-size: 11px;
      color: #333;
      font-weight: 700;

      em {
        border-radius: 3px;
        display: block;
        font-size: 11px;
        font-style: normal;
        height: 100%;
        margin: 0 auto;
        max-width: 110px;
      }

      i {
        display: none;
      }

      &.today {
        em {
          background-color: $color-body-text;
          color: $color-white !important;
        }
      }
    }

    .callback,
    .meeting {
      .data {
        color: $color-white;
      }
    }

    .callback {
      background-color: #00b7ea;
      border-radius: 2px;
      cursor: pointer;
      cursor: -webkit-grab;
      cursor: -moz-grab;

      &:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
      }
    }

    .day_off {
      background-color: $color-warning;
      height: 37px;
      left: 1px;
      position: absolute;
      width: 100%;

      &.valid_no {
        opacity: 0.5;
      }
    }

    .external {
      cursor: pointer;
      background-color: $color-grey-4;
      border: 1px solid $color-grey-4;
    }

    .grate {
      border: 1px solid #ccc;
      border-radius: 3px;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;

      i {
        border-right: 1px solid #ccc;
        float: left;
        height: 100%;
        position: relative;

        b {
          color: #ccc;
          font-size: 9px;
          font-weight: 400;
          left: -11px;
          position: absolute;
          text-align: center;
          top: -17px;
          width: 22px;
        }

        &.end {
          border-right: 0;

          b {
            left: auto;
            right: -10px;
          }
        }

        &.s0 {
          border-right: 1px dotted $color-grey-4;
        }
      }
    }

    .inner_day {
      margin: 35px 0 40px 0;
      position: relative;
      width: 100%;
    }

    .meeting {
      background-color: $ahoy-color-aqua-dark;
      border-radius: 2px;
      cursor: pointer;
    }

    .suggestion {
      background-image: url("#{$cdn-url}/images/sug_stripe.png");
      border-radius: 2px;
      cursor: default;
    }
  }

  .left {
    float: left;
    width: 180px;
    padding: 60px 0 0 0;

    .user {
      cursor: pointer;
      height: 36px;
      margin: 0 0 29px 0;
      position: relative;

      a {
        background-image: url("#{$cdn-url}/img/trash_big.png");
        cursor: pointer;
        display: none;
        height: 20px;
        left: 7px;
        position: absolute;
        top: 7px;
        width: 20px;
      }

      img {
        border-radius: 2px;
        float: left;
        margin: 0 5px 0 0;
        width: 35px;
      }

      span {
        display: block;
        float: left;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin: 0 0 0 5px;
        padding-top: 2px;
        vertical-align: top;
      }

      &:hover {
        a {
          display: block;
        }

        img {
          visibility: hidden;
        }
      }
    }
  }
}
