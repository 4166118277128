#project_graphs_body {
  margin-top: 11px;
  min-height: 100px;
}

#project_graphs_header {
  background-color: #e8e8e8;
  height: 21px;
  margin: -20px 0 0 -19px;
  padding: 17px 17px 16px 24px;
  width: calc(100% - 3px);

  .graph_link_container {
    float: right;
  }

  a {
    border-radius: 3px;
    background-color: $color-white;
    color: $color-black;
    float: left;
    font-size: 12px;
    line-height: 18px;
    margin-left: 5px;
    text-align: center;
    text-decoration: none;
    width: 100px;

    &.active {
      background-color: $color-primary-1;
      color: $color-white;
      font-weight: 700;
    }
  }

  span {
    margin-right: 10px;
  }
}

#project_graphs_header_name {
  font-size: 16px;
  font-weight: 700;
}
