#document {
  a.button {
    &.nextbutton {
      float: right;
      margin-top: 3px;
    }
  }

  h1 {
    color: #333;
    float: left;
    font-size: 21px;
    margin: 0 0 12px 0;
    padding: 0;
    text-transform: uppercase;
    width: 570px;

    a.button {
      display: inline-block;
      margin: 3px 0 0 10px;
      position: relative;
      vertical-align: top;
    }
  }

  p.explain {
    width: 680px;
  }

  #add_page {
    div {
      background-color: $color-white;
      border: 1px solid #ccc;
      cursor: pointer;
      font-size: 11px;
      line-height: 26px;
      padding-left: 8px;
      position: absolute;
    }

    .temp {
      border: 0;
    }
  }

  #playground.fadingboxes {
    #pages {
      float: left;
      background-image: url("#{$cdn-url}/images/preloader.gif");
      background-position: center 220px;
      background-repeat: no-repeat;
    }

    .temp {
      &.message:hover {
        opacity: 1;
      }
    }
  }

  #template_form {
    a.button {
      &.nextbutton {
        float: left;
        margin-top: 3px;
      }
    }
  }

  .coloredit {
    background-image: url("#{$cdn-url}/images/colors.png");
    border-radius: 2px;
    display: block;
    cursor: pointer;
    float: left;
    height: 42px;
    margin-right: 20px;
    opacity: 0.5;
    width: 42px;
  }

  .document_name {
    height: 23px;
    margin-left: 0;
    margin-top: 0;
    padding-left: 3px;
    width: 143px;
  }

  .floating {
    height: 40px;
    float: left;
    clear: both;

    a,
    input {
      float: left;
      margin: 0 15px 0 0;
    }
  }

  .page {
    border: 1px solid #999;
    background-size: 100% 100%;
    clear: both;
    float: left;
    height: 806px;
    margin: 0 0 35px 0;
    overflow: hidden;
    position: relative;
    width: 570px;

    div {
      background-color: $color-white;
      border: 1px solid #ccc;
      cursor: pointer;
      font-size: 11px;
      line-height: 26px;
      padding-left: 8px;
      position: absolute;

      a.edit {
        background-image: url("#{$cdn-url}/img/pencil.png");
        display: block;
        height: 14px;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 14px;
        z-index: 4;
      }

      b {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 3;
      }
    }

    input {
      font-size: 11px;
      line-height: 12px;
      margin-left: 0;
      padding: 1px;
      width: 70%;
    }

    span {
      display: block;
      pointer-events: none;
      position: absolute;
      left: 6px;
      top: 1px;
      z-index: 8;
    }

    .editing {
      span {
        pointer-events: all;
      }
    }

    .temp {
      border: 0;
    }
  }

  .fadingboxes {
    .page {
      div {
        a.edit {
          display: none;

          &.edit_57 {
            display: block;
          }
        }
      }
    }
  }

  .red {
    .page {
      background-color: $color-error;
      background-image: url("#{$cdn-url}/images/forbidden_big.png");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center 50px;

      img {
        opacity: 0.5;
      }
    }
  }

  .snapping {
    background-image: url("#{$cdn-url}/images/snapping.png");
    border-radius: 2px;
    cursor: pointer;
    display: block;
    float: left;
    height: 42px;
    opacity: 0.5;
    width: 42px;

    &.active {
      opacity: 1;
    }
  }

  .temp.message {
    height: 100%;
    left: 0;
    line-height: 660px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .yellow {
    background-color: #ffff99;
  }
}
