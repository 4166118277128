.integrations {
  padding-right: 50px !important;
  position: relative;
}

.calls-queue-scrollable,
.notifications-scrollable {
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  max-height: 527px;
}

.top-bar-dropdown-empty-message {
  background-color: $ahoy-color-neutral-lightest;
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
}
