.dropdownmenu {
  .d {
    float: left;
    padding: 5px 0 25px 15px;
    width: 200px;

    label {
      color: $color-body-text;
      float: left;
      font-weight: 400;
      padding: 10px 0 8px 0;
      text-transform: none;
    }
  }
}

.innerwidget {
  .dropdownmenu {
    display: none;

    .dropd {
      color: #333;
      cursor: pointer;
      font-size: 12px;
      line-height: 25px;
      padding: 0 0 0 10px;
      text-transform: none;

      &:hover {
        background-color: #34a2dc;
        background-color: $ahoy-color-aqua-dark;
        color: $color-white;
      }
    }
  }

  .widgettitle {
    &:hover {
      .dropdownmenu {
        display: block;
      }
    }
  }
}

#rightcontent {
  #action_menu_div {
    opacity: 0;
    width: auto;
  }
}
