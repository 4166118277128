#date_dropdown {
  #dr_errors {
    background-color: #ff4343;
    color: $color-white;
    display: none;
    float: left;
    line-height: 30px;
    margin: 0 0 15px 15px;
    padding: 0 15px 0 15px;
  }

  #dr_quicklinks {
    float: left;
    margin: 15px 0 15px 15px;
    width: 100%;

    a {
      background-color: #e5e5e5;
      color: #777;
      float: left;
      font-size: 11px;
      line-height: 20px;
      margin-right: 10px;
      padding: 0 10px 0 10px;
      text-decoration: none;
      text-transform: none;

      &.active {
        background-color: $color-primary-1;
        color: $color-white;
      }
    }
  }

  #custom_dr_picker {
    float: left;
    margin-top: -10px;
  }
}
