.maandplanning {
  position: relative;

  label {
    clear: none;
    float: left;
    line-height: 25px;
    width: 80px;
  }

  select {
    width: 80px;
  }

  select#forwho {
    background-color: $color-white;
    border: 1px solid #c3c3c3;
    border-radius: 0px;
    height: 24px;
    width: 160px;
  }

  .big {
    float: right;
    margin-right: 34px;
    width: auto;
  }

  .data {
    padding: 4px;
  }

  .dark {
    .data {
      color: $color-white;
    }
  }

  .multi_day {
    .data {
      display: none;
    }
  }

  .planning_header {
    clear: both;
    float: left;
    margin: 15px 0 0 0;
    width: 100%;

    span {
      color: $color-primary-2;
      display: block;
      float: left;
      font-weight: 700;
      line-height: 20px;
      margin: 8px 1px 5px 0;
      text-align: center;
      width: 14.1%;
    }
  }

  .planning_header_week {
    clear: both;
    float: left;
    margin: 15px 0 0 15px;
    width: 100%;

    em {
      color: #999;
      font-size: 11px;
      font-style: normal;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    span {
      float: left;
      position: relative;
      display: block;
      height: 20px;
      text-align: center;
      line-height: 20px;
      margin: 8px 5px 5px 5px;
      width: calc(14.2857% - 10px - 2.1428px);

      a {
        color: #333;
        display: inline-block;
        font-weight: normal;
        line-height: 20px;
        margin: 0 auto;
        padding: 0 15px 0 15px;
        text-decoration: none;
        text-align: center;
      }

      &.active {
        em {
          font-weight: 700;
          color: $color-black;
          font-size: 13px;
        }
      }

      &.valid_no {
        opacity: 0.5;
      }
    }

    .company,
    .full_day_off,
    .active {
      i {
        border-radius: 3px;
        height: 100%;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 0;
      }
    }

    .company,
    .full_day_off {
      em {
        color: #333 !important;
      }

      i {
        background-color: #e3e3e3 !important;
        left: 0;
      }
    }

    .before_noon_off,
    .after_noon_off {
      i {
        display: none;
      }
    }

    .active {
      a {
        background-color: #eee;
        border-radius: 4px;
      }
    }
  }

  .planning_content {
    float: left;
    clear: both;
    margin: 0 0 3px 0;
    width: 100%;

    i:first-child {
      margin-top: 28px !important;
    }

    i.meetingcount,
    i.todocount,
    i.callbackcount {
      clear: both;
      display: block;
      float: left;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      height: 15px;
      line-height: 16px;
      margin: 10px 0 0 15px;
      padding-left: 21px;
      width: 75%;

      &:before {
        font-family: "icomoon";
        padding-right: 5px;
        vertical-align: baseline;
      }
    }

    i.meetingcount:before {
      content: "\e60a";
    }

    i.todocount:before {
      content: "\e60e";
    }

    i.callbackcount:before {
      content: "\e603";
    }

    .day {
      color: $color-primary-2;
      cursor: pointer;
      background-color: $color-white;
      border: 1px solid #ccc;
      border-top: 0;
      border-left: 0;
      float: left;
      height: 128px;
      margin: 0 1px 1px 0;
      overflow: hidden;
      position: relative;
      width: calc(14.1% - 1px);

      b {
        background-color: $color-white;
        background-position: 4px 4px;
        background-repeat: no-repeat;
        border-top: 1px solid #ccc;
        bottom: 0;
        clear: both;
        display: block;
        float: left;
        font-weight: 400 !important;
        font-size: 11px;
        height: 9px !important;
        left: 0;
        line-height: 9px !important;
        margin: 0 !important;
        overflow: hidden;
        padding: 0 !important;
        position: absolute;
        width: 100%;

        em {
          background: $color-error;
          bottom: 0;
          display: block;
          float: left;
          height: 4px;
          left: 0;
          margin: 0 !important;
          padding: 0 !important;
          position: absolute;
        }

        i {
          background-color: $color-valid;
          display: block;
          float: left;
          height: 9px;
          margin: 0 !important;
          padding: 0 !important;
        }

        &.callback {
          background-image: rl("#{$cdn-url}/img/call_10.png?v=2");
        }

        &.meeting {
          background-image: url("#{$cdn-url}/img/meeting_10.png?v=2");
        }

        &.planning {
          background-image: url("#{$cdn-url}/img/clock_10.png?v=2");
        }
      }

      em {
        font-style: normal;
        height: 15px;
        left: 0px;
        line-height: 15px;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 1;
      }

      span {
        color: $color-body-text;
        display: block;
        font-size: 10px;
        position: absolute;
        right: 5px;
        text-align: center;
        top: 2px;
        width: 17px;
      }

      .day_off {
        border-radius: 3px;
        background-color: $color-warning;
        color: $color-body-text !important;

        strong {
          background-color: $color-secondary-3;
          border-radius: 6px;
          height: 15px;
          margin: 0;
          position: absolute;
          top: 0px;
          width: 100%;
          z-index: 0;
        }

        &.full_day_off {
          strong {
            left: 0px;
          }
        }

        &.valid_no {
          opacity: 0.5;
        }
      }
    }

    .empty {
      background-color: #efefef;
      cursor: auto;
    }

    .today {
      background-color: $color-body-text !important;
      color: $color-white;

      span {
        color: $color-white !important;
      }
    }

    .topborder {
      background-image: none;
      border-top: 1px solid #ccc;
    }

    .leftborder {
      border-left: 1px solid #ccc;
      width: calc(14.1% - 2px);
    }

    .weekend {
      background-color: #eaeff6;
      color: $color-primary-2;
    }

    .nobottomborder {
      border-bottom: 0;
      height: 118px;
      margin-bottom: 0;
    }
  }

  .per_user {
    height: 160px;

    img {
      border-radius: 2px;
      float: left;
      margin: 0 10px 0 0;
      width: 45px;
    }

    span {
      float: left;
      width: 100px;
    }

    .inner_user {
      margin-top: 20px;
    }
  }

  #allday_events_holder {
    box-sizing: border-box;
    clear: both;
    float: left;
    margin: 8px 0 -12px 0;
    overflow: auto;
    padding-left: 20px;
    padding-right: 0;
    width: calc(100% - -5px);

    .allday_entries {
      clear: none;
      float: left;
      margin-left: 5px;
      margin-right: 5px;
      min-height: 18px;
      width: calc(14.2857% - 10px);

      .morelink {
        background-image: url("#{$cdn-url}/img/event_milestone.png");
        background-repeat: no-repeat;
        display: block;
        float: left;
        font-size: 11px;
        height: 16px;
        padding-left: 22px;
      }

      em {
        position: relative;
      }

      .all_dayentry {
        background-repeat: no-repeat;
        background-image: url("#{$cdn-url}/img/event_fullday.png");
        float: left;
        margin-bottom: 4px;
        min-height: 18px;
        overflow: hidden;
        position: relative;

        &.bday {
          background-image: url("#{$cdn-url}/img/event_bday.png");
        }

        &.default_hidden {
          display: none;
        }

        &.milestone {
          background-image: url("#{$cdn-url}/img/event_milestone.png");

          span {
            cursor: pointer;
            padding-top: 1px;
          }

          &.done {
            span {
              text-decoration: line-through;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .inner {
        max-height: 25px;
        overflow: hidden;
        padding-left: 22px;
      }

      &:first-child {
        margin-left: 0;
        margin-right: 5px;
      }

      &:hover {
        .morelink {
          display: none;
        }

        .default_hidden {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .planning_content .day {
      height: 160px;
    }
  }

  @media screen and (min-width: 1680px) {
    .planning_content .day {
      height: 180px;
    }
  }

  @media screen and (min-width: 1920px) {
    .planning_content .day {
      height: 200px;
    }
  }
}

#controls_maandplanning {
  border-bottom: 1px solid #ccc;
  height: 40px;
  margin: 0 0 5px 0;

  .datepicker_toolbox {
    float: left;
    min-width: 520px;
    width: 50%;
  }

  #project_id {
    float: right;
    width: 120px;
  }

  a.prevlink {
    margin-left: 0;
  }

  select,
  input {
    border: 1px solid #c3c3c3;
    border-radius: 3px;
  }

  select#forwho {
    height: 24px;
    margin-right: 20px;
    width: 142px;
  }

  select#project_id {
    height: 24px;
    margin-right: 19px;
    width: 127px;
  }

  .big {
    float: right;
    clear: none;
  }

  .right {
    width: 44px;
  }

  @media screen and (max-width: 1460px) {
    select#project_id,
    select#forwho {
      width: 96px;
    }
  }
}
