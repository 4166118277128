.product-thumbnail-wrapper {
  box-shadow: inset 0 0 0 1px $ahoy-color-neutral;
  border-radius: 4px;
  display: flex;
  height: 72px;
  padding: 6px;
  position: relative;
  width: 72px;

  .product-thumbnail {
    height: auto;
    width: 100%;
  }

  .product-thumbnail-edit-button {
    background-color: rgba($ahoy-color-teal-darkest, 0.84);
    border: 0;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 24px;
  }
}
