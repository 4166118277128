#stats_tt {
  &.listview {
    .c0 {
      width: 42%;
    }

    .c3 {
      width: 18%;
    }

    .entry .c3,
    .entry .c0 {
      font-weight: 400;
    }
  }
}
