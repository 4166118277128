a.folder {
  background-image: url("#{$cdn-url}/img/foldericon.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 79px;
  color: $ahoy-color-aqua-dark;
  display: inline-block;
  height: auto !important;
  line-height: 20px;
  margin: 20px;
  min-height: 20px;
  padding-top: 80px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  width: 101px;

  &:hover {
    text-decoration: underline;
  }
}

a.folder_company {
  background-image: url("#{$cdn-url}/img/foldericons/folder_company.png") !important;
}

a.folder_contact {
  background-image: url("#{$cdn-url}/img/foldericons/folder_contact.png") !important;
}

a.folder_event {
  background-image: url("#{$cdn-url}/img/foldericons/folder_event.png") !important;
}

a.folder_meeting {
  background-image: url("#{$cdn-url}/img/foldericons/folder_meeting.png") !important;
}

a.folder_project {
  background-image: url("#{$cdn-url}/img/foldericons/folder_project.png") !important;
}

a.folder_product {
  background-image: url("#{$cdn-url}/img/foldericons/folder_product.png") !important;
}

a.folder_sale {
  background-image: url("#{$cdn-url}/img/foldericons/folder_sale.png") !important;
}

a.folder_ticket {
  background-image: url("#{$cdn-url}/img/foldericons/folder_ticket.png") !important;
}
