#webhooklist {
  .entry {
    clear: both;
    background-image: url("#{$cdn-url}/images/webhook_bg.png");
    background-repeat: no-repeat;
    float: left;
    height: 28px;
    margin: 0 0 5px 0;
    width: 883px;

    div {
      float: left;
      line-height: 29px;
    }

    .eventtype {
      font-size: 11px;
      height: 29px;
      overflow: hidden;
      padding: 0 0 0 9px;
      width: 350px;
    }

    .target {
      font-size: 11px;
      width: 471px;
      padding: 0 15px 0 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
