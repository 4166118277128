#popup_move_surface {
  backface-visibility: hidden;
  background-color: $color-black;
  cursor: move;
  height: 100%;
  opacity: 0.001;
  position: fixed;
  width: 100%;
}

#popupmover {
  cursor: move;
  height: 55px;
  left: -0px;
  position: absolute;
  top: 0;
  width: 60px;
}

#popup-container {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
}

#popupbg {
  backface-visibility: hidden;
  background-color: $color-black;
  height: 500%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
}

#popupcontent {
  clear: left;
  height: auto !important;
  height: 250px;
  margin: 75px auto 75px auto;
  min-height: 250px;
  position: relative;
  width: 660px;
}

#popupcontentframe {
  border: 0;
  height: auto !important;
  height: 250px;
  min-height: 250px;
  -webkit-overflow-scrolling: hidden;

  iframe:not(.cke_wysiwyg_frame) {
    background-color: $color-white;
    border: 0;
    height: auto !important;
    height: 250px;
    min-height: 250px;
    overflow: hidden;
    width: 100%;
  }
}

#popupcontentoverflow {
  position: relative;
  overflow: hidden;
}

.grecaptcha-badge { 
  visibility: hidden;
}

.grecaptcha-brand {
  font-size: 12px;
  margin-top: -25px !important;
}

#popup {
  left: 0;
  margin: 0 0 0 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-out;
  visibility: hidden;
  width: 100%;

  &.loaded {
    #innerpopupcontent {
      &:after,
      &:before {
        display: block;
      }
    }

    #popupcontentframe {
      &:before {
        display: none;
      }
    }
  }

  &.moving {
    transition: none !important;
  }

  &.transparent {
    #TL_iframe {
      background-color: transparent;
    }

    #innerpopupcontent:before,
    #popupcontentframe:before {
      display: none !important;
    }
  }

  &.visible {
    opacity: 1;
    transition: height 0.2s ease-out, top 0.2s ease-out, opacity 0.2s ease-out;
    visibility: visible;
  }
}

.popupform {
  label {
    span {
      color: $color-error;
      font-size: 14px;
      font-weight: 700;
    }
  }

  p {
    font-size: 11px;
  }
}

.popup-close-button {
  position: absolute !important;
  top: 12px;
  right: 12px;
}
