.tag {
  background-color: $color-warning;
  border-radius: 0.3em;
  clear: both;
  color: $color-white;
  display: block;
  float: left;
  font-size: 11px;
  line-height: 20px;
  margin: 8px 0 0 0;
  padding: 0 5px 0 5px;

  a {
    color: $color-white;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tagholder {
  display: block;
  height: 90px;
  margin: 5px 0 0 100px;
  overflow: auto;
  width: 179px;
}
