#q_mark {
  background-color: transparent;
  background-image: url("#{$cdn-url}/images/qmark.gif?v=2");
  background-repeat: no-repeat;
  border: 0;
  display: block;
  height: 22px;
  line-height: 22px;
  margin-top: 13px;
  padding-left: 30px;
  position: relative;
  text-transform: none;
  width: auto;

  &:before {
    background: $color-black;
    border-radius: 100px;
    color: $color-white;
    content: "?";
    display: inline-block;
    font-size: 15px;
    left: 0;
    line-height: 22px;
    position: absolute;
    text-align: center;
    text-indent: 1px;
    top: 0;
    width: 22px;
  }

  .content .listview & {
    float: right;
  }
}
