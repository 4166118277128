.ui-search-form {
  display: flex;
  align-items: center;
}

.ui-search-box-wrapper {
  position: relative;
  display: inline-block;
  width: 210px;

  &.search_filled_out .ui-search-clear-button {
    /* Remove !important when legacy styling is removed */
    display: block !important;
  }
}

.ui-search-input {
  /* Remove !important when legacy styling is removed */
  padding: 0 24px !important;
}

.ui-search-submit-button {
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  /* Remove !important when legacy styling is removed */
  position: absolute !important;
  left: 0 !important;
  width: 24px !important;
  height: 100% !important;

  /* Remove when legacy styling is removed */
  background-color: transparent !important;
  float: none !important;
  vertical-align: middle !important;

  &:before {
    color: $color-grey-6 !important;
  }
}

.ui-search-clear-button {
  position: absolute;
  right: 0;
  top: 0;

  background-image: url("#{$cdn-url}/img/remove-input.png");
  background-position: center center;
  background-repeat: no-repeat;

  color: $color-grey-3;
  display: none;
  text-decoration: none;

  /* Remove !important when legacy styling is removed */
  width: 30px !important;
  height: 100% !important;

  /* Remove when legacy styling is removed */
  left: auto !important;
}
