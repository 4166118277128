#admin_custom_fields {
  border-top: 1px solid $color-grey-2;
  clear: both;
  margin: 10px -19px -8px -19px;
  padding-top: 10px;

  h3 {
    margin-left: 10px;
  }

  h2 {
    margin-left: 10px;
  }

  i {
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    margin-right: 16px;
    min-width: 125px;
    padding-left: 19px;
    text-align: left;
  }

  span {
    display: inline-block;
    min-width: 450px;
  }
}
