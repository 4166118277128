#overview_area {
  .tooltip {
    margin: 7px 10px 0 0;
  }

  .invoiceable {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.loading {
    float: left;
    min-height: 350px;
    position: relative;
    width: 100%;

    &:after,
    &:before {
      content: " ";
      display: block;
      position: absolute;
    }

    &:after {
      background-color: $color-white;
      bottom: 1px;
      left: -19px;
      opacity: 0.75;
      right: -19px;
      top: -1px;
      z-index: 1;
    }

    &:before {
      background-image: url("#{$cdn-url}/images/loading_big.gif");
      background-position: center center;
      background-repeat: no-repeat;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}
