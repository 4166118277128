.widget {
  background: $color-white;
  border: 0px solid;
  box-shadow: 0 0 5px #d1d1d1;
  box-sizing: border-box;
  margin-bottom: 30px;
  position: relative;

  .content {
    @extend .cf;
    height: auto;
    min-height: 30px;
    padding: 19px 19px 14px 19px;
  }

  .greyed {
    .content_t {
      background-color: $color-white;
    }
  }

  .inactive {
    .content_t {
      a {
        border-color: #ccc;
        opacity: 0.7;
      }
    }
  }

  .widgettitleholder {
    border-bottom: 1px solid #e5e6e9;
    height: 25px;
    line-height: 25px;
    padding: 10px 4px 10px 19px;

    .fixed-right & {
      border-bottom: none;
    }
  }
}

.widget:not(.widget-bigtitle):not(.widget-search):not(.widget-view) {
  border-radius: 4px;
  box-shadow: none;
}

.widget:not(.widget-bigtitle):not(.widget-search):not(.widget-view),
.widget-view:not(.ahoy-overview-page-content) .content {
  border: 1px solid $ahoy-color-neutral;
}

#fixedright .widget {
  border: none;
}

.smallwidget {
  float: left;
  width: calc(50% - 15px);

  .content {
    overflow: hidden;
  }

  .remarks {
    .content {
      word-break: break-all;
    }
  }

  + .smallwidget, &.invoice-remarks {
    margin-left: 30px;
  }

  &.search {
    .content {
      min-height: 71px;
      height: 71px;
    }
  }

  &.overflowallowed {
    .content {
      overflow: visible;
    }
  }
}

.bigwidget {
  clear: left;
}

.widgettitleholder {
  &.dropdownwidget {
    border-bottom: none;
    left: 17px;
    position: absolute;
    top: 0px;
  }

  &.daterange_with_pipeline{
    position: unset;
    margin-left: 17px;
  }
}

//Specific CSS for Products > Pricelists

.settings_products {
  .widgettitle {
    display: flex;
  }

  .widget-actions {
    align-items: center;
    display: flex;
  }
}

.widget-actions {
  .ahoy-menu-dropdown.ahoy-menu-dropdown-right {
    float: right;
    margin-top: -2px;
    margin-right: 15px;
  }
}

.widgettitle {
  color: $color-primary-2;
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  position: relative;

  .content_t {
    background-color: transparent;
    border-radius: 0;
    display: inline-block;
    font-size: 16px;
    line-height: 25px;
    padding: 0;

    a {
      border-bottom: 3px solid $ahoy-color-aqua-dark;
      color: inherit;
      font-weight: inherit;
      padding-bottom: 12px;
      text-decoration: none;
    }

    .spacing & {
      height: 24px;
    }
  }

  .ahoy-button-primary {
    margin-left: 12px;
    margin-top: -4px;
    float: none;
  }
}

.innerwidget {
  background-color: $color-grey-3;
  clear: both;
  float: left;
  margin: 40px 0 20px;
  padding: 6px;
  position: relative;
  width: calc(100% - 12px);

  p {
    background-color: $color-grey-3;
    display: block;
    height: 240px;
    font-size: 13px;
    float: right;
    margin: 0;
    text-align: center;
    width: 20%;

    span {
      display: block;
      line-height: 20px;
      margin: 80px auto 0 auto;
    }

    .big {
      width: 140px;
    }
  }

  .content_t {
    color: $color-primary-1;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &.no_hover_effects {
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }
  }

  .filter {
    background-color: $color-grey-5;
    border-radius: 3px;
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    float: right;
    margin: 5px 6px 0 0;
    padding: 0 5px 0 5px;

    i {
      font-style: normal;
      font-weight: 700;
    }

    .active {
      background-color: $ahoy-color-aqua-dark;
    }
  }

  .widgettitleholder {
    &.dropdownwidget {
      top: -35px;
    }
  }
}

.dropdownwidget {
  .widgettitle {
    .icon-chevron-down {
      color: #888;
      padding-left: 10px;
    }
  }
}

#date_title {
  cursor: pointer;
  margin-right: 15px;
}

/* Widgets based on view.php */
.widget-view {
  background: none;
  box-shadow: none;

  .content {
    position: relative;
    margin-top: 88px;
    background: white;
    box-shadow: none;
    border-radius: 4px;
  }

  .view_search {
    top: -59px;
  }

  .widgettitleholder {
    border-bottom: none;
  }

  .widgettitle {
    font-size: 2em;
    margin-left: -19px;

    .content_t {
      color: $color-primary-2;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: -0.25px;
    }
  }
}

.view_actions {
  display: flex;
  position: absolute;
  right: 0;
  top: -55px;

  > * {
    margin-left: 12px;
  }
}

/* Widget that has a big title */
.widget-bigtitle {
  background: none;
  box-shadow: none;

  .content {
    position: relative;
    margin-top: 25px;
    background: white;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #d1d1d1;
  }

  .widgettitleholder {
    border-bottom: none;
    padding-right: 0;
  }

  .widgettitle {
    color: $color-primary-2;
    margin-left: -19px;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.25px;

    .content_t {
      font-size: inherit;
    }
  }
}

.widgetcontent {
  background-color: $color-white;
  clear: both;
  float: left;
  width: 100%;

  .legend {
    background-image: url("#{$cdn-url}/images/blue-bol.png?v=2");
    background-position: left center;
    background-repeat: no-repeat;
    display: block;
    float: left;
    line-height: 18px;
    margin: 5px 0 0 12px;
    padding: 0 0 0 16px;
  }
}

.content .sep_line {
  border-top: 1px solid $color-grey-4;
  clear: both;
  float: left;
  font-size: 0px;
  height: 0px;
  line-height: 0px;
  margin: 4px 0 10px 0;
  width: 100%;
}

b.left {
  float: left;
  width: 85px;
}

b.pro {
  clear: both;
  float: left;
  font-weight: 400;
  margin-top: 5px;
}

i.sp {
  display: none;
}

.content {
  h5 {
    color: $color-black;
    font-weight: 700;
    float: left;
    font-size: 16px;
    margin: 0 0 2px 0;
    padding: 0 0 0 4px;
    width: 350px;
  }
}

.h3-columns {
  font-weight: inherit;
  opacity: 0.7;
}

.li-columns {
  background: none;
  padding-left: 0;
}

h2.subtitle {
  background-color: $color-grey-2;
  box-sizing: content-box;
  clear: both;
  color: #444;
  float: left;
  font-size: 17px;
  font-weight: 400;
  margin: 14px 0 26px -19px;
  line-height: 24px;
  padding: 7px 0px 7px 18px;
  width: calc(100% + 20px);

  &:first-child {
    margin-top: -20px;
  }
}

#background-info,
#detail-description-widget {
  margin-left: 30px;
  margin-bottom: 30px;
  float: left;
  width: calc(50% - 15px);
  position: relative;
}

[id^="custom-field-multi-line-widget-"] {
  position: relative;
  margin-bottom: 30px;
  clear: both;
}
