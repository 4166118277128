.spinner_inline {
  animation-name: spin;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  margin-left: 10px;

  &.icon-spinner8 {
    &:before {
      left: 0px;
      position: absolute;
      top: 1px;
    }
  }
}
