#showmemore {
  a {
    background-color: #f0f0f0;
    border-radius: 3px;
    clear: both;
    color: $color-black;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 12px;
    line-height: 27px;
    margin: 2px 0 13px -19px;
    text-align: center;
    width: calc(100% + 38px);

    &:hover {
      background-color: #e6e6e6;
      text-decoration: none;
    }
  }
}
